import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useGetByIdDealsQuery } from "../../App/Slice/dealSlice";
import ClientFilter from "./ClientFilter";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetOrderIDQuery } from "../../App/Slice/orderIDSlice";
import { Helmet } from "react-helmet";

function View_Deals() {
  const Auth_User = JSON.parse(localStorage.getItem("user_data"));

  const params = useParams();
  const key = params;
  const store = key?.client;
  const id = key?.id;

  const cli = store?.split("-")?.join(" ");

  const { data, isLoading } = useGetByIdDealsQuery(id);
  const deal = data?.deal || {};

  const {
    title,
    link,
    image,
    feature,
    description,
    keyword,
    client,
    category,
    convert_deal_Product,
  } = deal;
  const calc = client?.calc;
  const client_ids = client?._id;
  const calcu = calc?.[0];
  const calcue = calc?.[1];
  const cashback = calc?.[0]?.split(" ")?.join("-");
  const name = client?.name?.split(" ")?.join("-");
  const campaign_name = `&p8=${name}`; //store name

  const { data: userData } = useNormalUserVerifyQuery();
  const user = userData?.user_data || {};

  const { data: orderId } = useGetOrderIDQuery();
  const order_id = orderId?.order_id;

  const generateUniqueRandom17DigitNumber = () => {
    const min = Math.pow(10, 9);
    const max = Math.pow(10, 10) - 1;
    const random17DigitNumber =
      Math.floor(Math.random() * (max - min + 1)) + min;

    const existingOrder = random17DigitNumber;
    if (existingOrder === order_id) {
      return generateUniqueRandom17DigitNumber();
    }
    return random17DigitNumber;
  };
  const random17DigitNumber = generateUniqueRandom17DigitNumber();

  const source = user?.sub_id
    ? `&source=${user.sub_id}`
    : "&source=CD_wo_lo_2023";
  const p5 = `&p5=CD${random17DigitNumber}`;

  const modify_link = `${client?.link}${p5}&p7=${cashback}+${calcue}${source}${campaign_name}&url=${link}`;

  console.log("linker", modify_link);

  //https://tsm.s3g6.com/click?campaign_id=202&pub_id=853&p5=CD1900669393&p7=FP+15&source=CD_wo_lo_2023&p8=Allen-Solly&url=https://allensolly.abfrl.in/p/men-maroon-hooded-neck-full-sleeves-casual-sweatshirt-916564.html

  //https://tsm.s3g6.com/click?campaign_id=202&pub_id=853&p5=CD4811700256&p7=FP+15&source=cashD93450989&p8=Allen-Solly&url=https://allensolly.abfrl.in/p/men-maroon-hooded-neck-full-sleeves-casual-sweatshirt-916564.html

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keyword} />
      </Helmet>

      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="/campaign-store">Store</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to={`/campaign-store/${cli}/${client?._id}`}>{cli}</Link>
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{title}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container view_deal_details mb-5 p-3">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12">
            <ClientFilter client_ids={client_ids} />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            {isLoading ? (
              <div className="h-50 d-flex align-items-center justify-content-center">
                <p>Fetching data...</p>
              </div>
            ) : (
              <div className="view_store_detail">
                <h1>Just A Step Away From Earning Cashdhundo Cashback</h1>
                <div className="view_store_image">
                  {convert_deal_Product === "deal" ? (
                    <img
                      src={client?.logo}
                      className="view_store_deals"
                      loading="lazy"
                      alt={title}
                    />
                  ) : (
                    <>
                      <img src={image} loading="lazy" alt={title} />
                      <span>
                        <img src={client?.logo} loading="lazy" alt={title} />
                      </span>
                    </>
                  )}
                </div>
                <div className="store-logo mt-4">
                  <div className="view_content">
                    <h2>{title}</h2>
                    {convert_deal_Product === "deal" ? (
                      <h3
                        title="Your Deal Is Activated Now"
                        className="mt-4 mb-3 activate_deals"
                      >
                        DEAL ACTIVATED
                      </h3>
                    ) : null}
                    {calcu === null || calcu === "Paused" ? (
                      <h4 style={{ color: "red" }}>Cashback Not Available</h4>
                    ) : (
                      <h4 style={{ color: "red" }}>
                        {client?.client_offer} Cashback
                      </h4>
                    )}
                    <div className="cashback-btn mb-3">
                      {Auth_User ? (
                        <Link
                          to={
                            calcu === null || calcu === "Paused"
                              ? link
                              : modify_link
                          }
                          type="button"
                          className="btn"
                          target="_blank"
                        >
                          {calcu === null || calcu === "Paused"
                            ? "SHOP NOW"
                            : "GET CASHBACK"}
                        </Link>
                      ) : calcu === null || calcu === "Paused" ? (
                        <Link
                          to={
                            calcu === null || calcu === "Paused"
                              ? link
                              : modify_link
                          }
                          type="button"
                          className="btn"
                          target="_blank"
                        >
                          SHOP NOW
                        </Link>
                      ) : (
                        <>
                          <Link
                            to={modify_link}
                            type="button"
                            className="btn"
                            target="_blank"
                          >
                            SKIP & LOSE CASHBACK
                          </Link>
                          <a
                            type="button"
                            className="btn"
                            data-bs-toggle="modal"
                            href="#exampleModalToggle"
                            role="button"
                          >
                            SIGN IN & GET CASHBACK
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="view_feature mt-4 mb-4">
                  <span>
                    <h4>Category:</h4>
                    <p>{category?.name}</p>

                    <h4>Description:</h4>
                    <p>{description}</p>
                  </span>
                  <ul>
                    <h4>Features:</h4>
                    {feature?.map((val, ind) => {
                      return <li key={ind}>{val}</li>;
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default View_Deals;
