import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetTransactionQuery } from "../../App/Slice/transactionSlice";
import Loading from "../../Secure/Loading";

function Transaction() {
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data || {};

  const { data: getTCs, isLoading } = useGetTransactionQuery();
  const getTransaction = getTCs?.transaction;
  // console.log("trans :", getTransaction);
  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name.username}</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Transaction</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9">
            <div className="inner-profile ">
              <span className=" mb-4 d-flex align-items-center justify-content-between">
                <h5>Transactions</h5>
              </span>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Transaction Date</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Store Name</th>
                    <th scope="col">Sale Amount</th>
                    <th scope="col">Cashback</th>
                    <th scope="col">Sub ID</th>
                    <th scope="col">Refrence</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="mt-5">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {getTransaction?.length === 0 ? (
                      <span className="not-found-error">No Result Found</span>
                    ) : (
                      <tbody>
                        {getTransaction?.map((val, ind) => {
                          const {
                            sub_id,
                            order_id,
                            sale_amount,
                            campaign_name,
                            createdAt,
                            cashback,
                            refernce_link,
                          } = val;
                          const cashbackFilter = cashback?.slice(0, 3);
                          const cashbackO = cashback?.slice(3);
                          return (
                            <tr key={ind}>
                              <td>{createdAt?.slice(0, 10)}</td>
                              <td>{order_id}</td>
                              <td>{campaign_name}</td>
                              <td>₹ {sale_amount}</td>
                              <td>
                                {cashbackFilter === "ZF "
                                  ? `Falt ${cashbackO}`
                                  : cashbackFilter === "FP "
                                  ? `Flat ${cashbackO}%`
                                  : cashbackFilter === "ZU "
                                  ? `Upto ${cashbackO}`
                                  : cashbackFilter === "UP "
                                  ? `Upto ${cashbackO}%`
                                  : null}
                              </td>
                              <td>{sub_id}</td>
                              {refernce_link ? (
                                <td>{refernce_link}</td>
                              ) : (
                                <td>No</td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
