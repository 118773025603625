import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const partner = createApi({
  reducerPath: "partner",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    createPartner: builder.mutation({
      query: (body) => ({
        url: "/partner",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const { useGetPartnerQuery, useCreatePartnerMutation } = partner;
