import React from "react";

function CashbackQuery() {
  return (
    <>
      <h3 className="faq_heading">
        Unleash Your Curiosity: Dive into Cashback Queries!
      </h3>
      <div className="accordion-faq">
        <div
          className="accordion accordion-flush"
          id="accordionFlushcashbackfact"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOneCashbackfact">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOneCashbackfact"
                aria-expanded="false"
                aria-controls="flush-collapseOneCashbackfact"
              >
                What do pending Cashbacks entail?
              </button>
            </h2>
            <div
              id="flush-collapseOneCashbackfact"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOneCashbackfact"
              data-bs-parent="#accordionFlushcashbackfact"
            >
              <div className="accordion-body">
                Following your shopping, your Cashback will first appear in the
                'Pending' status. All websites typically have a
                return/cancellation period, commonly set at 30 days. After this
                duration, the respective sites share information about
                successful transactions with us, and this process may take
                anywhere between 10 to 60 days. Subsequently, we update the
                status of your Cashback from 'Pending' to either 'Confirmed' or
                'Cancelled.' <br /> <br />
                Once you've accumulated Rs 149 or more in Confirmed Cashback,
                you are eligible to request NEFT payment. This ensures that you
                can conveniently receive the benefits of your confirmed rewards.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwoCashbackfact">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwoCashbackfact"
                aria-expanded="false"
                aria-controls="flush-collapseTwoCashbackfact"
              >
                What does the term Confirmed Cashback refer to?
              </button>
            </h2>
            <div
              id="flush-collapseTwoCashbackfact"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwoCashbackfact"
              data-bs-parent="#accordionFlushcashbackfact"
            >
              <div className="accordion-body">
                Confirmed Cashback refer to successfully validated transactions
                from retailers, confirmed after the return/exchange period. When
                your Confirmed Cashback total Rs. 149 or more, you have the
                option to transfer this cashback to your bank account.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2
              className="accordion-header"
              id="flush-headingThreeCashbackfact"
            >
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThreeCashbackfact"
                aria-expanded="false"
                aria-controls="flush-collapseThreeCashbackfact"
              >
                Is there a time limit for the validity of my Cashback?
              </button>
            </h2>
            <div
              id="flush-collapseThreeCashbackfact"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThreeCashbackfact"
              data-bs-parent="#accordionFlushcashbackfact"
            >
              <div className="accordion-body">
                cashdhundo Earnings will lapse if there is no login activity on your
                cashdhundo account, either through the website or the application,
                for a consecutive period of 1 year.
                <br /> <br />
                To safeguard against the expiration of your earnings, it is
                essential to log in to your cashdhundo account via the website or
                application at least once every 1 year.
                <br /> <br />
                In the event of earnings reaching their expiration, the
                corresponding amount will be deducted from the Order Details
                page and categorized under the "Expired Cashbacks" menu as a
                single entry. Unfortunately, such expired Earnings will not be
                available for withdrawal by you.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashbackQuery;
