import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "../../App/Slice/bannerSlice";
import Skeleton from "react-skeleton-loader";

function Header() {
  const { data, isLoading } = useGetBannerQuery();
  const banner = data?.Banner;
  const loading = isLoading;

  const filterbanner = banner?.filter((val) => val.status.includes("success"));

  return (
    <>
      <div className="container-fluid p-0 header-section pt-3">
        <Swiper
          slidesPerView={3}
          spaceBetween={15}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            140: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            570: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="row mySwiper"
        >
          {loading ? (
            <>
              <div className="sklton_slider">
                <div className="header-img">
                  <Skeleton color="#d6e7ff" />
                </div>
                <div className="header-img m-3 hide_sklton">
                  <Skeleton color="#d6e7ff" />
                </div>
                <div className="header-img hide_sklton">
                  <Skeleton color="#d6e7ff" />
                </div>
              </div>
            </>
          ) : (
            <>
              {filterbanner?.map((val, ind) => {
                const { client } = val;
                const names = client?.name?.split(" ")?.join("-");

                return (
                  <SwiperSlide key={ind} className="col-lg-12 col-md-12 col-12">
                    <div className="header-img" title={client?.name}>
                      <Link to={`/campaign-store/${names}/${client?._id}`}>
                        <img
                          src={val?.image}
                          loading="lazy"
                          alt={client?.name}
                        />
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
            </>
          )}
        </Swiper>
      </div>
    </>
  );
}

export default Header;
