import React from "react";
import { Link } from "react-router-dom";
import {
  CiFacebook,
  CiInstagram,
  CiLinkedin,
  CiTwitter,
  CiYoutube,
} from "react-icons/ci";
import { SiQuora } from "react-icons/si";
import { ImPinterest2 } from "react-icons/im";
import { FaTelegram } from "react-icons/fa";
function Footer() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  return (
    <>
      <div className="container-fluid p-0 footer-bg">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="footer-section">
              <div className="inner-section">
                <div className="footer-logo">
                  <Link to="/">
                    <img
                      src="https://api.cashdhundo.com/public/uploads/1721372863076Black and White Monogram Business Logo.png"
                      loading="lazy"
                      alt="cashdhundo"
                    />
                  </Link>
                </div>
                <div className="logo-para">
                  <p>
                    Welcome to cashdhundo, your ultimate destination for
                    unrivaled savings! Immerse yourself in a world of exclusive
                    discounts, irresistible deals, and enticing offers across a
                    myriad of categories. Our platform is your key to smart and
                    savvy shopping, where every purchase not only comes with
                    unbeatable discounts and coupons but also rewards you with
                    generous cashback.
                    <Link to="/about-us">see more</Link>
                  </p>
                </div>
              </div>
              <div className="inner-section">
                <div className="footer-heading">
                  <h4>IMPORTANT LINKS</h4>
                </div>
                <ul>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to={`/blog/category/filter/All`}>Blog</Link>
                  </li>
                  <li>
                    <Link to="/campaign-store">All Store</Link>
                  </li>
                  {/* <li>
                    <Link to="/coupons/category/All">Coupons</Link>
                  </li> */}
                </ul>
              </div>
              <div className="inner-section">
                <div className="footer-heading">
                  <h4>EASY GUIDE</h4>
                </div>
                <ul>
                  <li>
                    {user ? (
                      <Link to="/refer-earn">Refer & Earn</Link>
                    ) : (
                      <a
                        data-bs-toggle="modal"
                        href="#exampleModalToggle"
                        role="button"
                      >
                        Refer & Earn
                      </a>
                    )}
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="inner-section">
                <div className="footer-heading">
                  <h4>CONTACT US</h4>
                </div>
                <ul className="social-media">
                  <li>
                    <Link
                      to="https://twitter.com/cashdhundo"
                      target="_blank"
                      title="Twitter"
                    >
                      <CiTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.facebook.com/cashdhundo"
                      target="_blank"
                      title="Facebook"
                    >
                      <CiFacebook />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/cashdhundo/"
                      target="_blank"
                      title="Instagram"
                    >
                      <CiInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/cashdhundo/"
                      target="_blank"
                      title="Linkedin"
                    >
                      <CiLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.quora.com/profile/Cashdhoondho"
                      target="_blank"
                      title="Quora"
                    >
                      <SiQuora />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://in.pinterest.com/cashdhoondho/"
                      target="_blank"
                      title="Pinterest"
                    >
                      <ImPinterest2 />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/@Cashdhundo"
                      target="_blank"
                      title="Youtube"
                    >
                      <CiYoutube />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://t.me/+NRWqJsIxbOZhZGU9"
                      target="_blank"
                      title="Telegram"
                    >
                      <FaTelegram />
                    </Link>
                  </li>
                </ul>
                {/* <div className="playstore">
                  <div className="store-img">
                    <Link to="/coming-soon">
                      <img
                        src="https://api.cashdhundo.com/public/uploads/1683723783324playstore.png"
                        loading="lazy" alt=""
                      />
                    </Link>
                  </div>
                  <div className="store-img">
                    <Link to="/coming-soon">
                      <img
                        src="https://api.cashdhundo.com/public/uploads/1683723795866iso.png"
                        loading="lazy" alt=""
                      />
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="bottom-footer">
              <div className="company">
                <h5>© 2023 cashdhundo: All Right Reserved</h5>
              </div>
              <div className="policy">
                <ul>
                  <li>
                    <Link to="/become-our-partner">Become our Partner</Link>
                  </li>
                  <li>
                    <Link to="/anti-policy">Anti-Spam Policy</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
