import React from "react";

function AccessKingdom() {
  return (
    <>
      <h3 className="faq_heading">Can’t Access Your Kingdom? Login Woes Solved!</h3>
      <div className="accordion-faq">
        <div className="accordion accordion-flush" id="accordionFlushTraking">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOneTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOneTraking"
                aria-expanded="false"
                aria-controls="flush-collapseOneTraking"
              >
                Locked Out of Your Kingdom: Battling the 'Authentication Failed' Error
Unlock the Secret: Reset Your Password in a Flash!

              </button>
            </h2>
            <div
              id="flush-collapseOneTraking"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOneTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                <ul>
                  <li>Click on 'Forgot Password.'</li>
                  <li>Enter your registered email address or mobile number.</li>
                  <li>Your new password will be sent to the same email address or mobile number.</li>
                  <li>Reset your IP Address for added security.</li>
                  <li>Log in using the NEW password received in your email.</li>
                  <li>Go to 'Account Settings' and change the password.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccessKingdom;
