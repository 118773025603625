import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import WithdrawMoney from "../../Components/Profile/WithdrawMoney";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetUserWithdrawMoneyQuery } from "../../App/Slice/withdrawMoneySlice";
import Loading from "../../Secure/Loading";

function Wallet() {
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data || {};

  const { data: withdrawMoney, isLoading } = useGetUserWithdrawMoneyQuery();
  const wiMon = withdrawMoney?.withdraw_money;
  // console.log("money : ", withdrawMoney?.withdraw_money_length);

  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name.username}</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Wallet</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9">
            <div className="inner-profile ">
              <span className=" mb-4 d-flex align-items-center justify-content-between">
                <h5>Wallet Balance</h5>{" "}
                <h5>
                  Total Balance{" "}
                  <span className="total_amount">₹{name?.amount}</span>
                </h5>
                <button
                  className="btn edit-profile"
                  data-bs-toggle="modal"
                  href="#exampleModalToggleWithdrawMoney"
                  role="button"
                >
                  Withdraw Money
                </button>
              </span>
              <div className="inner-profile ">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Transation Date</th>
                      <th scope="col">Withdrawal ID</th>
                      <th scope="col">Request Amount</th>
                      <th scope="col">Mode</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <div className="mt-3">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {withdrawMoney?.withdraw_money_length === 0 ? (
                        <span className="not-found-error">No Result Found</span>
                      ) : (
                        <>
                          <tbody>
                            {wiMon?.map((val, ind) => {
                              const {
                                amount,
                                mode,
                                createdAt,
                                Order_ID,
                                status,
                              } = val;
                              return (
                                <tr key={ind}>
                                  <td>{createdAt?.slice(0, 10)}</td>
                                  <td>{Order_ID}</td>
                                  <td>₹ {amount}</td>
                                  <td>{mode}</td>
                                  <td>
                                    {status === "pending" ? (
                                      <span className="order-pending">
                                        Pending
                                      </span>
                                    ) : status === "approve" ? (
                                      <span className="order-success">
                                        Approve
                                      </span>
                                    ) : status === "rejected" ? (
                                      <span className="order-cancel">
                                        Reject
                                      </span>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </>
                      )}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WithdrawMoney />
    </>
  );
}

export default Wallet;
