import React from 'react'
import All_Store from '../View/Store/All_Store'

function Store() {
  return (
    <>
      <All_Store/>
    </>
  )
}

export default Store