import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import Missing_Report from "../../Components/Profile/Missing_Report";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetMissingReportQuery } from "../../App/Slice/missingReportSlice";
import Loading from "../../Secure/Loading";

function MissingReport() {
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data || {};

  const { data: MissingRepo, isLoading } = useGetMissingReportQuery();
  const missingReport = MissingRepo?.missing_report;

  const filterMissingReport = missingReport?.filter((ele) => {
    return ele.user._id === name._id;
  });

  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name.username}</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Missing Report Cashback</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9">
            <div className="inner-profile ">
              <span className=" mb-4 d-flex align-items-center justify-content-between">
                <h5>Transaction Missing Report</h5>{" "}
                <button
                  className="btn edit-profile"
                  data-bs-toggle="modal"
                  href="#exampleModalToggleMissingReport"
                  role="button"
                >
                  Missing Report
                </button>
              </span>
              <div className="inner-profile ">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Apply Date</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Store</th>
                      <th scope="col">Transation Date</th>
                      <th scope="col">Purchase Amount</th>
                      <th scope="col">Product</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <tbody>
                      {filterMissingReport?.missing_report_length === 0 ? (
                        <span className="not-found-error">No Result Found</span>
                      ) : (
                        <>
                          {filterMissingReport?.map((val, ind) => {
                            const {
                              _id,
                              client,
                              order_transaction_id,
                              product_name,
                              transaction_date,
                              createdAt,
                              amount,
                              status,
                            } = val;
                            return (
                              <tr key={ind}>
                                <td>{createdAt?.slice(0, 10)}</td>
                                <td>{order_transaction_id}</td>
                                <td className="bg-light">{client?.name}</td>
                                <td>{transaction_date}</td>
                                <td>₹ {amount}</td>
                                <td>{product_name}</td>
                                <td>
                                  {status === "Pending" ? (
                                    <span className="order-pending">
                                      {status}
                                    </span>
                                  ) : status === "Approve" ? (
                                    <span className="order-success">
                                      {status}
                                    </span>
                                  ) : status === "Rejected" ? (
                                    <span className="order-cancel">
                                      {status}
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Missing_Report name={name} />
    </>
  );
}

export default MissingReport;
