import React from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FiChevronRight } from "react-icons/fi";
import {
  useGetBlogQuery,
  useGetByIdBlogQuery,
} from "../../App/Slice/blogSlice";
import Popular from "./Popular";
import Category from "./Category";
import Loading from "../../Secure/Loading";

function SingleBlog() {
  const params = useParams();
  let id = params.id;

  const { data, isLoading } = useGetByIdBlogQuery(id);
  let blogs = data?.blog || {};

  const loading = isLoading;

  const { title, image, short_desc, createdAt, description, keyword } = blogs;

  const body = description;

  const { data: blogCate } = useGetBlogQuery();
  let blogCategory = blogCate?.blog;

  const findalData = blogCategory?.filter((item) =>
    item.status.includes("success")
  );

  const allCategory = [
    ...new Set(
      findalData?.map((val) => {
        return val.category?.name;
      })
    ),
    "All",
  ];
  const filterCategory = allCategory?.reverse();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={short_desc} />
        <meta name="keywords" content={keyword} />
      </Helmet>
      {loading ? (
        <div className="blog_loader">
          <p>Fetching Data...</p>
        </div>
      ) : (
        <>
          <div className="container-fluid p-0">
            <div className="row m-0 mb-5">
              <div className="col-lg-12 p-0">
                <div className="blog-banner">
                  <img src={image} loading="lazy" alt={title} />
                  <div className="outer-content">
                    <h1>{title}</h1>
                    <p>{short_desc}</p>
                    <div className="blog-profile d-flex justify-content-start flex-coloumn">
                      <div className="pro-image">
                        <img
                          src="https://api.cashdhundo.com/public/uploads/1704014236907user.png"
                          loading="lazy" alt="cashdhundo-profile"
                        />
                      </div>
                      <div className="profile-content">
                        <h4>Written by cashdhundo</h4>
                        <h5>Posted on {createdAt?.slice(0, 10)}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-4 mb-4">
            <div className="row">
              <div className="col-12 p-0">
                <ul className="brade_crampe">
                  <li>
                    <Link to="/">Home</Link>
                  </li>{" "}
                  <span>
                    {" "}
                    <FiChevronRight />{" "}
                  </span>
                  <li>
                    <Link to="/blog/category/filter/All">Blog</Link>
                  </li>
                  <span>
                    {" "}
                    <FiChevronRight />{" "}
                  </span>
                  <li>
                    <Link to="#">{params.title}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container mt-4">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-12">
                <div className="blog-section-s">
                  <p dangerouslySetInnerHTML={{ __html: body }} />
                </div>
                <div className="blog-keywords mb-5 mt-4">
                  <h5 className="fw-bold">Tags</h5>
                  <ul>
                    {keyword?.map((val, ind) => {
                      return <li key={ind}>#{val}</li>;
                    })}
                  </ul>
                </div>
                {/* <Comments id={id} />
            <AddComment id={id} /> */}
              </div>

              <div className="col-lg-3 col-md-3 col-12">
                <Category filterCategory={filterCategory} />
                <br />
                <Popular />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SingleBlog;
