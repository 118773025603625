import React from "react";
import { Link } from "react-router-dom";

function TopNav() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  return (
    <>
      <div className="top-nav">
        <ul className="d-flex align-items-end justify-content-end mb-1">
          <li className="nav-items">
            {user ? (
              <Link to="/refer-earn">Refer & Earn</Link>
            ) : (
              <a
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                role="button"
              >
                Refer & Earn
              </a>
            )}
          </li>
          <li className="nav-items">
            <Link to="/faq">FAQ</Link>
          </li>
          <li className="nav-items">
            <Link to={`/blog/category/filter/All`}>Blogs</Link>
          </li>
          <li className="nav-items">
            <Link to="/become-our-partner">Become our Partner</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default TopNav;
