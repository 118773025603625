export const URL_LINK = "https://api.cashdhundo.com/api";
export const UPLOADS_FILE = "https://api.cashdhundo.com/api/public";

// export const URL_LINK = "http://localhost:8006/api";
// export const UPLOADS_FILE = "http://localhost:8006/api/public";

export function GetToken() {
  let user = JSON.parse(localStorage.getItem("user_data"));
  if (user) {
    return user.token;
  } else {
    return "JDJFKD398438DKJFKJDK3894833JJJdhf378dHDJFDJ3834";
  }
}

// const Auth_User = JSON.parse(localStorage.getItem("user_data"));
// export const Token = Auth_User.token || {};

//https://mediaxpedia.gotrackier.com/click?campaign_id=24780&pub_id=1603&p1={click_id}&p5=zip7273449147&p7=30%-Cashback-by-cashdhundo&source=CD_wo_lo_2023&campaign_name=Earthrhythm

export const GetUserSecure = async () => {
  const isFetching = await fetch(`${URL_LINK}/user/normal-user`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${GetToken()}`,
    },
  });
  const response = await isFetching.json();
  const result = response?.status;
  console.log("result session :", result);
  if (result === "failed") {
    localStorage.clear();
    window.location.href = "/";
  } else {
    return;
  }
};
