import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetOrderIDQuery } from "../../App/Slice/orderIDSlice";
import { FaRegHandPointRight } from "react-icons/fa";
import Skeleton from "react-skeleton-loader";

function CashbackFilter({
  clientByData,
  queryDeal,
  queryCoupon,
  querySearch,
  loading,
}) {
  // queryDeal={queryDeal} queryCoupon={queryCoupon} querySearch={querySearch}
  // console.log("querySearch", querySearch);

  const { data: userData } = useNormalUserVerifyQuery();
  const user = userData?.user_data || {};

  const { data: orderId } = useGetOrderIDQuery();
  const order_id = orderId?.order_id;

  const generateUniqueRandom17DigitNumber = () => {
    const min = Math.pow(10, 9);
    const max = Math.pow(10, 10) - 1;
    const random17DigitNumber =
      Math.floor(Math.random() * (max - min + 1)) + min;

    const existingOrder = random17DigitNumber;
    if (existingOrder === order_id) {
      return generateUniqueRandom17DigitNumber();
    }
    return random17DigitNumber;
  };
  const random17DigitNumber = generateUniqueRandom17DigitNumber();

  const source = user?.sub_id
    ? `&source=${user.sub_id}`
    : "&source=CD_wo_lo_2023";
  const p5 = `&p5=CD${random17DigitNumber}`;

  const {
    name,
    client_offer,
    link,
    logo,
    cashback_date,
    avg_tracking_speed,
    cashback_claim_time,
    cashback_rates,
    calc,
  } = clientByData || {};
  const names = name?.split(" ")?.join("-");
  const campaign_name = `&p8=${names}`; //store name
  const calcue = calc?.[1]
  const calcu = calc?.[0];
  const cli = name?.split(" ")?.join("-");
  const cashback = calc?.[0]?.split(" ")?.join("-");
  const modify_link = `${link}${p5}&p7=${cashback}+${calcue}${source}${campaign_name}`;

  const filterQuery = querySearch?.filter((ele) =>
    ele.convert_deal_Product?.includes("deal")
  );
  return (
    <>
      <div className="category-sides mb-4">
        {loading ? (
          <div className="client_logo_filter">
            <Skeleton color="#d6e7ff" />
          </div>
        ) : (
          <div className="client_logo_filter">
          <Link to={calcu === null || calcu === "Paused" ? link : modify_link} target="_blank">
            <img src={logo} loading="lazy" alt={name} />
          </Link>
          </div>
        )}
        <div className="category_icons_filter d-flex justify-content-between">
          {/* <h3>{name}</h3> */}
          <Link
            to={calcu === null || calcu === "Paused" ? link : modify_link}
            target="_blank"
            title={name}
          >
            Proceed <FiArrowUpRight />
          </Link>
        </div>
        <span>
          {/* <form
            className="cashback-check d-flex justify-content-between"
            onSubmit={cashbackFilterHandler}
          >
            <input
              type="text"
              placeholder="Enter Cashback Value"
              onChange={(e) => setInput(e.target.value)}
            />
            <button type="submit">
              <FiSearch />
            </button>
          </form> */}
        </span>
        {/* <ul>
          <li>
            <a
              title="Terms & Condition"
              data-bs-toggle="modal"
              href="#exampleModalToggleTerms"
              role="button"
            >
              Terms & Condition
            </a>
          </li>
        </ul> */}
        <div className="filter_btn mt-3 d-flex justify-content-between">
          {filterQuery?.length === 0 ? (
            " "
          ) : (
            <Link to={`/campaign-store/${cli}/All`} type="button">
              ALL
            </Link>
          )}
          {queryDeal?.length === 0 ? (
            " "
          ) : (
            <Link to={`/campaign-store/${cli}/deals`} type="button">
              DEALS
            </Link>
          )}
          {queryCoupon?.length === 0 ? (
            " "
          ) : (
            <Link to={`/campaign-store/${cli}/coupons`} type="button">
              COUPONS
            </Link>
          )}
        </div>
      </div>

      <div className="category-sides mb-4">
        <div className="category_icons_filter d-flex justify-content-between">
          <h3>Track Cashback</h3>
        </div>
        <div className="cashback_history_data">
          <h4>Estimated Cashback Date</h4>
          <h6>
            <FaRegHandPointRight /> {cashback_date}
          </h6>
        </div>
        <div className="cashback_history_data">
          <h4>Tracking Speed</h4>
          <h6>
            <FaRegHandPointRight /> {avg_tracking_speed}
          </h6>
        </div>
        <div className="cashback_history_data">
          <h4>Cashback Claim Time</h4>
          <h6>
            <FaRegHandPointRight /> {cashback_claim_time}
          </h6>
        </div>
      </div>

      <div className="category-sides mb-4">
        <div className="category_icons_filter d-flex justify-content-between">
          <h3>Cashback T&C</h3>
        </div>
        {calcu === null || calcu === "Paused" ? (
          <div className="cashback_history_data">
            <h4>No Cashback Available</h4>
          </div>
        ) : (
          <>
            <div className="cashback_history_data">
              <h4>{client_offer} Cashback</h4>
            </div>
            <ul className="list_bolet_style">
              {cashback_rates?.map((val, ind) => {
                return <li key={ind}>{val}</li>;
              })}
            </ul>
          </>
        )}
      </div>
    </>
  );
}

export default CashbackFilter;
