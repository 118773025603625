import React from "react";
import { FiPackage } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useGetStoreQuery } from "../../App/Slice/storeSlice";

function ClientFilter() {
  const { data } = useGetStoreQuery();
  const storeData = data?.client;

  const store = storeData?.filter((ele) => ele.status.includes("success"));
  
  return (
    <>
      <div className="category-sides category_coupon_side mb-4">
        <h3>Brands</h3>
        <ul>
          {store?.map((val, ind) => {
            return (
              <li key={ind}>
                <span>
                  <FiPackage />
                </span>
                <Link to={`/coupons/category/${val.name}`}>{val.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default ClientFilter;
