import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const coupons = createApi({
  reducerPath: "coupons",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCoupons: builder.query({
      query: () => ({
        url: "/coupons",
        method: "GET",
      }),
    }),

    getByIdCoupons: builder.query({
      query: (id) => ({
        url: `/coupons/${id}`,
        method: "GET",
      }),
    }),

    getSearchQueryCoupons: builder.query({
      query: (q) => {
        return {
          url: `/coupons/search/q/${q}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetCouponsQuery,
  useGetSearchQueryCouponsQuery,
  useGetByIdCouponsQuery,
} = coupons;
