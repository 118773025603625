import React from "react";
import Heading from "../../Components/Heading";
import { useGetStoreQuery } from "../../App/Slice/storeSlice";
import { Link } from "react-router-dom";
import Skeleton from "react-skeleton-loader";

function Store() {
  const { data, isLoading } = useGetStoreQuery();
  const storeData = data?.client;

  const store = storeData
    ?.filter((item) => item.status.includes("success"));

  const trending = store?.filter((ele) => ele.trending.includes("true"))?.slice(0, 30);

  return (
    <>
      <div className="container deals-section mt-4 mb-4">
        <Heading link="/campaign-store" heading="All Stores" />
        {isLoading ? (
          <div className="row mb-5">
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mb-5">
            {trending?.map((val, ind) => {
              const { _id, client_offer, calc, logo, name } = val;
              const cli = name?.split(" ")?.join("-");
              return (
                <div key={ind} className=" col-lg-2 col-md-3 col-6 mb-4">
                  <Link
                    to={`/campaign-store/${cli}/${_id}`}
                    className="store-inner"
                  >
                    <div className="outer-store" title={name}>
                      <img src={logo} loading="lazy" alt={name} />
                      {calc[0] === null || calc[0] === "Paused" ? null : (
                        <h5 className="highlight_offer">
                          {client_offer} Cashback
                        </h5>
                      )}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default Store;
