import React from "react";
import { Link, useParams } from "react-router-dom";
import { FiArrowUpRight, FiChevronRight } from "react-icons/fi";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { useGetByIdCouponsQuery } from "../../App/Slice/couponsSlice";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetOrderIDQuery } from "../../App/Slice/orderIDSlice";
import { Helmet } from "react-helmet";

function CouponDetails() {
  const params = useParams();
  const key = params;
  const store = key?.client?.split("-")?.join(" ");
  const id = key?.id;
  const name = key?.name?.split("-")?.join(" ");

  const share_loc = window.location;

  const { data, isLoading } = useGetByIdCouponsQuery(id);
  const coupon = data?.coupons || {};

  const {
    valid_date,
    title,
    client,
    category,
    terms_condition,
    coupon_input,
    coupon_offer,
    keyword,
    description,
    link,
  } = coupon;

  const calc = client?.calc;
  const calcu = calc?.[1];
  const cashback = calc?.[0]?.split(" ")?.join("-");

  const { data: userData } = useNormalUserVerifyQuery();
  const user = userData?.user_data || {};

  const { data: orderId } = useGetOrderIDQuery();
  const order_id = orderId?.order_id;

  const generateUniqueRandom17DigitNumber = () => {
    const min = Math.pow(10, 9);
    const max = Math.pow(10, 10) - 1;
    const random17DigitNumber =
      Math.floor(Math.random() * (max - min + 1)) + min;

    const existingOrder = random17DigitNumber;
    if (existingOrder === order_id) {
      return generateUniqueRandom17DigitNumber();
    }
    return random17DigitNumber;
  };
  const random17DigitNumber = generateUniqueRandom17DigitNumber();

  const source = user?.sub_id
    ? `&source=${user.sub_id}`
    : "&source=CD_wo_lo_2023";
  const p5 = `&p5=CD${random17DigitNumber}`;
  const campaign_name = `&p8=${name}`; //store name

  const modify_link = `${link}${p5}&p7=${cashback}+${calcu}${source}${campaign_name}`;

  const copyCouponHandler = (link, client) => {
    navigator.clipboard.writeText(`${link}`);
    alert("COUPON IS COPY 👍");
    setTimeout(() => {
      window.open(modify_link);
    }, 1000);
  };

  const redirectCouponHandler = (client) => {
    const client_link = client?.link;
    setTimeout(() => {
      window.open(modify_link);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keyword} />
      </Helmet>

      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="/coupons/category/All">Coupons</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to={`/coupons/category/${store}`}>{store}</Link>
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5 single-coupons-details">
        {isLoading ? (
          <div className="h-50 d-flex align-items-center justify-content-center">
            <p>Fetching data...</p>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="coupons-details">
                <div className="coupon-logo-img mb-3">
                  <img src={client?.logo} loading="lazy" alt={client?.title} />
                </div>
                <h1 className="text-danger">{coupon_offer}</h1>
                <h2>{title}</h2>
              </div>
              <div className="copy-coupons-details mt-4 d-flex justify-content-start">
                <h4 className="mb-0" onClick={() => redirectCouponHandler()}>
                  {coupon_input}
                </h4>
                <button
                  type="button"
                  onClick={() => copyCouponHandler(coupon_input)}
                >
                  COPY NOW
                </button>
              </div>
              <div className="coupons-web-redirect mt-4 mb-2">
                <Link to={modify_link} target="_blank">
                  <FiArrowUpRight /> Go to {client?.name}
                </Link>
              </div>
              <div className="link-shares mt-3 mb-3">
                <FacebookShareButton url={share_loc}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={share_loc}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <LinkedinShareButton url={share_loc}>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                <WhatsappShareButton url={share_loc}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </div>
              <h6 className="fw-bold">Category:</h6>
              <p className="desc_coupon">{category?.name}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <p className="expired_date fw-bold text-danger">
                Expired : <span>{valid_date}</span>
              </p>

              {terms_condition?.length === 0 ? null : (
                <ul className="feature-list">
                  <h6 className="fw-bold">Terms & Condition</h6>
                  {terms_condition?.map((val, ind) => {
                    return <li key={ind}>{val}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CouponDetails;
