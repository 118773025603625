import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetToken, URL_LINK } from "../../Secure/Helper";

export const transaction = createApi({
  reducerPath: "transaction",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getTransaction: builder.query({
      query: () => ({
        url: "/transaction/user-report",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GetToken()}`,
        },
      }),
    }),
    
  }),
});

export const { useGetTransactionQuery } = transaction;
