import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetToken, URL_LINK } from "../../Secure/Helper";

export const cashbackHistory = createApi({
  reducerPath: "cashbackhistory",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCashbackHistory: builder.query({
      query: () => ({
        url: "/cashback-history",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GetToken()}`,
        },
      }),
    }),
  }),
});

export const { useGetCashbackHistoryQuery } = cashbackHistory;
