import React from "react";
import { useState } from "react";
import { useCreateSupportMutation } from "../../App/Slice/supportSlice";

function CreateSupport() {
  let user = JSON.parse(localStorage.getItem("user_data"));

  const [description, setDescription] = useState();
  const [file, setFile] = useState();

  const [mess, setMess] = useState(false);

  const [createSupport, isLoading] = useCreateSupportMutation();
  const apiLoading = isLoading?.isLoading;

  const submitSupportForm = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user", user.id);
    formData.append("description", description);
    formData.append("file", file);

    if (!description || !file) {
      alert("all fields are required!");
    } else {
      const response = await createSupport(formData);
      const result = response?.data;
      if (result?.status === "success") {
        setMess(result?.message);
        setTimeout(() => {
          window.location.href = "/user/profile/support";
        }, 2000);
      } else {
        return;
      }
    }
  };
  return (
    <>
      <>
        <div
          className="modal fade"
          id="exampleModalToggleSupport"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleSupportLabel"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-body">
                <div className="outer-form missing-report-section">
                  <form
                    onSubmit={submitSupportForm}
                    className="form-data pt-2 pb-2"
                  >
                    <div className="register-heading mb-3">
                      <h5>Support</h5>
                    </div>
                    <div className="missing-fields">
                      <label>Enter your Message</label>
                      <textarea
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="missing-fields">
                      <label>Upload File</label>
                      <input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    {mess && (
                      <div className="alert alert-success" role="alert">
                        {mess}
                      </div>
                    )}
                    <div className="missing-fields input-login mt-2">
                      <button
                        type="submit"
                        className="btn"
                        disabled={apiLoading}
                      >
                        {apiLoading ? "Please wait..." : "Create Support"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default CreateSupport;
