import React, { useState } from "react";
import { useGetStoreQuery } from "../../App/Slice/storeSlice";
import { useCreateMissingReportMutation } from "../../App/Slice/missingReportSlice";

function Missing_Report(name) {
  const user = name?.name;

  const [createReport, isLoading] = useCreateMissingReportMutation();
  const apiLoading = isLoading?.isLoading;
  const { data } = useGetStoreQuery();
  const store = data?.client;

  const [transaction, setTransaction] = useState();
  const [transactionDate, setTransactionDate] = useState();
  const [client, setClient] = useState();
  const [couponApply, setCouponApply] = useState();
  const [amount, setAmount] = useState();
  const [source, setSource] = useState();
  const [productName, setProductName] = useState();
  const [message, setMessage] = useState();

  const [mess, setMess] = useState(false);

  const missinReportSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      user: user._id,
      order_transaction_id: transaction,
      transaction_date: transactionDate,
      client: client,
      coupon_apply: couponApply,
      amount: amount,
      source_website_app: source,
      product_name: productName,
      message: message,
    };
    if (
      !transaction ||
      !transactionDate ||
      !client ||
      !couponApply ||
      !amount ||
      !source ||
      !productName
    ) {
      alert("all fields are required!");
    } else {
      const response = await createReport(data);
      const result = response?.data;
      console.log(result);
      if (result?.status === "success") {
        setMess(result?.message);
        setTimeout(() => {
          window.location.href = "/user/profile/missing-report-cashback";
        }, 1500);
      } else {
        return;
      }
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleMissingReport"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleMissingReportLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className="outer-form missing-report-section">
                <form
                  action=""
                  className="form-data pt-2 pb-2"
                  onSubmit={missinReportSubmitHandler}
                >
                  <div className="register-heading mb-3">
                    <h5>Report Missing Transaction</h5>
                  </div>
                  <div className="missing-fields">
                    <label>Order ID / Transaction ID</label>
                    <input
                      type="text"
                      placeholder="Enter order ID"
                      onChange={(e) => setTransaction(e.target.value)}
                    />
                  </div>
                  <div className="missing-fields missing-fields-flex">
                    <span>
                      <label>Transaction Date</label>
                      <input
                        type="date"
                        placeholder="Select Date"
                        onChange={(e) => setTransactionDate(e.target.value)}
                      />
                    </span>
                    <span>
                      <label>Order Amount</label>
                      <input
                        type="text"
                        placeholder="Enter Amount"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className="missing-fields missing-fields-flex">
                    <span>
                      <label>Select Store</label>
                      <select onChange={(e) => setClient(e.target.value)}>
                        <option value="Select store" selected disabled>
                          Select store
                        </option>
                        {store?.map((val, ind) => {
                          return (
                            <option key={ind} value={val._id}>
                              {val.name}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                    <span>
                      <label>Product Name</label>
                      <input
                        type="text"
                        placeholder="Like: t-shirt"
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className="missing-fields missing-fields-flex">
                    <span>
                      <label>Coupon Applied</label>
                      <select onChange={(e) => setCouponApply(e.target.value)}>
                        <option value="Select Conditions" selected disabled>
                          Select Conditions
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </span>
                    <span>
                      <label>Source</label>
                      <select onChange={(e) => setSource(e.target.value)}>
                        <option value="Select Conditions" selected disabled>
                          Select Conditions
                        </option>
                        <option value="Mobile">Mobile</option>
                        <option value="Website">Website</option>
                      </select>
                    </span>
                  </div>
                  <div className="missing-fields">
                    <label>Other Details</label>
                    <textarea
                      type="text"
                      placeholder="Enter Ohter Details"
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  {mess && (
                    <div className="alert alert-success" role="alert">
                      {mess}
                    </div>
                  )}
                  <div className="missing-fields input-login mt-2">
                    <button type="submit" className="btn" disabled={apiLoading}>
                      {apiLoading ? "Please wait..." : "Add Missing Report"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Missing_Report;
