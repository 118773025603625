import React from "react";
import { Link } from "react-router-dom";
import { useGetBlogQuery } from "../../App/Slice/blogSlice";

function BlogCard() {
  const { data } = useGetBlogQuery();
  const blogData = data?.blog;

  const blog = blogData?.filter((item) => item.status.includes("success"));

  return (
    <>
      {blog?.map((val, ind) => {
        const { _id, image, title, short_desc, createdAt, category } = val;
        const titles = title?.split(" ")?.join("-");
        return (
          <div key={ind} className="col-lg-3 col-md-6 col-12 mb-4">
            <Link to={`/blog/${titles}/${_id}`}>
              <div className="blog-card">
                <div className="blog-image">
                  <img src={image} loading="lazy" alt={title} />
                  <span>{category?.name}</span>
                </div>
                <div className="blog-content">
                  <h3>{title?.slice(0, 60)}...</h3>
                  <p>{short_desc?.slice(0, 100)}</p>
                  <h6>{createdAt?.slice(0, 10)}</h6>
                  <button type="button">Read More</button>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </>
  );
}

export default BlogCard;
