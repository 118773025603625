import React from "react";
import { useGetStoreQuery } from "../../App/Slice/storeSlice";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { Helmet } from "react-helmet";
import Skeleton from "react-skeleton-loader";

function All_Store() {
  const { data, isLoading } = useGetStoreQuery();
  const storeData = data?.client;

  const store = storeData?.filter((item) => item.status.includes("success"));

  return (
    <>
      {/* Unlock Savings Wonderland: Shop Smarter, Earn Bigger Cashbacks – Your
          One-Stop Store for Deals Galore! */}
      <Helmet>
        <title>All Store</title>
        <meta
          name="description"
          content="Step into a universe of curated stores that align with your preferences, offering a plethora of choices from your beloved brands. Immerse yourself in the shopping realm, where you not only access the best deals but also unlock maximum savings and cashbacks. Welcome to a world tailored to your preferences!"
        />
        <meta name="keywords" content="" />
      </Helmet>

      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Store</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container deals-section mt-4 mb-4">
        {isLoading ? (
          <div className="row mb-5">
            <div className=" col-lg-2 col-md-3 col-6 mb-4">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6 mb-4">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6 mb-4">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6 mb-4">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6 mb-4">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6 mb-4">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
            <div className=" col-lg-2 col-md-3 col-6">
              <div className="outer-store">
                <Skeleton color="#d6e7ff" />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mb-5">
            {store?.map((val, ind) => {
              const { client_offer, calc, logo, name, _id } = val;
              const cli = name?.split(" ")?.join("-");
              return (
                <div key={ind} className=" col-lg-2 col-md-3 col-6 mb-4">
                  <Link
                    to={`/campaign-store/${cli}/${_id}`}
                    className="store-inner"
                  >
                    <div className="outer-store" title={name}>
                      <img src={logo} loading="lazy" alt={name} />
                      {/* {calc[0] === null || calc[0] === "Paused" ? (
                        <h4 className="without_name d-flex align-items-center justify-content-center">
                          {name}
                        </h4>
                      ) : (
                        <h4>{name}</h4>
                      )} */}
                      {calc[0] === null || calc[0] === "Paused" ? null : (
                        <h5 className="highlight_offer">
                          {client_offer} Cashback
                        </h5>
                      )}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default All_Store;