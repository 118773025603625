import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { category } from "./Slice/categorySlice";
import { store } from "./Slice/storeSlice";
import { deals } from "./Slice/dealSlice";
import { user } from "./Slice/userSlice";
import { MissingReport } from "./Slice/missingReportSlice";
import { WithdrawMoney } from "./Slice/withdrawMoneySlice";
import { coupons } from "./Slice/couponsSlice";
import { orderID } from "./Slice/orderIDSlice";
import { transaction } from "./Slice/transactionSlice";
import { cashbackHistory } from "./Slice/cashbackHistorySlice";
import { banner } from "./Slice/bannerSlice";
import { blog } from "./Slice/blogSlice";
import { contact } from "./Slice/contactSlice";
import { career } from "./Slice/careerSlice";
import { support } from "./Slice/supportSlice";
import { partner } from "./Slice/partnerSlice";

const Store = configureStore({
  reducer: {
    [category.reducerPath]: category.reducer,
    [store.reducerPath]: store.reducer,
    [deals.reducerPath]: deals.reducer,
    [user.reducerPath]: user.reducer,
    [MissingReport.reducerPath]: MissingReport.reducer,
    [WithdrawMoney.reducerPath]: WithdrawMoney.reducer,
    [coupons.reducerPath]: coupons.reducer,
    [orderID.reducerPath]: orderID.reducer,
    [transaction.reducerPath]: transaction.reducer,
    [cashbackHistory.reducerPath]: cashbackHistory.reducer,
    [banner.reducerPath]: banner.reducer,
    [blog.reducerPath]: blog.reducer,
    [contact.reducerPath]: contact.reducer,
    [career.reducerPath]: career.reducer,
    [support.reducerPath]: support.reducer,
    [partner.reducerPath]: partner.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      category.middleware,
      store.middleware,
      deals.middleware,
      user.middleware,
      MissingReport.middleware,
      WithdrawMoney.middleware,
      coupons.middleware,
      orderID.middleware,
      transaction.middleware,
      cashbackHistory.middleware,
      banner.middleware,
      blog.middleware,
      contact.middleware,
      career.middleware,
      support.middleware,
      partner.middleware,
    ]),
});

setupListeners(Store.dispatch);
export default Store;
