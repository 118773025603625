import React, { useState } from "react";
import { FiLock, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetLoginUserMutation } from "../App/Slice/userSlice";
import ForgotMailSend from "./ForgotMailSend";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa6";

function Login() {
  const [createLogin, isLoading] = useGetLoginUserMutation();
  const apiLoading = isLoading?.isLoading;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [show, setShow] = useState(false);

  const loginSubmitHandler = async (e) => {
    e.preventDefault();
    const data = { email: email, password: password };
    if (
      !email ||
      (email === undefined && !password) ||
      password === undefined
    ) {
      toast.error("all fields are required!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const response = await createLogin(data);
    const result = response?.data;
    const normal_user = result?.user;
    if (normal_user?.role === "admin") {
      toast.error("your credentials is wrong!", {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      return;
    } else if (result.status === "success") {
      toast.success(`${result.message}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      localStorage.setItem("user_data", JSON.stringify(result.user));
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
      return;
    } else {
      toast.error(`${result.message}`, {
        position: "top-right",
        autoClose: 1000,
        theme: "dark",
      });
      return;
    }
  };

  const showPasswordHandler = () => {
    setShow(!show);
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body login_form">
              <div className="outer-form">
                <form
                  action=""
                  className="form-data pt-4 pb-4"
                  onSubmit={loginSubmitHandler}
                >
                  <div className="register-heading mb-5">
                    <h5>SIGN IN TO CASHDHUNDO</h5>
                  </div>
                  <div className="input-fields">
                    <span>
                      <FiUser />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter E-mail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-fields mb-1">
                    <span>
                      <FiLock />
                    </span>
                    <input
                      type={show === true ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="show_password"
                      onClick={showPasswordHandler}
                    >
                      {!show === true ? <FaEyeSlash /> : <IoEyeSharp />}
                    </span>
                  </div>
                  <div className="forgot-link">
                    <Link
                      to=""
                      data-bs-target="#exampleModalToggleForgot"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      Forgot Password
                    </Link>
                  </div>
                  <div className="input-fields input-login mt-2">
                    <button type="submit" className="btn" disabled={apiLoading}>
                      {apiLoading ? "Please wait..." : "Login"}
                    </button>
                  </div>
                  <div className="or-section">
                    <h5>OR</h5>
                  </div>
                  {/* <div className="social-media-login pt-3 pb-1">
                    <div className="input-fields">
                      <span>
                        <img
                          src="../../assets/img/social/google.png"
                          loading="lazy" alt="cashdhundo"
                        />
                      </span>
                      <h5>Google</h5>
                    </div>
                    <div className="input-fields">
                      <span>
                        <img
                          src="../../assets/img/social/facebook.png"
                          loading="lazy" alt="cashdhundo"
                        />
                      </span>
                      <h5>Facebook</h5>
                    </div>
                  </div> */}
                  <div className="input-content mt-3">
                    <h5>
                      Don't have an account?{" "}
                      <Link
                        to=""
                        data-bs-target="#exampleModalToggle2"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Create an account
                      </Link>
                    </h5>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ForgotMailSend />
    </>
  );
}

export default Login;
