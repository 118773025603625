import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoCreateSharp } from "react-icons/io5";
import { useCreateContactMutation } from "../App/Slice/contactSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function Contact() {
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const [createContact, isLoading] = useCreateContactMutation();
  const loading = isLoading?.isLoading;

  const contactFormHandler = async (e) => {
    e.preventDefault();
    const data = {
      name: `${firstName} ${lastName}`,
      email: email,
      subject: subject,
      message: message,
    };
    if (!firstName || !lastName || !email || !message) {
      alert("All Fields are Required!");
    }
    const result = await createContact(data);
    const res = result?.data;
    if (res.status === "failed") {
      toast.error("Try Again", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(res.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/contact-us";
      }, 1000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content="Got shopping questions or cashback cravings? We're all ears (and eyes!) at CASHDHUNDO! Shoot us a message through email, live chat, or social media. Whether you need help finding the perfect deal on diapers or deciphering cashback magic, our friendly customer care crew is just a click away. We're passionate about savings, so don't hesitate to reach out - no question is too big or too small for the CASHDHUNDO squad! Remember, happy shopping starts with happy shoppers, and we're here to make sure your smile stays glued on!"
        />
        <meta
          name="keywords"
          content="contact, contact-us, contact cashdhundo, cashdhundo contact, cashdhundo.com"
        />
      </Helmet>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">Let's Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12">
            <div className="contact_content">
              <h4>Contact Us</h4>
              <p>
                Got cashback questions? Cashdhundo is here for you! Chat anytime
                with our friendly team on WhatsApp, email, or call. We're ready
                to help you save and celebrate! Email us at <a href="mailto:contact@cashdhundo.com" target="_blank">contact@cashdhundo.com</a>, and our team will get back to you to
                answer all your queries.
              </p>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="contact_us_section">
              <form
                className="form-data pt-4 pb-4"
                onSubmit={contactFormHandler}
              >
                <div className="first_name d-flex justify-content-between">
                  <div className="input-fields">
                    <span>
                      <FaUserCircle />
                    </span>
                    <input
                      type="text"
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="input-fields">
                    <span>
                      <FaUserCircle />
                    </span>
                    <input
                      type="text"
                      placeholder="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="input-fields contact_fields">
                  <span>
                    <MdEmail />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter Your E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields contact_fields">
                  <span>
                    <IoCreateSharp />
                  </span>
                  <input
                    type="text"
                    placeholder="Subject ( Optional )"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="contact_fields">
                  <textarea
                    placeholder="Enter Your Message."
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="input-fields contact_fields mt-3 p-0 mb-0">
                  <button className="btn" type="submit" disabled={loading}>
                    {loading ? "Please Wait..." : "SUMBIT"}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-12 mt-4">
            <div className="contact_map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.0191674312014!2d77.03548907448518!3d28.418678493761753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23d3e8c9dc6f%3A0xfaf6ebbf6d13c843!2sMediaxpedia%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1703161684439!5m2!1sen!2sin"
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Contact;
