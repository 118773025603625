import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetToken, URL_LINK } from "../../Secure/Helper";

export const support = createApi({
  reducerPath: "support",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getSupport: builder.query({
      query: () => {
        return {
          url: "/support/user",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GetToken()}`,
          },
        };
      },
    }),

    createSupport: builder.mutation({
      query: (data) => {
        return {
          url: "/support",
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${GetToken()}`,
          },
        };
      },
    }),
  }),
});

export const { useCreateSupportMutation, useGetSupportQuery } = support;
