import React from "react";
import BlogCard from "../Blog/BlogCard";
import Heading from "../../Components/Heading";
import { useGetBlogQuery } from "../../App/Slice/blogSlice";

function Blog() {
  const { data, isLoading } = useGetBlogQuery();

  if (isLoading) {
    return null
  }
  return (
    <>
      <div className="container mt-5 mb-5">
        <Heading heading="Latest News" link="/blog/category/filter/All" />
        <div className="row">
          <BlogCard />
        </div>
      </div>
    </>
  );
}

export default Blog;
