import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const career = createApi({
  reducerPath: "career",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    createCareer: builder.mutation({
      query: (data) => {
        return {
          url: "/career",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useCreateCareerMutation } = career;
