import React from "react";

function LostCashbacks() {
  return (
    <>
      <h3 className="faq_heading">
        Lost Cashbacks? Fear Not, We're on the Case!
      </h3>
      <div className="accordion-faq">
        <div className="accordion accordion-flush" id="accordionFlushTraking">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOneTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOneTraking"
                aria-expanded="false"
                aria-controls="flush-collapseOneTraking"
              >
                At what point does the tracking of my Cashback occur?
              </button>
            </h2>
            <div
              id="flush-collapseOneTraking"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOneTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                The tracking time for Cashback varies for each site. To find the
                tracking time for a specific site, you can refer to its Store
                Page on cashdhundo. Typically, Cashback take anywhere between 5
                minutes to 72 hours to track in your cashdhundo account. Once the
                tracking is complete, you will receive a notification through
                email and SMS, ensuring you are promptly informed of the status.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwoTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwoTraking"
                aria-expanded="false"
                aria-controls="flush-collapseTwoTraking"
              >
                What steps can I take if my Cashback hasn't been tracked?
              </button>
            </h2>
            <div
              id="flush-collapseTwoTraking"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwoTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                To initiate a missing Cashback ticket for untracked
                transactions, follow these straightforward steps on the cashdhundo
                homepage:
                <ol>
                  <li>
                    Click on the “Missing Report” button located in the bottom
                    corner.
                  </li>
                  <li>Select the retailer where you made your purchase.</li>
                  <li>Provide the date of your transaction.</li>
                  <li>Enter your order ID.</li>
                  <li>Input the amount of your order.</li>
                </ol>
                After entering these details, cashdhundo will proceed to track your
                Cashback within 60 minutes. You can then sit back and relax
                while they handle the rest of the process
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThreeTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThreeCashbackfact"
                aria-expanded="false"
                aria-controls="flush-collapseThreeCashbackfact"
              >
                When can I expect the resolution of my Missing Cashback Ticket?
              </button>
            </h2>
            <div
              id="flush-collapseThreeCashbackfact"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThreeTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                Recover your missing Cashback in just 5 to 60 minutes! Simply
                submit your query, and upon approval, we'll initiate the
                tracking process to ensure you don't miss out on any savings.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LostCashbacks;
