import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoCreateSharp } from "react-icons/io5";
import { useCreateContactMutation } from "../App/Slice/contactSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaRoad } from "react-icons/fa6";
import { MdFolderSpecial } from "react-icons/md";
import { MdPermMedia } from "react-icons/md";
import { useCreateCareerMutation } from "../App/Slice/careerSlice";
import { Helmet } from "react-helmet";

function Careers() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [designation, setDesignation] = useState();
  const [specialization, setSpecialization] = useState();
  const [file, setFile] = useState();
  const [description, setDescription] = useState();

  const [createContact, isLoading] = useCreateCareerMutation();
  const loading = isLoading?.isLoading;

  const careerFormHandler = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !email ||
      !phone ||
      !designation ||
      !specialization ||
      !file ||
      !description
    ) {
      alert("All Fields are Required!");
    }

    const formData = await new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("designation", designation);
    formData.append("specialization", specialization);
    formData.append("file", file);
    formData.append("description", description);

    const result = await createContact(formData);
    const res = result?.data;
    if (res.status === "failed") {
      toast.error("Try Again", {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });
      return;
    } else {
      toast.success(res.message, {
        position: "top-right",
        autoClose: 1000,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/career";
      }, 2000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Careers</title>
        <meta
          name="description"
          content="At CASHDHUNDO, we're not just passionate about deals, we're passionate about our people. Join our vibrant team and unlock a career filled with savings magic! Dive into diverse roles across tech, marketing, finance, and more, all fueled by the thrill of helping millions find joy in smart shopping. Be a deal detective, craft cashback spells, or spread the savings gospel – your talent sparks endless possibilities. Here, you'll work with a squad of like-minded wizards, celebrate wins every step of the way, and leave your mark on the world (and someone's shopping cart)! Ready to cast your career spell at CASHDHUNDO? We're waiting for you!"
        />
        <meta
          name="keywords"
          content="careers, cashdhundo careers , jobs, job in gurugram"
        />
      </Helmet>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">cashdhundo - Careers</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 mb-5 bg-white">
        <div class="row p-4">
          <div class="col-lg-5 col-md-12 col-12">
            <div class="careers_storeis">
              <div class="career_about">
                <h5 class="mb-3">6 Reasons to Join Our Company</h5>

                <ul>
                  <li>
                    <b>Passionate Mission and Vision</b>
                  </li>
                  <li>
                    <b>Growth & Development</b>
                  </li>
                  <li>
                    <b>Collaborative & Supportive Culture</b>
                  </li>
                  <li>
                    <b>Innovation & Creativity</b>
                  </li>
                  <li>
                    <b>Rewarding Career Opportunity </b>
                  </li>
                  <li>
                    <b>Positive & Fun Workplace</b>
                  </li>
                </ul>
              </div>
              <div class="career_about mt-4">
                <h5 class="mb-3">INTERVIEW TIPS</h5>

                <ul>
                  <li>
                    <b>Know Your Value & Research the Company</b>
                  </li>
                  <li>
                    <b>Practice Makes Perfect</b>{" "}
                  </li>
                  <li>
                    <b>Ask Smart Questions</b>
                  </li>
                  <li>
                    <b>Dress for Success</b>
                  </li>
                  <li>
                    <b>Body Language & Communication</b>
                  </li>
                  <li>
                    <b>Highlight Teamwork & Problem-Solving</b>
                  </li>
                  <li>
                    <b>Follow Up & Express Gratitude</b>
                  </li>
                  <li>
                    <b>Be Yourself & Stay Positive</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-7 col-md-12 col-12">
            <div class="careers_heading text-center">
              <h4>Create a Profile with CASHDHUNDO Careers</h4>
              <p>
                Want to join us as a full-time team member at cashdhundo? Kindly
                fill this form
              </p>
            </div>
            <div className="contact_us_section careers_data_contact">
              <form class="form-data" onSubmit={careerFormHandler}>
                <div class="large-form-with">
                  <div class="input-fields large-width">
                    <span>
                      <FaUserCircle />
                    </span>
                    <input
                      type="text"
                      placeholder="jhon xyz"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div class="input-fields large-width">
                    <span>
                      <FaPhoneSquareAlt />
                    </span>
                    <input
                      type="phone"
                      placeholder="+91 99901 00200"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div class="input-fields">
                  <span>
                    <MdEmail />
                  </span>
                  <input
                    type="email"
                    placeholder="jhon@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class="large-form-with">
                  <div class="input-fields large-width">
                    <span>
                      <FaRoad />
                    </span>
                    <select onChange={(e) => setDesignation(e.target.value)}>
                      <option value="your designation" disabled selected>
                        your designation
                      </option>
                      <option value="Intern">Intern</option>
                      <option value="Executive">Executive</option>
                      <option value="Senior Executive">Senior Executive</option>
                      <option value="Associate Manager">
                        Associate Manager
                      </option>
                      <option value="Manager">Manager</option>
                      <option value="Senior Manager">Senior Manager</option>
                      <option value="Head">Head</option>
                    </select>
                  </div>
                  <div class="input-fields large-width">
                    <span>
                      <MdFolderSpecial />
                    </span>
                    <select onChange={(e) => setSpecialization(e.target.value)}>
                      <option value="your specialization" disabled selected>
                        your specialization
                      </option>
                      <option value="Ad Operations">Ad Operations</option>
                      <option value="Human Resource">Human Resource</option>
                      <option value="Business Development">
                        Business Development
                      </option>
                      <option value="Lead Generation / CPL">
                        Lead Generation / CPL
                      </option>
                      <option value="Mobile Delivery / CPI">
                        Mobile Delivery / CPI
                      </option>
                      <option value="CPS Delivery">CPS Delivery</option>
                      <option value="Client Servicing">Client Servicing</option>
                      <option value="IGaming">IGaming</option>
                      <option value="Finance">Finance</option>
                      <option value="Graphic Designing">
                        Graphic Designing
                      </option>
                      <option value="Full Stack Development">
                        Full Stack Development
                      </option>
                      <option value="Front-End Development">
                        Front-End Development
                      </option>
                      <option value="Back-End Development">
                        Back-End Development
                      </option>
                    </select>
                  </div>
                </div>
                <div class="input-fields">
                  <span>
                    <MdPermMedia />
                  </span>
                  <input
                    type="file"
                    class="btn btn-light"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="contact_fields">
                  <textarea
                    placeholder="Short Description for About us"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div class="input-fields p-0 mt-3">
                  <button
                    class="btn btn-light"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;
