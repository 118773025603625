import React from "react";
import { Link } from "react-router-dom";

function CashbackFilterAll({ titles }) {

  return (
    <>
      <div className="category-sides mb-4">
        <div className="category_icons_filter d-flex justify-content-between">
          <h3>Category</h3>
        </div>
        <div className="filter_btn mt-3 d-flex justify-content-between">
          <Link to={`/campaign-store/category/${titles}/Deals`} type="button">
            DEALS
          </Link>
          <Link to={`/campaign-store/category/${titles}/Coupons`} type="button">
            COUPONS
          </Link>
        </div>
      </div>
    </>
  );
}

export default CashbackFilterAll;
