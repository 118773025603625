import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetToken, URL_LINK } from "../../Secure/Helper";

export const MissingReport = createApi({
  reducerPath: "MissingReport",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getMissingReport: builder.query({
      query: () => ({
        url: "/missing-report/user",
        method: "GET",
        headers: {
          Authorization: `Bearer ${GetToken()}`,
        },
      }),
    }),

    createMissingReport: builder.mutation({
      query: (body) => ({
        url: "/missing-report/user",
        method: "POST",
        headers: {
          Authorization: `Bearer ${GetToken()}`,
        },
        body:body
      }),
    }),

  }),
});

export const { useGetMissingReportQuery, useCreateMissingReportMutation } = MissingReport;
