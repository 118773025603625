import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "./Page/Home";
import Footer from "./Components/Footer";
import Profile from "./Profile/User/Profile";
import Transaction from "./Profile/User/Transaction";
import Wallet from "./Profile/User/Wallet";
import Cashback from "./Profile/User/Cashback";
import MissingReport from "./Profile/User/Missing_Report";
import ClickReport from "./Profile/User/ClickReport";
import Support from "./Profile/User/Support";
import VerifyEmail from "./Profile/Verify_Email";
import ForgotPassword from "./Profile/ForgotPassword";
import Navbar from "./Components/Navbar";
import Protected from "./Secure/Protected";
import EarningHistory from "./Profile/User/EarningHistory";
import Store from "./Page/Store";
import Blog from "./Page/Blog";
import CampaignStore from "./View/Store/Campaign_Store";
import MainCoupons from "./View/Coupons/Main_Coupons";
import ScrollToTop from "./Secure/Scroll";
import ViewDeals from "./View/Store/View_Deals";
// import ViewCoupons from "./View/Coupons/View_Coupons";
import Refer_Earn from "./Page/Refer_Earn";
import PageNotFound from "./Page/PageNotFound";
import CouponDetails from "./View/Coupons/CouponDetails";
import SingleBlog from "./View/Blog/SingleBlog";
import Faq from "./Page/Faq"
import TermsAndCondition from "./Page/TermsAndCondition";
import CategoryByFilter from "./View/Store/CategoryByFilter";
import PrivicyPolicy from "./Page/PrivicyPolicy";
import AntiPolicy from "./Page/AntiPolicy";
import Contact from "./Page/Contact";
import Careers from "./Page/Career";
import About from "./Page/About";
import BecomePartner from "./Page/BecomePartner";
import Loading from "./Secure/Loading";

function App() {
  const loc = useLocation();
  let footerHide = loc.pathname;
  let forgotHide = loc.pathname?.slice(0, 24);
  // console.log("link :", forgotHide);

  return (
    <>
      <ScrollToTop />
      {footerHide === "/profile/email-verify" ||
      forgotHide === "/profile/forgot-password" ? (
        " "
      ) : (
        <Navbar />
      )}
      <Routes>
        <Route path="/" element={<Home />} />

        {/* ---------------------------------user-profile------------------------------ */}
        <Route
          path={"/user/profile"}
          element={<Protected ProtectRoute={Profile} />}
        />
        <Route path={"/user/profile/transaction"} element={<Transaction />} />
        <Route
          path={"/user/profile/wallet"}
          element={<Protected ProtectRoute={Wallet} />}
        />
        <Route
          path={"/user/profile/CD-cashback"}
          element={<Protected ProtectRoute={Cashback} />}
        />
        <Route
          path={"/user/profile/missing-report-cashback"}
          element={<Protected ProtectRoute={MissingReport} />}
        />
        <Route
          path={"/user/profile/click-report"}
          element={<Protected ProtectRoute={ClickReport} />}
        />
        <Route
          path={"/user/profile/earning-history"}
          element={<Protected ProtectRoute={EarningHistory} />}
        />
        <Route
          path={"/user/profile/support"}
          element={<Protected ProtectRoute={Support} />}
        />

        <Route path="/profile/email-verify" element={<VerifyEmail />} />

        <Route
          path="/profile/forgot-password/:id/:token"
          element={<ForgotPassword />}
        />
        <Route
          path="/refer-earn"
          element={<Protected ProtectRoute={Refer_Earn} />}
        />
        {/* ---------------------------------user-profile------------------------------ */}

        <Route path="/campaign-store" element={<Store />} />
        <Route path="/coupons/category/:key" element={<MainCoupons />} />
        <Route path="/blog/category/filter/:category" element={<Blog />} />
        <Route path="/store/:client/:name/:id" element={<ViewDeals />} />
        <Route path="/coupon/:client/:name/:id" element={<CouponDetails />} />
        <Route path="/campaign-store/:client/:id" element={<CampaignStore />} />
        <Route path="/campaign-store/category/:title/:category" element={<CategoryByFilter />} />
        <Route path="/blog/:title/:id" element={<SingleBlog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-policy" element={<PrivicyPolicy />} />
        <Route path="/anti-policy" element={<AntiPolicy />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/career" element={<Careers />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/become-our-partner" element={<BecomePartner />} />

        <Route exact path="*" element={<PageNotFound />} />
        <Route path="/loading" element={<Loading />} />
      </Routes>

      {footerHide === "/profile/email-verify" ||
      forgotHide === "/profile/forgot-password" ? (
        " "
      ) : (
        <Footer />
      )}

      <ToastContainer />
    </>
  );
}

export default App;
