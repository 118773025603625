import React from "react";
import Header from "../View/Home/Header";
import Store from "../View/Home/Store";
import Deals from "../View/Home/Deals";
import Blog from "../View/Home/Blog";
import { Helmet } from "react-helmet";
import Testimonials from "../View/Home/Testimonials";

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Deals Delights, Discounts Dreams, Cashback Cheers with Cashdhundo
        </title>
        <meta
          name="description"
          content="Ditch full prices and hello cashback cheers! CASHDHUNDO unlocks a treasure trove of deals, discounts, and coupons for everything you love. Shop smarter, shop happier, and watch your savings grow with every purchase. CASHDHUNDO sprinkles cashback magic, rewarding you for shopping your faves. Join the savvy shopper community and celebrate savings victories together. Sign up, click, collect, and experience shopping bliss - one delightful deal at a time!"
        />
        <meta
          name="keywords"
          content="cashback, cashdhundo, coupons, myntra, ajio, flipkart, myntra coupons, best deals"
        />
      </Helmet>

      <Header />
      {/* <Process /> */}
      <Store />
      <Deals />
      <Blog />
      <Testimonials />
    </>
  );
}

export default Home;
