import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const contact = createApi({
  reducerPath: "contact",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    createContact: builder.mutation({
      query: (data) => {
        return {
          url: "/contact",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useCreateContactMutation } = contact;
