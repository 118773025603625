import React from "react";
import {
  useGetCategoryQuery,
  useGetSubCategoryQuery,
} from "../App/Slice/categorySlice";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useGetStoreQuery } from "../App/Slice/storeSlice";
import { IoIosCloseCircle } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa6";
import { TbCategory2 } from "react-icons/tb";

function Category() {
  const [hide, setHide] = useState(false);
  const [touchData, setTouchData] = useState();
  const [subTouchMouse, setSubTouchMouse] = useState();

  const { data, isLoading } = useGetCategoryQuery();
  const category = data?.category;
  const loading = isLoading;

  const cateFilter = category?.filter((ele) =>
    ele?.status?.includes("success")
  );

  const { data: subCategory } = useGetSubCategoryQuery();
  const subCate = subCategory?.sub_category;

  const cateSubFilter = subCate?.filter((ele) =>
    ele?.status?.includes("success")
  );

  const { data: clientData } = useGetStoreQuery();
  const store = clientData?.client;

  const clientFilter = store?.filter((ele) => ele?.status?.includes("success"));

  const categoryFilter = cateSubFilter?.filter((ele) => {
    return ele?.category?.name === touchData;
  });

  const storeFilter = clientFilter?.filter((ele) => {
    return ele?.search_keyword?.some((keyword) => keyword === subTouchMouse);
  });

  const hiddenCategoryHandler = () => {
    setHide(false);
  };

  const showCategoryHandler = () => {
    setHide(true);
  };
  return (
    <>
      <li className="nav-item mx-2 desktopHinden" title="Category">
        <div className="category_icon_" onClick={showCategoryHandler}>
          <TbCategory2 />
        </div>
        {hide === false ? null : (
          <div className="main_category_icon">
            <div
              className="close_category_icon"
              onClick={hiddenCategoryHandler}
            >
              <IoIosCloseCircle />
            </div>
            <ul className="hover_category_ul p-3">
              <h5>Category</h5>
              {cateFilter?.map((val, ind) => {
                const { name } = val;
                return (
                  <li key={ind} className="under_links">
                    <Link
                      className="nav-link"
                      to=""
                      onMouseEnter={() => setTouchData(val?.name)}
                    >
                      <span>
                        <FaAngleRight />
                      </span>{" "}
                      {name}
                      {categoryFilter?.length === 0 ? (
                        " "
                      ) : (
                        <div className="hover_categor_section pt-5">
                          <h5>{name}</h5>
                          <ul>
                            {categoryFilter?.map((val, ind) => {
                              return (
                                <li className="nav-items">
                                  <Link to="" key={ind}>
                                    <div
                                      className="category_sub_hover"
                                      onMouseEnter={() =>
                                        setSubTouchMouse(val?.name)
                                      }
                                    >
                                      <span>
                                        <FaAngleRight />
                                      </span>{" "}
                                      {val?.name}
                                      {storeFilter?.length === 0 ? (
                                        " "
                                      ) : (
                                        <div className="hover_store_section pt-5">
                                          <h5>{val?.name}</h5>
                                          <div className="hover_store_category">
                                            {storeFilter?.map((val, ind) => {
                                              const {
                                                _id,
                                                client_offer,
                                                logo,
                                                name,
                                                calc,
                                              } = val;
                                              const cli = name
                                                ?.split(" ")
                                                ?.join("-");
                                              return (
                                                <div
                                                  key={ind}
                                                  onClick={
                                                    hiddenCategoryHandler
                                                  }
                                                >
                                                  <Link
                                                    to={`/campaign-store/${cli}/${_id}`}
                                                    className="store-inner"
                                                  >
                                                    <div
                                                      className="outer-store"
                                                      title={name}
                                                    >
                                                      <img
                                                        src={logo}
                                                        loading="lazy"
                                                        alt={name}
                                                      />
                                                      {calc[0] ===
                                                      null ? null : (
                                                        <h5 className="highlight_offer">
                                                          {client_offer}{" "}
                                                          Cashback
                                                        </h5>
                                                      )}
                                                    </div>
                                                  </Link>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </li>
    </>
  );
}

export default Category;
