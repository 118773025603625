import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FiArchive,
  FiBookOpen,
  FiBriefcase,
  FiDollarSign,
  FiHelpCircle,
  FiMousePointer,
  FiSend,
  FiShuffle,
  FiUser,
} from "react-icons/fi";
// import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";

function Nav() {
  // const {data} = useNormalUserVerifyQuery();
  // const name = data?.user_data;

  const profileNav = [
    {
      id: 1,
      name: "Profile",
      link: "/user/profile",
      icon: <FiUser />,
    },
    {
      id: 2,
      name: "Transaction",
      link: "/user/profile/transaction",
      icon: <FiSend />,
    },
    {
      id: 3,
      name: "Wallet",
      link: "/user/profile/wallet",
      icon: <FiBriefcase />,
    },
    {
      id: 4,
      name: "CD Cashback",
      link: "/user/profile/CD-cashback",
      icon: <FiArchive />,
    },
    {
      id: 5,
      name: "Missing Report",
      link: "/user/profile/missing-report-cashback",
      icon: <FiShuffle />,
    },
    // {
    //   id: 6,
    //   name: "Click Report",
    //   link: "/user/profile/click-report",
    //   icon: <FiMousePointer />,
    // },
    // {
    //   id: 7,
    //   name: "Earning History",
    //   link: "/user/profile/earning-history",
    //   icon: <FiDollarSign />,
    // },
    {
      id: 8,
      name: "Support",
      link: "/user/profile/support",
      icon: <FiHelpCircle />,
    },
  ];

  return (
    <>
      <div className="col-lg-3">
        <div className="sticky-top">
          <div className="profile-nav">
            <ul>
              {profileNav?.map((val, ind) => {
                return (
                  <li key={ind} class="nav-item">
                    <Link class="nav-link mb-0" to={val?.link}>
                      <span>{val?.icon}</span> {val?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Nav;
