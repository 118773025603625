import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

function Heading(props) {
  return (
    <>
        <div className="row mb-5">
          <div className="col-12 w-auto">
            <div className="top-heading">
              <Link to={props.link}>
                <h1>{props.heading} <span><FiChevronRight /></span></h1>
              </Link>
            </div>
          </div>
        </div>
    </>
  );
}

export default Heading;
