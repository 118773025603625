import React from "react";
import Heading from "../../Components/Heading";
import { Link } from "react-router-dom";
import { useGetDealsQuery } from "../../App/Slice/dealSlice";

function Deals() {
  const { data, isLoading } = useGetDealsQuery();
  const deal_data = data?.deal;

  const ProductData = deal_data?.filter((ele) =>
    ele.convert_deal_Product.includes("product")
  );

  const Product = ProductData?.filter((item) =>
    item.status.includes("success")
  );

  const short_product = Product?.slice(0,8)
  if (isLoading) {
    return null;
  }
  return (
    <>
      <div className="container mt-5 mb-5">
        <Heading heading="Today's Deals" link="/campaign-store" />
        <div className="row">
          {short_product?.map((val, ind) => {
            const { _id, client, title, mrp_rate, real_rate, image } = val;
            const name = title?.split(" ")?.join("-");
            const cli = client?.name?.split(" ")?.join("-");
            const clclinet = client?.calc[0];
            return (
              <div key={ind} className="col-lg-3 col-md-4 col-12 mb-4">
                <Link to={`store/${client?.name}/${name}/${_id}`}>
                  <div className="today-deals">
                    <div className="product-image">
                      <img src={image} loading="lazy" alt={name} />
                      {/* <span>{client?.client_offer?.slice(0, 22)}...</span> */}
                    </div>
                    <div className="deals-content product_content">
                      <h1>{title?.slice(0, 50)}</h1>
                      <span className="d-flex align-items-center justify-content-center">
                        <h5 className="text-danger">
                          <s>MRP ₹{mrp_rate}</s>
                        </h5>{" "}
                        <h5>Price ₹{real_rate}</h5>
                      </span>
                      {clclinet === null || clclinet === "Paused" ? (
                        <h6 className="highlight_offer">
                          Cashback not Available
                        </h6>
                      ) : (
                        <h6 className="highlight_offer">
                          {client?.client_offer} Cashback
                        </h6>
                      )}
                    </div>
                    <div className="client-btn d-flex align-items-center justify-content-between">
                      <Link to={`/campaign-store/${cli}/${client?._id}`}>
                        <span>
                          <img
                            className="p-1"
                            src={client?.logo}
                            loading="lazy"
                            alt={client?.name}
                          />
                        </span>
                      </Link>
                      <Link
                        to={`store/${cli}/${name}/${_id}`}
                        type="button"
                        className="btn"
                      >
                        Show Now
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Deals;
