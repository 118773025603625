import React from "react";
import { Link } from "react-router-dom";

function PrivicyPolicy() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">Privacy Plicy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="terms_conditions_para p-4">
          <div className="condition_heading">
            <p>
              Cashdhundo, referred to collectively as 'We,' 'Our,' or 'Us'
              (inclusive of their respective legal heirs, representatives,
              administrators, permitted successors, and assigns, unless
              repugnant to the context), serves as the proprietors and/or
              administrators of the website and mobile application
              Cashdhundo.com ('Website,' 'Our Website,' or 'Site'). <br />{" "}
              <br /> In fulfilling the obligations to provide the Services (as
              defined in clause 1 below), Cashdhundo is required to gather and
              employ specific information from users of the Website ('Users')
              utilizing the Services, encompassing the capture, storage, and
              transmission of such information. This privacy policy ('Privacy
              Policy' or 'Policy') elucidates how We collect, use, share, and
              protect the personal information of Users of the Services,
              collectively denoted as 'You,' 'Your,' 'Yourself,' 'User,' or
              'Users' in this Privacy Policy. This Privacy Policy is established
              to underscore our unwavering commitment to preserving the privacy
              of Users' information during their interactions with our Services.
              Your use and access to the Services are subject to this Privacy
              Policy and our Terms and Conditions. <br /> <br />
              Any capitalized term used herein, not explicitly defined, shall
              carry the meaning assigned to it in our Terms and Conditions. The
              headings utilized herein are solely for the purpose of organizing
              the different provisions of the Privacy Policy. These headings are
              for reference purposes only and should not be interpreted to
              restrict or broaden the provisions of the clauses contained
              therein.
            </p>
          </div>
          <div className="condition_heading">
            <h2>1. DEFINITIONS</h2>
            <p>
              Cashdhundo, collectively denoted as "We," "Our," "Cashdhundo," or
              "Us," assumes ownership and/or operational responsibility for the
              website and mobile application Cashdhundo.com ("Website," "Our
              Website," "Site"). <br /> <br />
              In the process of delivering the Services (as specifically defined
              in clause 1 below), Cashdhundo undertakes the collection and
              utilization of specific information from users engaging with the
              Website ("Users"). This privacy policy ("Privacy Policy" or
              "Policy") serves as an elucidation of how We gather, employ,
              share, and safeguard the personal information of Users availing
              the Services. This Privacy Policy is crafted to articulate our
              steadfast commitment to preserving the privacy of Users'
              information during their engagement with our Services. Your
              utilization of and access to the Services are contingent upon
              compliance with this Privacy Policy and our Terms and Conditions.
              Any capitalized term utilized within this Privacy Policy, without
              explicit definition, is ascribed the meaning stipulated in our
              Terms and Conditions. <br /> <br />
              The headings utilized herein are designed exclusively for the
              purpose of structuring the diverse provisions of the Privacy
              Policy. These headings are provided for reference only and shall
              not be construed to limit or extend the provisions of the clauses
              contained therein. <br /> <br />
              Within this Privacy Policy, unless the context dictates otherwise,
              the defined terms shall carry the meanings assigned below, and
              related expressions shall be interpreted accordingly.
              <br /> <br />
            </p>
            <p>
              <span>a. Personal Information: </span>
              The term is ascribed the same definition delineated in Rule
              2(1)(i) of the Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal Data or
              Information) Rules, 2011. It pertains to any data possessing the
              capability to identify a natural person, either directly or
              indirectly, either in isolation or when amalgamated with other
              accessible information.
            </p>
            <p>
              <span>b. Sensitive Personal Data or Information: </span>
              As articulated in the SPI Rules, this category encompasses
              particulars concerning an individual's passwords, financial
              particulars, health status, sexual orientation, medical records,
              biometric data, information acquired under lawful contracts,
              visitor details, and call data records.
            </p>
            <p>
              <span>c. You, Your, Yourself, User: </span>
              These terms collectively denote both natural and legal
              individuals, as well as legal entities and companies, who engage
              in the use or visitation of the Services.
            </p>
            <p>
              <span>d. Third Parties: </span>
              This term is indicative of any website, application, web portal,
              company, or individual distinct from the User and Us.
            </p>
            <p>
              <span>e. Services</span>
              This encompasses the Website www.Cashdhundo.com and the Mobile
              Application (Cashdhundo), along with any contextual information
              exchanged with or received from Users through diverse
              communication channels.
            </p>
            <p>
              <span>f. User Information : </span>
              This term encompasses both Personal Information and Sensitive
              Personal Data or Information, encapsulating the entirety of data
              associated with the User.
            </p>
          </div>
          <div className="condition_heading">
            <h2>2. RATIONALE BEHIND THIS PRIVACY POLICY</h2>
            <p>
              This articulation of our Privacy Policy is meticulously crafted in
              adherence to several regulatory provisions, including but not
              limited to:
            </p>
            <p>
              <span>
                a. Section 43A of the Information Technology Act, 2000:
              </span>
              Section 43A of the Information Technology Act, 2000, serves as a
              foundational pillar underpinning the formulation of our Privacy
              Policy. This legislative framework impels us to delineate and
              elucidate our approach to handling sensitive information with
              utmost diligence.
            </p>
            <p>
              <span>b. Regulation 4 of the SPI Rules:</span> Complementing our
              commitment to privacy, Regulation 4 of the SPI Rules acts as a
              guiding directive shaping the contours of our data collection
              practices. This regulation intricately influences the manner in
              which we gather, process, and safeguard user information.
            </p>
            <p>
              <span>
                c. Regulation 3(1) of the Information Technology (Intermediaries
                Guidelines) Rules, 2011 ("Intermediaries Guidelines"):
              </span>{" "}
              The Privacy Policy is further aligned with the intricacies
              stipulated in Regulation 3(1) of the Information Technology
              (Intermediaries Guidelines) Rules, 2011. This regulation serves as
              a beacon, illuminating the standards and guidelines that govern
              our role as intermediaries in the digital landscape. <br /> In
              essence, our Privacy Policy serves as a comprehensive document
              that meticulously delineates: <br /> The nature and categorization
              of information procured from Users, including the handling of
              Sensitive Personal Data or Information (SPDI). <br /> The explicit
              detailing of the objectives, methodologies, and modalities
              employed in the utilization of such information. <br /> The
              explicit disclosure mechanisms, specifying how and to whom we
              intend to divulge the gathered information, ensuring transparency
              and accountability in our data practices.
            </p>
          </div>
          <div className="condition_heading">
            <h2>3. GENERAL CLAUSE</h2>
            <ol>
              <li>
                The User, in full acknowledgment and concurrence, recognizes
                that this Policy, in conjunction with the aforementioned Terms
                and Conditions, constitutes a legally binding accord between the
                User and Cashdhundo. The User is expressly beholden to the
                rules, guidelines, policies, terms, and conditions governing any
                service proffered by Cashdhundo, inclusive of the Services, with
                these terms integrally forming part of a cohesive agreement.
              </li>
              <li>
                This document stands as an electronic record in accordance with
                the provisions of the Information Technology Act, 2000, and its
                related rules. Significantly, it obviates the necessity for
                physical or digital signatures. Its publication aligns
                meticulously with the SPI Rules and Intermediaries Guidelines.
              </li>
              <li>
                The terms 'Party' and 'Parties' assume reference to the User and
                Cashdhundo individually and collectively, as the context
                necessitates.
              </li>
              <li>
                The section headings within this Policy are strategically
                employed for the organization of provisions and bear no
                intrinsic legal or contractual weight. Both Parties mutually
                concede that these headings are bereft of interpretative
                influence upon the stipulations herein.
              </li>
              <li>
                Cashdhundo, by virtue of its exclusive prerogative, reserves the
                right to effect amendments or modifications to the Policy and
                Terms and Conditions sans prior consent or intimation to the
                User. The User explicitly agrees that such alterations or
                revisions instantaneously come into force. It becomes incumbent
                upon the User to routinely scrutinize and remain abreast of any
                developments in the Policy and Terms and Conditions. The
                sustained utilization of the Services signifies the User's
                unequivocal consent to any such amendments or modifications. The
                User, in adherence to the Policy and Terms and Conditions,
                retains the privilege to ingress, access, and employ the
                Services.
              </li>
            </ol>
          </div>
          <div className="condition_heading">
            <h2>4. COLLECTION AND MANAGEMENT OF PERSONAL DATA</h2>
            <h4>Privacy Manifesto:</h4>
            <p>
              In our relentless commitment to upholding the sanctity of privacy
              for all stakeholders, our suite of services is meticulously
              crafted to align with the jurisdictional statutes as delineated by
              the Government of India. Primarily, the efficacy of our services
              necessitates the identification of individuals. Upon engaging with
              our offerings, you may be prompted to furnish specific information
              voluntarily, thereby facilitating effective customization to your
              unique requisites. It is imperative to note that the collection of
              such data strictly adheres to prevailing legal frameworks. <br />
              In the realm of data acquisition, the spectrum is broad and
              nuanced. The information gathered encompasses, but is not
              restricted to, the following categories:
            </p>
            <ol>
              <li>Contact Data:</li>
              <p className="p-0">
                Embracing your digital persona, we may seek your email address
                and phone number, ensuring seamless communication channels.
              </p>
              <li>User Authentication:</li>
              <p className="p-0">
                UTo fortify security measures, usernames and passwords serve as
                sentinel gatekeepers.
              </p>
              <li>Demographic Data:</li>
              <p className="p-0">
                A deeper understanding of your identity is sought through
                details such as name, gender, age, date of birth, and pin code.
              </p>
              <li>Usage and Transaction Data:</li>
              <p className="p-0">
                In the landscape of your interactions with our services,
                insights into your usage patterns and transactional history are
                meticulously recorded.
              </p>
              <li>Browsing Information:</li>
              <p className="p-0">
                Embarking on the digital trail, we capture data pertaining to
                your interactions on and with Cashdhundo. This includes mobile
                device specifications, web browser intricacies, and discernible
                browsing patterns.
              </p>
              <li>Bank Account Information:</li>
              <p className="p-0">
                For seamless financial transactions, we may requisition details
                like bank account name, number, IFSC code, and associated
                particulars.
              </p>
              <li>Voluntarily Provided Information:</li>
              <p className="p-0">
                Beyond the structured data, a myriad of voluntarily provided
                information enriches our understanding. This encompasses
                insights shared through diverse channels—be it emails, calls, or
                letters. Such data may span work specifics, residential or
                occupational addresses, familial particulars, transaction
                minutiae on e-commerce platforms, transactional screenshots,
                order IDs, alternate contact information, and a panoply of
                details shared intermittently. This corpus of data is
                categorized as 'Personal Information' or 'Sensitive Personal
                Data Information' under the SPI Rules, and we handle it with the
                utmost diligence, ensuring strict compliance with pertinent
                privacy regulations.
              </p>
            </ol>
          </div>
          <div className="condition_heading">
            <h2>5. PRIVACY STATEMENTS</h2>
            <h4>A. User's Express Agreement and Acknowledgments:</h4>
            <ol>
              <li>Publicly Available Information:</li>
              <p className="p-0">
                Information that is publicly available or accessible under the
                Right to Information Act, 2005, or any other applicable law is
                expressly excluded from the definitions of 'Personal
                Information' or 'Sensitive Personal Data or Information' as
                outlined in this Policy.
              </p>
              <li>Automatic Tracking of User Information:</li>
              <p className="p-0">
                Cashdhundo may employ automated methods to track User
                information, leveraging IP addresses and user behavior on the
                Platform, subject to User consent. This data may be utilized for
                internal demographic research, interest analysis, and behavior
                patterns.
              </p>
              <li>Consent for Sharing Information:</li>
              <p className="p-0">
                Users explicitly grant consent for the correlation of their IP
                address with personally identifiable information, sharing of
                such information with third parties, and its disclosure for
                anonymized market research.
              </p>
              <li>Compilation of User Information:</li>
              <p className="p-0">
                Cashdhundo, with explicit User consent, may collect, compile,
                and securely store information pertaining to the User, including
                personal correspondence.
              </p>
              <li>Communication and Marketing:</li>
              <p className="p-0">
                Contact information may be leveraged to disseminate offers,
                promotions, and other communications, with Users retaining the
                option to unsubscribe from promotional communications at their
                discretion.
              </p>
              <li>Optional Surveys:</li>
              <p className="p-0">
                Users may be invited to participate in optional online surveys
                aimed at enhancing and tailoring services. The information
                gathered is utilized for the benefit of both new and existing
                Users.
              </p>
              <li>Record of Communications:</li>
              <p className="p-0">
                For administrative, customer support, research, and development
                purposes, Cashdhundo may maintain records of electronic
                communications and telephone calls.
              </p>
              <li>Reviews and Feedback:</li>
              <p className="p-0">
                Users may be solicited to furnish reviews for services availed
                on the Platform, with Cashdhundo authorized to publish such
                reviews for the benefit of the wider user community.
              </p>
              <li>User Responsibility for Information Accuracy:</li>
              <p className="p-0">
                Users bear the responsibility for the accuracy of information
                submitted to Cashdhundo, and corrections can be effectuated by
                contacting Cashdhundo via email.
              </p>
              <li>Withdrawal of Consent:</li>
              <p className="p-0">
                UUsers possess the right to withdraw consent at any juncture;
                however, such withdrawal is not retroactive. Users may contact
                Cashdhundo for account deletion or the cessation of information
                use.
              </p>
              <li>Opting Out of Communications:</li>
              <p className="p-0">
                Users can opt-out of non-essential communications by sending an
                email to
                <Link to="mailto:support@cashdhundo.com">
                  support@cashdhundo.com
                </Link>
              </p>
              <p className="p-0">
                <span className="text-danger">Note:</span>Withdrawal of consent
                or the closure of an account may impact service provision and
                existing relationships as stipulated in the Terms and
                Conditions.
              </p>
            </ol>
            <p>Cashback/Rewards Redemption:</p>
            <p>
              Users are eligible to redeem validated cashback/rewards upon
              reaching the minimum threshold in their Account. Closure of the
              Account with a validated balance below the minimum threshold will
              result in forfeiture. However, if the validated Cashback/Reward
              equals or exceeds the minimum threshold, users can request payment
              using specified Cashback/Reward Receipt Methods. The discretionary
              authority to forfeit any validated balance is retained by
              Cashdhundo, as further detailed in other sections of this
              Agreement.
            </p>
            <h4>B. OUR USE OF YOUR INFORMATION</h4>
            <br />
            <h4>Voluntary Nature of Information:</h4>
            <p>
              Every piece of information provided by a User to Cashdhundo,
              encompassing Personal Information or any Sensitive Personal Data
              or Information, is entirely voluntary. Subject to the User's
              explicit consent, such information may be shared with Third
              Parties.
            </p>
            <h4>Utilization of Information:</h4>
            <p>
              Cashdhundo may employ designated Personal Information or
              'Sensitive Personal Data or Information' as defined by the SPI
              Rules for various purposes. This includes, but is not limited to,
              providing services, soliciting product and service feedback,
              introducing new products or services, conducting marketing
              activities, analyzing software usage patterns, generating
              alerts/reminders/SMS for offers, and maintaining internal records.
            </p>
            <h4>Tracking Information:</h4>
            <p>
              For identification purposes, demographic data collection, and
              enhancement of product design and utility, Cashdhundo may employ
              tracking information such as IP addresses and/or Device IDs.
            </p>
            <h4>Transfer of Information in Mergers or Acquisitions:</h4>
            <p>
              In the event of an acquisition or merger, Cashdhundo will
              facilitate the transfer of disclosed information to the
              acquiring/merging entity. Users will be duly notified prior to
              such transfer.
            </p>
            <h4>Storage of Personal Information:</h4>
            <p>
              Cashdhundo securely collects and stores Personal Information
              and/or Sensitive Personal Information within a cloud-based
              platform, as provided by the User.
            </p>
            <h4>Data Disclosure to Third Parties:</h4>
            <p>
              User's Personal Information may be disclosed by Cashdhundo to
              third parties for purposes outlined in the Privacy Policy. This
              includes, but is not limited to, government
              institutions/authorities, protection of rights or property, fraud
              prevention, enforcement of Terms and Conditions, or as mandated by
              law.
            </p>
            <h4>Advertising and Cookies:</h4>
            <p>
              Third-party vendors, such as Google, Facebook, and others, may
              utilize cookies to deliver advertisements based on a user's
              previous visits to the website. Users have the option to opt out
              of interest-based advertising through Ads Settings.
            </p>
            <p>
              <span className="text-danger">Note:</span> While Cashdhundo
              engages third parties for advertising services, these entities are
              strictly prohibited from selling or sharing personally
              identifiable information collected during this process.
            </p>
            <h4>C. CONFIDENTIALITY AND SECURITY</h4>
            <br />
            <h4>a. Confidentiality of Your Information:</h4>
            <p>
              Your information is handled with the utmost confidentiality and
              will not be disclosed to any Third Parties unless specified
              conditions permit or as legally mandated by the appropriate
              authorities. Sharing may occur when necessary for the provision of
              Services through the Platform.
            </p>
            <h4>b. Storage of Personal Information:</h4>
            <p>
              Your Personal Information/Sensitive Personal Data is
              electronically maintained on our equipment and that of our
              employees, and may also be transcribed into physical form as
              deemed necessary.
            </p>
            <h4>c. Access to Your Personal Information:</h4>
            <p>
              User Information undergoes processing by authorized staff,
              marketing agencies, or agents on a need-to-know basis, contingent
              on the specific purposes for which the User Information was
              collected. Cashdhundo may retain and provide records to relevant
              stakeholders.
            </p>
            <h4>d. Security Practices:</h4>
            <p>
              We view data as a valuable asset and employ diverse security
              techniques to shield it from unauthorized access. While adhering
              to industry standards, any data loss or theft resulting from
              unauthorized access to the User’s electronic devices is not within
              our liability.
            </p>
            <h4>e. Security Measures Expected from You:</h4>
            <p>
              You actively contribute to the safeguarding of Your User
              Information. Select a secure password, maintain its
              confidentiality, and log out on shared/public computers. Make use
              of privacy settings and controls provided on Our Platform.
            </p>
            <h4>f. Unauthorized Use of User’s Account:</h4>
            <p>
              We bear no responsibility for any unauthorized use of Your account
              and password. If unauthorized use is suspected, prompt
              notification to us at support@Cashdhundo.com is essential.{" "}
              <Link to="mailto:support@cashdhundo.com">
                support@cashdhundo.com
              </Link>{" "}
              .
            </p>
            <h4>g. Third-Party Confidentiality:</h4>
            <p>
              Cashdhundo is not accountable for the confidentiality, security,
              or distribution of your Personal Information by third parties
              beyond the scope of Our Agreement. Additionally, we disclaim
              responsibility for security breaches or events/actions beyond Our
              reasonable control.
            </p>
            <h4>D. RETENTION OF PERSONAL DATA</h4>
            <p>
              In accordance with applicable legal frameworks, we shall
              judiciously employ and preserve User Information for a duration
              commensurate with the imperative to fulfill the specific purposes
              for which it was originally collected, as expounded in Section 4
              above. Additionally, this retention period may extend to the
              fulfillment of pertinent legal obligations that necessitate the
              continued preservation of said information.
            </p>
            <br />
            <h4>E. EXERCISING YOUR PRIVACY RIGHTS</h4>
            <h4>a. Comprehensive Access to Personal Data:</h4>
            <p>
              Empowering Your Control - Avail Yourself of Unrestricted Access,
              Thorough Review, and Secure Retrieval of Your Information
              Preserved in Our Custody. Seek Clarification Regarding the Origin
              of Your Personal/Sensitive Information.
            </p>
            <h4>
              b. Expansive Additional Rights (e.g., modification, deletion of
              Personal Data):
            </h4>
            <p>
              Navigate Your Privacy - In compliance with applicable laws, you
              have the prerogative to:
            </p>
            <ol>
              <li>
                Request the deletion, portability, correction, or revision of
                your User Information.
              </li>
              <li>
                Exercise control over the utilization and disclosure of your
                Personal Data.
              </li>
              <li>Revoke consent for any of Our data processing activities.</li>
            </ol>
            <p>
              <span className="text-danger">Note: </span> : In the discharge of
              business, legal, or contractual obligations, it may be necessary
              for Us to retain certain aspects of Your User Information even
              subsequent to deletion requests. Your Privacy, Governed by Your
              Terms.
            </p>
            <br />
            <br />
            <h4>F. PROTECTION OF CHILDREN'S AND MINORS' PRIVACY</h4>
            <p>
              We strongly advise parents and guardians to actively supervise the
              online engagements of their minor children. Employing parental
              control tools provided by online services and software
              manufacturers can significantly contribute to establishing a
              digital environment that is conducive to the well-being of
              children. These tools are specifically designed to thwart minors
              from divulging personal information, such as their name and
              address, online without explicit parental consent. Although the
              Services are not expressly designed for use by minors, we are
              committed to safeguarding the privacy of every individual who may
              inadvertently access the internet or our mobile application to
              avail themselves of our services.
            </p>
            <h4>G. ACKNOWLEDGMENT AND ACCEPTANCE OF PRIVACY POLICY</h4>
            <p>
              Recognizing the significance of this Privacy Policy as an
              inseparable component of the Terms and Conditions governing the
              Website and its associated Services, you affirm your unequivocal
              agreement as a User of the Platform and Services, thereby
              indicating your consent to abide by the provisions outlined
              herein. Your interaction with the Website, utilization of the App,
              and engagement with the Services are contingent upon your
              adherence to both this Privacy Policy and the stipulations set
              forth in the Terms and Conditions.
            </p>
            <h4>H. Platform COOKIES AND THEIR MANAGEMENT ON OUR PLATFORM</h4>
            <p>
              {" "}
              <span>a. Introduction</span><br />
              When engaging with our Services on the Platform, a persistent
              cookie is deployed onto your computer. These sophisticated cookies
              play a pivotal role in facilitating the tracking of your
              transactions with our affiliated retailers. In reciprocity for
              this data exchange, we extend Cashbacks or Rewards to enhance your
              overall experience. It is imperative to note that the absence of
              these persistent cookies on your device will preclude you from
              accruing Cashbacks or Rewards through our Platform during your
              online shopping endeavors.<br />
              <span>b. Cookie Management:</span><br />
              The discretion to accept or reject cookies lies within your
              control, achievable by adjusting the settings embedded in your
              browser. However, it is crucial to recognize that opting to
              disable cookies might curtail your access to the full spectrum of
              interactive features available on our Platform.
              <span>c. Important Considerations:</span><br />
              It is paramount to underscore that if you choose to disable
              specific cookies within your browser responsible for monitoring
              your transactions on our Platform, the consequence is the
              forfeiture of the ability to earn Cashbacks or Rewards when making
              purchases through our website.<br />
              <span>d. Guidelines for Cookie Management:</span><br />
              The nuanced management of cookies extends to various avenues. For
              users employing different computers across diverse locations, it
              is imperative to meticulously configure each browser in alignment
              with individual cookie preferences.<br />
              <span>e. Practical Steps for Deleting Cookies:</span><br />
              Effortlessly deleting cookies that have been embedded in your
              browser's cookie folder can be accomplished through a few
              straightforward steps. For instance, if you are utilizing
              Microsoft Windows Explorer, follow these instructions:
              <ul>
                <li>Open 'Windows Explorer.'</li>
                <li>Click the 'Search' button on the toolbar.</li>
                <li>
                  Enter "cookie" into the search box for 'Folders and Files.'
                </li>
                <li>Choose 'My Computer' in the 'Look In' box.</li>
                <li>Click 'Search Now.'</li>
                <li>Double-click on the identified folders.</li>
                <li>'Select' any cookie file.</li>
                <li>Press the 'Delete' button on your keyboard.</li>
              </ul>
              For users employing alternative browsers, kindly refer to the
              "Help" function within your specific browser for comprehensive
              guidance on locating your cookie folder and effecting adept
              management of cookies.
            </p>

            <h4>I. MODIFICATIONS TO PRIVACY POLICY</h4>
            <p>
              Cashdhundo retains the prerogative to revise this Privacy Policy
              at its discretion, with or without prior notification. In
              instances of substantial alterations either in the handling of
              User Information by Cashdhundo or in the Privacy Policy itself,
              Cashdhundo will employ one of two methods: either conspicuously
              presenting a notice on the Website or dispatching an email to
              Users, as delineated above. This procedural approach affords Users
              the opportunity to scrutinize the amended terms before electing to
              persist in utilizing the Services.
              <br />
              Cashdhundo retains the prerogative to revise this Privacy Policy
              at its discretion, with or without prior notification. In
              instances of substantial alterations either in the handling of
              User Information by Cashdhundo or in the Privacy Policy itself,
              Cashdhundo will employ one of two methods: either conspicuously
              presenting a notice on the Website or dispatching an email to
              Users, as delineated above. This procedural approach affords Users
              the opportunity to scrutinize the amended terms before electing to
              persist in utilizing the Services. <br />
              Through the act of utilizing the Services subsequent to receiving
              notice of changes or following the publication of such changes on
              the Platform, Users inherently extend their consent to abide by
              the updated terms. This implies an acknowledgment and acceptance
              of the revised Privacy Policy by Users as an integral aspect of
              their continued engagement with Cashdhundo's Services.
            </p>
            <h4>J. ADDRESS PRIVACY QUESTIONS</h4>
            <p>
              For any inquiries pertaining to this Privacy Policy or the
              methodologies employed by Cashdhundo in the acquisition,
              utilization, and divulgence of information, we invite you to
              communicate with the appointed Data Protection Officer at
              Cashdhundo. Our commitment extends to undertaking reasonable
              endeavors to expeditiously attend to any requests, queries, or
              apprehensions you may harbor in connection with the utilization of
              your Personal Information. Should you encounter grievances linked
              to the handling of your personal information, we encourage you to
              convey such concerns to the Data
            </p>
            <p>Protection Officer:</p>
            <p>
              Name: cashdhundo <br />
              Email:{" "}
              <Link to="mailto:support@cashdhundo.com">
                support@cashdhundo.com
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivicyPolicy;
