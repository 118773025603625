import React, { useEffect } from "react";
import { FiArrowUpRight, FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import ClientFilter from "./ClientFilter";
import CashbackFilter from "./CashbackFilter";
import Skeleton from "react-skeleton-loader";

import {
  useGetAllStoreDataQuery,
  useGetByIdStoreQuery,
  useGetSearchBaseStoreDataQuery,
} from "../../App/Slice/storeSlice";
import { Helmet } from "react-helmet";

function Campaign_Store() {
  const params = useParams();
  const key_id = params.id;
  const key =
    params.id !== "All" && params.id !== "coupons" && params.id !== "deals"
      ? "All"
      : key_id;
  const client_key = params.client;
  const cli = client_key?.split("-")?.join(" ");

  const { data: clientData } = useGetByIdStoreQuery(key_id);
  const clientByData = clientData?.client;
  const query = clientByData?.name;
  // const querySee = key === "All" ? key_id : key === "deals" ? key_id : key === "coupons" ? key_id : null;
  // console.log("query --> ", key);

  const { data: allData } = useGetSearchBaseStoreDataQuery(query);
  const querySearch = allData?.searchByclient?.filter((ele) =>
    ele?.status?.includes("success")
  );

  const { data: queryDeals, isLoading } = useGetAllStoreDataQuery(query);
  const query_data = queryDeals?.searchByclient;
  const queryDealData = query_data?.deals?.filter((ele) =>
    ele.convert_deal_Product?.includes("deal")
  );

  const queryDeal = queryDealData?.filter((ele) =>
    ele.status?.includes("success")
  );

  const queryCoupon = query_data?.coupon?.filter((ele) =>
    ele.status?.includes("success")
  );

  useEffect(() => {
    // console.log("search query");
  }, [key_id, query]);

  return (
    <>
      <Helmet>
        <title>{clientByData?.name}</title>
        <meta name="description" content={clientByData?.description} />
        <meta name="keywords" content={clientByData?.meta_keyword} />
      </Helmet>

      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="/campaign-store">Store</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to={`/campaign-store/${cli}/All`}>{cli}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container p-0 main-store mt-4 mb-4">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12">
            <CashbackFilter
              clientByData={clientByData}
              queryDeal={queryDeal}
              queryCoupon={queryCoupon}
              querySearch={querySearch}
              loading={isLoading}
            />
            <ClientFilter key_id={key_id} loading={isLoading} />
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <div className="grid-filter-section d-flex justify-content-between mb-4">
              <div className="grid-section">
                <h6>{key === null ? " " : cli}</h6>
              </div>
            </div>
            {isLoading ? (
              <>
                <div className="inner-deal_coupon">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-4">
                        <Link to="#">
                          <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                            <span className="coupons-offers sklton_bg_light">
                              <Skeleton color="#d6e7ff" />
                            </span>
                            <div className="deals-image sklton_deals_image">
                              <Skeleton color="#d6e7ff" />
                              <span>
                                <Skeleton color="#d6e7ff" />
                              </span>
                            </div>
                            <div className="deals-content coupons-content d-flex flex-column">
                              <Skeleton color="#d6e7ff" width="80%" />
                              <Skeleton color="#d6e7ff" width="60%" />
                              <Skeleton color="#d6e7ff" />
                            </div>
                            <div className="coupon_input_section deals-content sklton_deals_content">
                              <Skeleton color="#d6e7ff" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-deal_coupon">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-4">
                        <Link to="#">
                          <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                            <span className="coupons-offers sklton_bg_light">
                              <Skeleton color="#d6e7ff" />
                            </span>
                            <div className="deals-image sklton_deals_image">
                              <Skeleton color="#d6e7ff" />
                              <span>
                                <Skeleton color="#d6e7ff" />
                              </span>
                            </div>
                            <div className="deals-content coupons-content d-flex flex-column">
                              <Skeleton color="#d6e7ff" width="80%" />
                              <Skeleton color="#d6e7ff" width="60%" />
                              <Skeleton color="#d6e7ff" />
                            </div>
                            <div className="coupon_input_section deals-content sklton_deals_content">
                              <Skeleton color="#d6e7ff" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="inner-deal_coupon">
                <div className="container-fluid p-0">
                  {key === "deals" ? (
                    <div className="row">
                      {queryDeal?.length === 0 ? (
                        <p>Empty Result!</p>
                      ) : (
                        <>
                          {queryDeal?.map((val, ind) => {
                            const { _id, client, title, category, feature } =
                              val;
                            const name = title?.split(" ")?.join("-");
                            const client_name = client?.name
                              ?.split(" ")
                              ?.join("-");
                            return (
                              <div
                                key={ind}
                                className="col-lg-12 col-md-12 col-12 mb-4"
                              >
                                <Link
                                  to={`/store/${client_name}/${name}/${_id}`}
                                >
                                  <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                    <span className="coupons-offers">
                                      {category?.name}
                                    </span>
                                    <div className="deals-image">
                                      <img
                                        src={client?.logo}
                                        loading="lazy"
                                        alt={title}
                                      />
                                      <span>
                                        <h5>Trending</h5>
                                      </span>
                                    </div>
                                    <div className="deals-content coupons-content">
                                      <h5>
                                        {client?.name} <span>DEAL</span>
                                      </h5>
                                      <h2>
                                        {client?.client_offer} Cashback by
                                        Cashdhundo
                                      </h2>
                                      <h1>{title}</h1>
                                      {feature?.length === 0 ? null : (
                                        <ul className="feature_list">
                                          {feature
                                            ?.slice(0, 1)
                                            ?.map((val, ind) => {
                                              return <li key={ind}>{val}</li>;
                                            })}
                                        </ul>
                                      )}
                                    </div>
                                    <div className="coupon_input_section deals-content">
                                      <Link
                                        to={`/store/${client?.name}/${name}/${_id}`}
                                        type="button"
                                        className="coupon_link_btn text-right p-2"
                                      >
                                        <span>ACTIVATE DEAL</span> ACTIVATE
                                      </Link>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  ) : key === "coupons" ? (
                    <div className="row">
                      {queryCoupon?.length === 0 ? (
                        <p>Empty Result!</p>
                      ) : (
                        <>
                          {queryCoupon?.map((val, ind) => {
                            const {
                              _id,
                              client,
                              category,
                              coupon_offer,
                              title,
                              description,
                              offer,
                            } = val;
                            const name = title?.split(" ")?.join("-");
                            return (
                              <div
                                key={ind}
                                className="col-lg-12 col-md-12 col-12 mb-4"
                              >
                                <Link
                                  to={`/coupon/${client?.name}/${name}/${_id}`}
                                >
                                  <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                    <span className="coupons-offers">
                                      {category?.name}
                                    </span>
                                    {/* <span className="coupons-feature">
                                      <Link to="">View Feature</Link>
                                      <Link to="">How it work?</Link>
                                    </span> */}
                                    <div className="deals-image coupon-img">
                                      <h1>{coupon_offer}</h1>
                                      <span>
                                        <img
                                          src={client?.logo}
                                          loading="lazy"
                                          alt={title}
                                        />
                                      </span>
                                    </div>
                                    <div className="deals-content coupons-content">
                                      <h1>{title}</h1>
                                      <h2 className="text-danger">{offer}</h2>
                                      <p>{description?.slice(0, 116)}</p>
                                      <div className="coupons-btn mt-2">
                                        <Link
                                          to={`/coupon/${client?.name}/${name}/${_id}`}
                                          type="button"
                                          className="btn"
                                        >
                                          View Detail
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="coupon_input_section deals-content">
                                      <Link
                                        to={`/coupon/${client?.name}/${name}/${_id}`}
                                        type="button"
                                        className="coupon_link_btn text-right p-2"
                                      >
                                        <span>SHOW COUPON CODE</span> ACTIVATE
                                      </Link>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  ) : key === "All" ? (
                    <div className="row">
                      {querySearch?.reverse()?.map((val, ind) => {
                        const {
                          _id,
                          client,
                          title,
                          mrp_rate,
                          real_rate,
                          image,
                          coupon_input,
                          category,
                          coupon_offer,
                          feature,
                          convert_deal_Product,
                        } = val;
                        const name = title?.split(" ")?.join("-");
                        const client_name = client?.name?.split(" ")?.join("-");
                        const linker = coupon_input
                          ? `/coupon/${client_name}/${name}/${_id}`
                          : `/store/${client_name}/${name}/${_id}`;
                        if (convert_deal_Product === "product") {
                          return;
                        }
                        return (
                          <div
                            key={ind}
                            className="col-lg-12 col-md-12 col-12 mb-4"
                          >
                            <Link to={linker}>
                              <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                <span className="coupons-offers">
                                  {category?.name}
                                </span>
                                {/* <span className="coupons-feature">
                                {coupon_input ? (
                                  <>
                                    <Link to="">View Feature</Link>
                                    <Link to="">How it work?</Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="">View Feature</Link>
                                    <Link to="">How it work?</Link>
                                  </>
                                )}
                              </span> */}
                                <div className="deals-image coupon-img">
                                  {coupon_input ? (
                                    <>
                                      <h1>{coupon_offer}</h1>
                                      <span>
                                        <img
                                          src={client?.logo}
                                          loading="lazy"
                                          alt={title}
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {convert_deal_Product === "deal" ? (
                                        <>
                                          <img
                                            src={client?.logo}
                                            loading="lazy"
                                            alt={title}
                                          />
                                          <span>
                                            <h5>Trending</h5>
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={image}
                                            loading="lazy"
                                            alt={title}
                                          />
                                          <span>
                                            <img
                                              src={client?.logo}
                                              loading="lazy"
                                              alt={title}
                                            />
                                          </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="deals-content coupons-content">
                                  {convert_deal_Product === "deal" ? (
                                    <>
                                      <h5>
                                        {client?.name} <span>DEAL</span>
                                      </h5>
                                      <h2>
                                        {client?.client_offer} Cashback by
                                        Cashdhundo
                                      </h2>
                                    </>
                                  ) : null}
                                  <h1>{title}</h1>
                                  {convert_deal_Product === "deal" ? (
                                    <>
                                      {feature?.length === 0 ? null : (
                                        <ul className="feature_list">
                                          {feature
                                            ?.slice(0, 1)
                                            ?.map((val, ind) => {
                                              return <li key={ind}>{val}</li>;
                                            })}
                                        </ul>
                                      )}
                                    </>
                                  ) : null}
                                  {coupon_input ||
                                  convert_deal_Product === "product" ? (
                                    <div className="coupons-btn mt-2">
                                      <Link
                                        to={`/coupon/${client?.name}/${name}/${_id}`}
                                        type="button"
                                        className="btn"
                                      >
                                        View Detail
                                      </Link>
                                    </div>
                                  ) : (
                                    <span className="d-flex align-items-center justify-content-start">
                                      {convert_deal_Product ===
                                      "deal" ? null : (
                                        <>
                                          <h5 className="text-danger">
                                            <s>MRP ₹{mrp_rate}</s>
                                          </h5>{" "}
                                          <h5>Price ₹{real_rate}</h5>
                                        </>
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div className="coupon_input_section deals-content">
                                  {coupon_input ? (
                                    <Link
                                      to={`/coupon/${client?.name}/${name}/${_id}`}
                                      type="button"
                                      className="coupon_link_btn text-right p-2"
                                    >
                                      <span>SHOW COUPON CODE</span> ACTIVATE
                                    </Link>
                                  ) : (
                                    <Link
                                      to={`/store/${client?.name}/${name}/${_id}`}
                                      type="button"
                                      className="coupon_link_btn text-right p-2"
                                    >
                                      <span>ACTIVATE DEAL</span> ACTIVATE
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Campaign_Store;
