import React, { useEffect, useState } from "react";
import { FiPackage } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  useGetByIdStoreQuery,
  useGetStoreQuery,
} from "../../App/Slice/storeSlice";
import Skeleton from "react-skeleton-loader";

function ClientFilter({ key_id, client_ids, loading }) {
  const ids = key_id ? key_id : client_ids;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const Auth_User = JSON.parse(localStorage.getItem("user_data"));

  const { data } = useGetStoreQuery();
  const store = data?.client;

  const { data: getById } = useGetByIdStoreQuery(ids);
  const getStore = getById?.client;
  const { name, logo, client_offer, calc } = getStore || {};
  const calcX = calc?.[0];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!Auth_User) {
      setTimeout(() => {
        openModal();
      }, 3000);
    }
    return;
  }, [Auth_User]);

  return (
    <>
      <div className="category-sides filter_category_design mb-4 sticky-top">
        <h3>Brands</h3>
        <ul>
          {store?.map((val, ind) => {
            const cli = val.name?.split(" ")?.join("-");
            return (
              <li key={ind}>
                <span>
                  <FiPackage />
                </span>
                <Link to={`/campaign-store/${cli}/${val._id}`}>{val.name}</Link>
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className={`modal fade alertNotification ${isModalOpen ? "show" : ""}`}
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title mx-auto" id="exampleModalToggleLabel">
                {loading ? (
                  <Skeleton color="#d6e7ff" />
                ) : (
                  <img src={logo} loading="lazy" alt={name} />
                )}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body bg-white">
              <div className="content_nofitification">
                {calcX === null || calcX === "Paused" ? (
                  <h5 className="text-danger">No Cashback Available</h5>
                ) : (
                  <h5 className="text-danger">
                    {client_offer} Cashback
                  </h5>
                )}
                <span>
                  <Link to="" onClick={closeModal}>
                    SKIP & LOSE CASHBACK
                  </Link>
                  <Link
                    to=""
                    data-bs-target="#exampleModalToggle"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    onClick={closeModal}
                  >
                    SIGN IN & GET CASHBACK
                  </Link>
                </span>
                <div className="input-content pop_up_lander mt-3">
                  <h5>
                    Don't have an account?{" "}
                    <Link
                      to=""
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={closeModal}
                    >
                      Create an account
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientFilter;
