import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination, Autoplay} from "swiper";
import Heading from "../../Components/Heading";

function Testimonials() {
    const filterbanner = [
        {
            id: 1,
            name: "Sara, Fashionista on a Budget",
            description:
                "I bought a budget-friendly phone, Realme C11 on Cashdhundo with a whopping 15% cashback! It was a no-brainer. The phone is super smooth, the camera takes stunning pictures, and the battery lasts for ages. ",
        },
        {
            id: 2,
            name: "	Rohan V. from Bangalore",
            description:
                "The Dell 14 caught my eye on Cashdhundo with a tempting 10% cashback offer. It's been a game-changer! The display is gorgeous, the processor is lightning-fast, and the cashback I earned helped me invest in some new design software. Win-win!",
        },
        {
            id: 3,
            name: "Priya K. from Delhi",
            description:
                "I'm a total techie, and the Alexa Echo Dot was calling my name! Thanks to Cashdhundo's 8% cashback deal, I couldn't resist. ",
        },
        {
            id: 4,
            name: "Amit T. from Kolkata ",
            description:
                "I found the Samsung washing machine on Cashdhundo with a cool 7% cashback offer! This machine is a lifesaver – it washes clothes sparkling clean in minutes, and the cashback I got feels like a reward for getting my laundry done so efficiently. ",
        },
        {
            id: 5,
            name: "	Disha M. from Chennai",
            description:
                "The Vega hair dryer on Cashdhundo caught my eye with its 5% cashback deal. It's lightweight, powerful, and leaves my hair smooth and frizz-free",
        },
        {
            id: 6,
            name: "	Aditya K., Pune",
            description:
                "Furnishing my apartment and whipping up gourmet meals are both passions, but expensive ones. CASHDHUNDO changed the game! I snag deals on furniture, appliances, and groceries, and the cashback fuels my culinary experiments. Now, my home is both stylish and delicious",
        },
        {
            id: 7,
            name: "	Meera J., Jaipur ",
            description:
                "My old earphones gave up the ghost, so I grabbed the Boult Audio ProBass Curve on Cashdhundo. They're definitely budget-friendly, and the cashback sweetened the deal. Sound quality is decent, they fit snugly, and the bass is punchy. ",
        },
        {
            id: 8,
            name: "Rohan S., Lucknow",
            description:
                "Cashdhundo helped me get Campus Dragon running shoes at a very good discount! They're lightweight and breathable, perfect for both treadmill and weight training. The grip is fantastic, and the price with cashback was unbeatable",
        },
        {
            id: 9,
            name: "Anjali M., Ahmedabad",
            description:
                "I love Biba, but they can be pricey. So, when I saw a beautiful kurta set on Cashdhundo with a hefty cashback offer, I jumped at it! The fabric is soft and flowy, the embroidery is stunning, and the cut is flattering",
        },
        {
            id: 10,
            name: "•	Priya T., Kolkata ",
            description:
                "Cashdhundo led me to the Shyaway sports bra. The material is breathable, and the adjustable straps are a godsend. The price was a bit on the higher side, but the cashback definitely helped. Overall, a great buy for active women",
        }
    ];

    return (
        <>
            <div className="container header-section pt-3 mb-5">
                <Heading heading="Testimonials"/>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={15}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        140: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        570: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                    }}
                    modules={[Pagination, Autoplay]}
                    className="row mySwiper p-0 m-0"
                >
                    <>
                        {filterbanner?.map((val, ind) => {
                            return (
                                <SwiperSlide
                                    key={ind}
                                    className="col-lg-12 col-md-12 col-12 border_radius_center"
                                >
                                    <div className="testimonial_cards">
                                        <div
                                            className="testimonial_image d-flex justify-content-start align-items-center">
                                            <img
                                                src="https://api.cashdhundo.com/public/uploads/1721373654481oneTokaFour.png"
                                                alt="testimonial-profile"
                                            />
                                            <span>{val?.name}</span>
                                        </div>
                                        <p>{val?.description}</p>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </>
                </Swiper>
            </div>
        </>
    );
}

export default Testimonials;
