import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const category = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getCategory: builder.query({
      query: () => ({
        url: "/category",
        method: "GET",
      }),
    }),

    getSubCategory: builder.query({
      query: () => ({
        url: "/sub-category",
        method: "GET",
      }),
    }),

    getByIdSubCategory: builder.query({
      query: (ele) => {
        return{
          url: `/category/search/${ele}`,
          method: "GET",
        }
      },
    }),


  }),
});

export const { useGetCategoryQuery, useGetSubCategoryQuery, useGetByIdSubCategoryQuery } = category;
