import React from "react";

function ReferEarn() {
  return (
    <>
      <h3 className="faq_heading">Maximize Your Gains: Refer, Earn, Repeat!</h3>
      <div className="accordion-faq">
        <div className="accordion accordion-flush" id="accordionFlushTraking">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOneTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOneTraking"
                aria-expanded="false"
                aria-controls="flush-collapseOneTraking"
              >
                How can I recommend or invite my friends?
              </button>
            </h2>
            <div
              id="flush-collapseOneTraking"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOneTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                <ol>
                  <li>Navigate to 'My Account' or 'Profile.'</li>
                  <li>Access the 'Refer and Earn' section.</li>
                  <li>Copy your unique referral link.</li>
                  <li>
                    Share this referral link with your friends or family. For
                    each purchase made by your referral, you will receive 10% of
                    their Cashback amount as Referral Earnings. These earnings
                    will initially track as 'Pending Cashback' and will be
                    confirmed once your referral's Cashback is confirmed.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwoTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwoTraking"
                aria-expanded="false"
                aria-controls="flush-collapseTwoTraking"
              >
                What is the amount I will receive for referring a friend?
              </button>
            </h2>
            <div
              id="flush-collapseTwoTraking"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwoTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                Each time your referral makes a purchase, you will receive 10%
                of their Cashback/Rewards amount as Referral Earnings. These
                earnings will be recorded as 'Pending Cashback' and will be
                confirmed as soon as your referral's Cashback is confirmed.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferEarn;
