import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetCashbackHistoryQuery } from "../../App/Slice/cashbackHistorySlice";
import Loading from "../../Secure/Loading";

function Cashback() {
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data;

  const { data: History, isLoading } = useGetCashbackHistoryQuery();
  const cashbackhistory = History?.cashback_history;
  // console.log("cashback history :", cashbackhistory);

  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name?.username}</Link>
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Cashback</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9">
            <div className="inner-profile ">
              <span className=" mb-4 d-flex align-items-center justify-content-between">
                <h5>Cashback Offers</h5>
              </span>
              <div className="inner-profile ">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Transation Date</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Store</th>
                      <th scope="col">Purchase Amount</th>
                      <th scope="col">CD Cashback</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {!cashbackhistory ? (
                        <span className="not-found-error">No Result Found</span>
                      ) : (
                        <tbody>
                          {cashbackhistory?.map((val, ind) => {
                            const {
                              cashback,
                              client,
                              sale_amount,
                              transaction_id,
                              createdAt,
                              status,
                            } = val;
                            return (
                              <tr key={ind}>
                                <td>{createdAt?.slice(0, 10)}</td>
                                <td>{transaction_id}</td>
                                <td>{client}</td>
                                <td>₹ {sale_amount}</td>
                                <td>₹ {cashback}</td>
                                <td>
                                  {status === "pending" ? (
                                    <span className="order-pending">
                                      {status}
                                    </span>
                                  ) : status === "approve" ? (
                                    <span className="order-success">
                                      {status}
                                    </span>
                                  ) : status === "rejected" ? (
                                    <span className="order-cancel">
                                      {status}
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cashback;
