import React from "react";
import { Link } from "react-router-dom";
import { useGetBlogQuery } from "../../App/Slice/blogSlice";

function Popular() {
  const { data } = useGetBlogQuery();
  const blog = data?.blog;

  const popular = blog?.filter((ele) => ele.status.includes("success"));
  const popularData = popular?.filter((ele) => ele.trending.includes("yes"));


  return (
    <>
      <div className="col-lg-12 col-md-12 col-12">
        <div className="category-filter">
          <h6 className="mb-3">Popular Coupons & Deals</h6>
          <div className="popular-post-blog">
            <ul className="ul-list">
              {popularData?.map((val, ind) => {
                const titles = val?.title?.split(" ")?.join("-");
                return (
                  <li key={ind}>
                    <Link
                      to={`/blog/${titles}/${val?._id}`}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <img
                        src={val?.image}
                        loading="lazy" alt={val?.title}
                        className="rounded"
                      />
                      <div className="blog-content-text">
                        <h4>{val?.short_desc?.slice(0,60)}...</h4>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Popular;
