import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center row mx-auto">
          <div className="col-lg-12 col-md-12 col-12 mt-5">
            <p className="fs-3">
              <span className="text-danger">Opps!</span> Page not found.
            </p>
            <p className="lead">The page you’re looking for doesn’t exist.</p>
            <div className="pageNotFound">
              <Link to="/" type="button" className="btn">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
