import React, { useState } from "react";
import { FiChevronRight, FiMenu, FiUser } from "react-icons/fi";
import TopNav from "./TopNav";
import { Link } from "react-router-dom";
import Register from "../Profile/Register";
import Login from "../Profile/Login";
import SearchBar from "./SearchBar";
import { useNormalUserVerifyQuery } from "../App/Slice/userSlice";
import { FaTelegram } from "react-icons/fa6";
import { TbCategory2 } from "react-icons/tb";
import { IoIosCloseCircle } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import Category from "./Category";
import {
  useGetCategoryQuery,
  useGetSubCategoryQuery,
} from "../App/Slice/categorySlice";
import { useGetStoreQuery } from "../App/Slice/storeSlice";

function Navbar() {
  const [hide, setHide] = useState(false);
  const { data } = useNormalUserVerifyQuery();

  const user = JSON.parse(localStorage.getItem("user_data"));

  const LogoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const usern = data?.user_data;
  const username = usern?.username;

  // const profileLink = [
  //   {
  //     id: 1,
  //     name: "Setting",
  //     link: "/",
  //   },
  // ];

  const [touchData, setTouchData] = useState();
  const [subTouchMouse, setSubTouchMouse] = useState();

  const { data: categoryData, isLoading } = useGetCategoryQuery();
  const category = categoryData?.category;
  const loading = isLoading;

  const cateFilter = category?.filter((ele) =>
    ele?.status?.includes("success")
  );

  const { data: subCategory } = useGetSubCategoryQuery();
  const subCate = subCategory?.sub_category;

  const cateSubFilter = subCate?.filter((ele) =>
    ele?.status?.includes("success")
  );

  const { data: clientData } = useGetStoreQuery();
  const store = clientData?.client;

  const clientFilter = store?.filter((ele) => ele?.status?.includes("success"));

  const categoryFilter = cateSubFilter?.filter((ele) => {
    return ele?.category?.name === touchData;
  });

  const storeFilter = clientFilter?.filter((ele) => {
    return ele?.search_keyword?.some((keyword) => keyword === subTouchMouse);
  });

  const hiddenCategoryHandler = () => {
    setHide(false);
  };

  const showCategoryHandler = () => {
    setHide(true);
  };

  return (
    <>
      <TopNav />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src="https://api.cashdhundo.com/public/uploads/1721372863076Black and White Monogram Business Logo.png"
              alt="cashdhundo"
            />
          </Link>

          <div
            className="collapse navbar-collapse dasktop-css-nav"
            id="navbarSupportedContent"
          >
            <SearchBar />
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/campaign-store">
                  Store
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/coupons/category/All">
                  Coupons
                </Link>
              </li> */}
              <li className="nav-item mx-2">
                <Link
                  className="btn btn-primary"
                  to="https://t.me/+NRWqJsIxbOZhZGU9"
                  target="_blank"
                >
                  <FaTelegram /> Join
                </Link>
              </li>
              {user ? (
                <div className="btn-group profile-item">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <FiUser />
                    </span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <h5>👋 Hey, {username}</h5>
                    <li className="nav-item">
                      <Link
                        className="nav-link d-flex align-items-center"
                        to="/user/profile"
                      >
                        <span>
                          <FiChevronRight />
                        </span>
                        Profile
                      </Link>
                    </li>
                    <li className="nav-item"></li>
                    <li>
                      <Link
                        className="nav-link logout_btn mt-2 "
                        type="button"
                        to=""
                        onClick={LogoutHandler}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <li className="nav-item mx-2">
                  <a
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                    role="button"
                  >
                    SignIn
                  </a>
                </li>
              )}
              <Category />
            </ul>
          </div>

          <div className="moble-css-nav">
            <div className="d-flex align-items-center">
              <li className="nav-item">
                <Link className="nav-link" to="/campaign-store">
                  Store
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/coupons/category/All">
                  Coupons
                </Link>
              </li> */}
              <li className="nav-item mx-2">
                <Link
                  className="btn btn-primary"
                  to="https://t.me/+NRWqJsIxbOZhZGU9"
                  target="_blank"
                >
                  <FaTelegram /> Join
                </Link>
              </li>
              {user ? (
                <div className="btn-group profile-item">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <FiUser />
                    </span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <h5>👋 Hey, {username}</h5>
                    <li className="nav-item">
                      <Link
                        className="nav-link d-flex align-items-center"
                        to="/user/profile"
                      >
                        <span>
                          <FiChevronRight />
                        </span>
                        Profile
                      </Link>
                    </li>
                    {/* {profileLink?.map((val, ind) => {
                      return (
                        <li key={ind} className="nav-item">
                          <Link
                            className="nav-link d-flex align-items-center"
                            to={val.link}
                          >
                            <span>
                              <FiChevronRight />
                            </span>
                            {val.name}
                          </Link>
                        </li>
                      );
                    })} */}
                    <li className="nav-item"></li>
                    <li>
                      <Link
                        className="nav-link logout_btn mt-2 "
                        type="button"
                        to=""
                        onClick={LogoutHandler}
                      >
                        Logout
                      </Link>
                    </li>

                  </ul>
                </div>
              ) : (
                <li className="nav-item mx-2">
                  <a
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                    role="button"
                  >
                    SignIn
                  </a>
                </li>
              )}
              <Category />
            </div>
          </div>
          <div className="d-block d-lg-none moblie-sarch-bar pb-2">
            <SearchBar />
          </div>
        </div>
      </nav>

      <Login />
      <Register />
    </>
  );
}

export default Navbar;
