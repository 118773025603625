import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const blog = createApi({
  reducerPath: "blog",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getBlog: builder.query({
      query: () => ({
        url: "/blog",
        method: "GET",
      }),
    }),

    getByIdBlog: builder.query({
      query: (id) => {
        return {
          url: `/blog/${id}`,
          method: "GET",
        };
      },
    }),

    getFiltersBlog: builder.query({
      query: (category) => {
        return {
          url: `/blog/category/filter/${category}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetBlogQuery, useGetByIdBlogQuery, useGetFiltersBlogQuery } =
  blog;
