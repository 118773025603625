import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useCreatePartnerMutation } from "../App/Slice/partnerSlice";

function BecomePartner() {
  const [mess, setMess] = useState(false);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [website, setWebsite] = useState();
  const [companyName, setCompanyName] = useState();
  const [legalName, setLegalName] = useState();
  const [address, setAddress] = useState();
  const [message, setMessage] = useState();

  const [createPartner, isLoading] = useCreatePartnerMutation();
  const loading = isLoading?.isLoading;

  const PartnerFormSubmitHandler = async (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      website: website,
      companyName: companyName,
      legalName: legalName,
      address: address,
      message: message,
    };
    if (
      !name ||
      (name === undefined && !email) ||
      (email === undefined && !website) ||
      (website === undefined && !companyName) ||
      (companyName === undefined && !address) ||
      address === undefined
    ) {
      alert("all fields are required!");
    } else {
      const response = await createPartner(data);
      const result = response?.data;
      if (result?.status === "failed") {
        const failed_mess = result?.message;
        return setMess(failed_mess);
      } else {
        const success_mess = result?.message;
        setMess(success_mess);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Join the CASHDHUNDO Shopping Symphony: Be a Partner in Savings Bliss!
        </title>
        <meta
          name="description"
          content="Unleash the power of CASHDHUNDO with your brand! Partner with us and reach millions of savvy shoppers hunting for exciting deals, juicy discounts, and cashback magic across every category. From fashionistas to foodies, tech geeks to travel bugs, CASHDHUNDO connects you with a vibrant community eager to explore your offerings. We'll amplify your brand with targeted promotions, boost your sales with irresistible cashback offers, and watch your business flourish alongside satisfied CASHDHUNDO users. Join our symphony of savings; let's rewrite the shopping story, one happy click at a time!"
        />
        <meta
          name="keywords"
          content="partner, contact us, become our partner, capmaign, affiliate marketing, digital marketing"
        />
      </Helmet>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner become_our_partner">
              <h1 className="text-white text-center">
                6 Reasons to Partner with Cashdhundo
              </h1>
              <button
                className="btn"
                data-bs-toggle="modal"
                href="#exampleModalTogglePartner"
                role="button"
              >
                Join Us
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-4">
            <div className="partner_section">
              <img
                src="https://cdn-icons-png.flaticon.com/256/7360/7360313.png"
                alt="cashdhundo"
              />
              <span>
                <h4>Reach Millions of Savvy Shoppers</h4>
                <p>
                  Tap into a massive network of Indian consumers actively
                  seeking deals, discounts, and cashback across diverse
                  categories. Expand your brand awareness and reach customers
                  you never could before, all under one digital roof.
                </p>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-4">
            <div className="partner_section">
              <img
                src="https://cdn-icons-png.flaticon.com/256/8305/8305539.png"
                alt="cashdhundo"
              />
              <span>
                <h4>Cashback is King</h4>
                <p>
                  Attract customers with the irresistible power of cashback!
                  Partner with Cashdhundo and offer exclusive cashback rewards
                  on your products and services, turning casual shoppers into
                  loyal fans.
                </p>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-4">
            <div className="partner_section">
              <img
                src="https://cdn-icons-png.flaticon.com/128/11739/11739194.png"
                alt="cashdhundo"
              />
              <span>
                <h4>Boost Sales & Conversions</h4>
                <p>
                  Witness the magic of increased sales and conversions as our
                  users flock to your brand with enticing deals and cashback
                  incentives. Watch your bottom line grow alongside happy
                  customers.
                </p>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-4">
            <div className="partner_section">
              <img
                src="https://cdn-icons-png.flaticon.com/256/7749/7749240.png"
                alt="cashdhundo"
              />
              <span>
                <h4>Brand Promotion Made Easy</h4>
                <p>
                  Leverage our robust marketing tools and targeted campaigns to
                  showcase your brand to the right audience at the right time.
                  We'll help you stand out from the crowd and make a lasting
                  impression.
                </p>
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-4">
            <div className="partner_section">
              <img
                src="https://cdn-icons-png.flaticon.com/256/6639/6639811.png"
                alt="cashdhundo"
              />
              <span>
                <h4>Grow Together, Win Together</h4>
                <p>
                  We believe in mutual success. As your partner, we'll work
                  alongside you to optimize your deals, analyze user behavior,
                  and refine your strategy for maximum impact. Let's unlock your
                  brand's full potential together!
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalTogglePartner"
        aria-hidden="true"
        aria-labelledby="exampleModalTogglePartnerLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className="outer-form missing-report-section">
                <form
                  action=""
                  className="form-data pt-2 pb-2"
                  onSubmit={PartnerFormSubmitHandler}
                >
                  <div className="register-heading mb-3">
                    <h5>Join Us</h5>
                  </div>
                  <div className="missing-fields">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="missing-fields missing-fields-flex">
                    <span>
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="abcd@gamil.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </span>
                    <span>
                      <label>Company Website</label>
                      <input
                        type="text"
                        placeholder="https://website.com"
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className="missing-fields">
                    <label>Company Name</label>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      onChange={(e) => setCompanyName(e.target.value)}
                    ></input>
                  </div>
                  <div className="missing-fields">
                    <label>Legal Name</label>
                    <input
                      type="text"
                      placeholder="Enter Legal Name"
                      onChange={(e) => setLegalName(e.target.value)}
                    ></input>
                  </div>
                  <div className="missing-fields">
                    <label>Company Address</label>
                    <input
                      type="text"
                      placeholder="Enter Company Address"
                      onChange={(e) => setAddress(e.target.value)}
                    ></input>
                  </div>
                  <div className="missing-fields">
                    <label>Message</label>
                    <textarea
                      type="text"
                      placeholder="Enter message"
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  {mess && (
                    <div className="alert alert-success" role="alert">
                      {mess}
                    </div>
                  )}
                  <div className="missing-fields input-login mt-2">
                    <button type="submit" className="btn" disabled={loading}>
                      {loading ? "Please wait..." : "Join Us"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BecomePartner;
