import React, { useState } from "react";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useCreateWithdrawMoneyMutation } from "../../App/Slice/withdrawMoneySlice";

function WithdrawMoney() {
  const { data } = useNormalUserVerifyQuery();
  const user = data?.user_data;

  const [createMoney, isLoading] = useCreateWithdrawMoneyMutation();
  const apiLoading = isLoading?.isLoading;

  function generateRandom17DigitNumber() {
    const min = Math.pow(10, 12);
    const max = Math.pow(10, 13) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const random17DigitNumber = generateRandom17DigitNumber();

  const [mess, setMess] = useState(false);

  const [amount, setAmount] = useState();
  const [mode, setMode] = useState();
  const [country, setCountry] = useState();
  const [name, setName] = useState();
  const [account, setAccount] = useState();
  const [bankName, setBankName] = useState();
  const [bankCity, setBankCity] = useState();
  const [bankBranch, setBankBranch] = useState();
  const [ifsc, setIFSC] = useState();
  const [pan, setPAN] = useState();
  const [address, setAddress] = useState();
  const [upiName, setUPIName] = useState();
  const [upi, setUPI] = useState();
  const [phone, setPhone] = useState();

  const bankDataFormHandler = async (e) => {
    e.preventDefault();
    const data = {
      user: user._id,
      amount: amount,
      Order_ID: `CD${random17DigitNumber}`,
      mode: mode,
      by_bank: {
        country_name: country,
        name: name,
        account_no: account,
        bank_name: bankName,
        bank_city: bankCity,
        bank_branch: bankBranch,
        ifsc_code: ifsc,
        pan_number: pan,
        address: address,
      },
      by_upi: {
        name: upiName,
        upi_detail: upi,
        phone: phone,
      },
    };

    const response = await createMoney(data);
    const result = response?.data;
    if (result?.status === "success") {
      setMess(result.message);
      setTimeout(() => {
        window.location.href = "/user/profile/wallet";
      }, 2000);
    } else {
      return;
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleWithdrawMoney"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleWithdrawMoneyLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content oop_section_width">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              {user?.amount <= 149 ? (
                <div className="opp_sec">
                  <div className="oops_section">
                    <img
                      src="https://api.zipkro.com/public/uploads/1704185491127oopss.png"
                      alt="cashdhundo"
                    />
                  </div>
                  <p>
                    Looks like your redeemable amount is less than ₹ 149.
                    Minimum amount required for bank transfer is ₹ 149.
                  </p>
                </div>
              ) : (
                <div className="outer-form missing-report-section">
                  <form
                    action=""
                    className="form-data pt-2 pb-2"
                    onSubmit={bankDataFormHandler}
                  >
                    <div className="register-heading mb-3">
                      <h5>Withdraw Money</h5>
                    </div>
                    <div className="missing-fields missing-fields-flex">
                      <span>
                        <label>Amount</label>
                        <input
                          type="text"
                          placeholder="Amount"
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </span>
                      <span>
                        <label>Source</label>
                        <select onChange={(e) => setMode(e.target.value)}>
                          <option value="Select Conditions" selected disabled>
                            Select Conditions
                          </option>
                          <option value="Bank Account">Bank Account</option>
                          <option value="UPI">UPI</option>
                        </select>
                      </span>
                    </div>

                    {mode === "Bank Account" ? (
                      <>
                        <div className="outer-form missing-report-section bank-account">
                          <div action="" className="form-data pt-2 pb-2">
                            <div className="register-heading mb-2">
                              <h6 style={{ textAlign: "left" }}>
                                Enter your Correct Bank Account Details
                              </h6>
                            </div>
                            <div className="missing-fields missing-fields-flex">
                              <span>
                                <label>Country Name</label>
                                <input
                                  type="text"
                                  placeholder="Enter Country Name"
                                  onChange={(e) => setCountry(e.target.value)}
                                />
                              </span>
                              <span>
                                <label>Pay Name</label>
                                <input
                                  type="text"
                                  placeholder="Your Name"
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </span>
                            </div>
                            <div className="missing-fields missing-fields-flex">
                              <span>
                                <label>Account No.</label>
                                <input
                                  type="text"
                                  placeholder="Enter Account Number"
                                  onChange={(e) => setAccount(e.target.value)}
                                />
                              </span>
                              <span>
                                <label>Bank Name</label>
                                <input
                                  type="text"
                                  placeholder="Enter Bank Name"
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </span>
                            </div>
                            <div className="missing-fields missing-fields-flex">
                              <span>
                                <label>Bank City</label>
                                <input
                                  type="text"
                                  placeholder="Enter City"
                                  onChange={(e) => setBankCity(e.target.value)}
                                />
                              </span>
                              <span>
                                <label>Bank Branch</label>
                                <input
                                  type="text"
                                  placeholder="Enter branch"
                                  onChange={(e) =>
                                    setBankBranch(e.target.value)
                                  }
                                />
                              </span>
                            </div>
                            <div className="missing-fields missing-fields-flex">
                              <span>
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  placeholder="Enter IFSC code"
                                  onChange={(e) => setIFSC(e.target.value)}
                                />
                              </span>
                              <span>
                                <label>PAN Number</label>
                                <input
                                  type="text"
                                  placeholder="Enter Your PAN number"
                                  onChange={(e) => setPAN(e.target.value)}
                                />
                              </span>
                            </div>
                            <div className="missing-fields account-fields">
                              <label>Address</label>
                              <textarea
                                type="text"
                                placeholder="Enter your Address"
                                onChange={(e) => setAddress(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {mode === "UPI" ? (
                      <>
                        <div className="outer-form missing-report-section bank-account">
                          <div action="" className="form-data pt-2 pb-2">
                            <div className="register-heading mb-2">
                              <h6 style={{ textAlign: "left" }}>
                                Enter your Correct UPI Details
                              </h6>
                            </div>
                            <div className="missing-fields missing-fields-flex">
                              <span>
                                <label>Pay Name</label>
                                <input
                                  type="text"
                                  placeholder="Enter Pay Name"
                                  onChange={(e) => setUPIName(e.target.value)}
                                />
                              </span>
                              <span>
                                <label>UPI</label>
                                <input
                                  type="text"
                                  placeholder="Your UPI Details"
                                  onChange={(e) => setUPI(e.target.value)}
                                />
                              </span>
                            </div>
                            <div className="missing-fields missing-fields-flex">
                              <span>
                                <label>Phone No.</label>
                                <input
                                  type="text"
                                  placeholder="Enter Mobile Number"
                                  onChange={(e) => setPhone(e.target.value)}
                                />
                              </span>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {mess && (
                      <div className="alert alert-success mt-3" role="alert">
                        {mess}
                      </div>
                    )}

                    <div className="missing-fields input-login mt-2">
                      <button
                        type="submit"
                        className="btn"
                        disabled={apiLoading}
                      >
                        {apiLoading ? "Please wait..." : "Process"}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawMoney;
