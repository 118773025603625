import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { useGetBlogQuery } from "../../App/Slice/blogSlice";

function SliderBlog() {
  const { data } = useGetBlogQuery();
  let fullfilldata = data?.blog?.slice(0, 10);

  const blog = fullfilldata?.filter((item) => item.status.includes("success"));

  return (
    <>
      <div className="container-fluid p-0 blog-header">
        <Swiper
          loop={true}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          spaceBetween={30}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          className="row"
        >
          {blog?.map((val, ind) => {
            // console.log(val);
            const { _id, title, image, short_desc, trending, createdAt } = val;
            const titles = title.split(" ").join("-");
            //   console.log(ti);
            if (trending === "yes") {
              return (
                <SwiperSlide key={ind} className="col-lg-12 col-md-12 col-12">
                  <div className="header-img">
                    <Link to={`/blog/${titles}/${_id}`}>
                      <img src={image} loading="lazy" alt={title} />
                      <div className="outer-content">
                        <h1>{title}</h1>
                        <p>{short_desc?.slice(0, 320)}</p>
                        <div className="blog-profile d-flex justify-content-start flex-coloumn">
                          <div className="pro-image">
                            <img
                              src="https://api.cashdhundo.com/public/uploads/1704014236907user.png"
                              loading="lazy" alt={title}
                            />
                          </div>
                          <div className="profile-content">
                            <h4>Written by cashdhundo</h4>
                            <h5>Posted on {createdAt?.slice(0, 10)}</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              );
            } else {
              return;
            }
          })}
        </Swiper>
      </div>
    </>
  );
}

export default SliderBlog;
