import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const store = createApi({
  reducerPath: "store",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getStore: builder.query({
      query: () => ({
        url: "/client",
        method: "GET",
      }),
    }),

    getByIdStore: builder.query({
      query: (id) => {
        return {
          url: `/client/${id}`,
          method: "GET",
        };
      },
    }),

    getAllStoreData: builder.query({
      query: (q) => {
        return {
          url: `/client/search/query/${q}`,
          method: "GET",
        };
      },
    }),

    getSearchBaseStoreData: builder.query({
      query: (q) => {
        return {
          url: `/client/search/cds-query/${q}`,
          method: "GET",
        };
      },
    }),

    getSearchStore: builder.mutation({
      query: (query) => {
        return {
          url: `client/serach/store/${query}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetStoreQuery,
  useGetSearchStoreMutation,
  useGetByIdStoreQuery,
  useGetAllStoreDataQuery,
  useGetSearchBaseStoreDataQuery,
} = store;
