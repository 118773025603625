//https://www.flipkart.in/&p5=zip7358070615&p6=ref1368324&p7=80% cashback by cashdhundo&source=ZIP993330&campaign_name=Flipkart
//https://mediaxpedia.gotrackier.com/click?campaign_id=24780&pub_id=1603&p1={click_id}&p5=zip9744392070&p6=ref8733988&p7=30%-Cashback-by-cashdhundo&source=ZIP993330&campaign_name=Earthrhythm

import React from "react";
import { FiArrowUpRight, FiCopy } from "react-icons/fi";
import {
  useGetByIdStoreQuery,
  useGetStoreQuery,
} from "../App/Slice/storeSlice";
import { useState } from "react";
import {
  useCreateOrderIDMutation,
  useGetOrderIDQuery,
} from "../App/Slice/orderIDSlice";
import { useNormalUserVerifyQuery } from "../App/Slice/userSlice";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { GetUserSecure } from "../Secure/Helper";
import { Helmet } from "react-helmet";

function Refer_Earn() {
  const [toggle, setToggle] = useState(false);
  const [refrenceLink, setRefrenceLink] = useState("");
  const { data: userData } = useNormalUserVerifyQuery();
  const user = userData?.user_data || {};

  const { data } = useGetStoreQuery();
  const client = data?.client;

  const [client_id, setClientId] = useState();
  const { data: clientID } = useGetByIdStoreQuery(client_id);
  const client_data = clientID?.client;
  const { description, cashback_rates, logo, name, calc, client_offer } =
    client_data || {};

  const filterClient = client?.filter((ele) => {
    const cl = ele?.calc?.[0];
    // console.log(cl);
    return cl !== null && cl !== "Paused";
  });

  const clientName = name?.split(" ")?.join("-");

  const calcu = calc?.[1];
  const cashback = calc?.[0]?.split(" ")?.join("-");

  const { data: orderId } = useGetOrderIDQuery();
  const order_id = orderId?.order_id;
  const link_generate_id = orderId?.order_id;

  const [createOrderID] = useCreateOrderIDMutation();

  const generateUniqueRandom17DigitNumber = () => {
    const min = Math.pow(10, 9);
    const max = Math.pow(10, 10) - 1;
    const random17DigitNumber =
      Math.floor(Math.random() * (max - min + 1)) + min;

    const existingOrder = random17DigitNumber;
    if (existingOrder === order_id || existingOrder === link_generate_id) {
      return generateUniqueRandom17DigitNumber();
    }
    return random17DigitNumber;
  };
  const random17DigitNumber = generateUniqueRandom17DigitNumber();

  const generateUniqueRandom17DigitNumberRefernce = () => {
    const min = Math.pow(10, 6);
    const max = Math.pow(10, 7) - 1;
    const random17DigitNumber =
      Math.floor(Math.random() * (max - min + 1)) + min;

    const existingOrder = random17DigitNumber;
    if (existingOrder === order_id || existingOrder === link_generate_id) {
      return generateUniqueRandom17DigitNumberRefernce();
    }
    return random17DigitNumber;
  };
  const random17DigitNumberRefrence =
    generateUniqueRandom17DigitNumberRefernce();

  const source = `&source=${user.sub_id}`; //source
  const p5 = `&p5=CD${random17DigitNumber}`; //cashdhundo orider id
  const p6 = `&p1=ref${random17DigitNumberRefrence}`; //referal id
  const p7 = `&p7=${cashback}+${calcu}`; //cashback offers
  const campaign_name = `&p8=${clientName}`; //store name

  const url_generate = `${client_data?.link}${p5}${p6}${p7}${source}${campaign_name}`;

  const generateLinker = async () => {
    const data = {
      user: user._id,
      client: client_id,
      order_id: `CD${random17DigitNumber}`,
      link_generate_id: `ref${random17DigitNumberRefrence}`,
    };
    await createOrderID(data);
    const url_generate = `${client_data?.link}${p5}${p6}${p7}${source}${campaign_name}`;
    setRefrenceLink(url_generate);
    setToggle(true);
  };

  //https://api.cashdhundo.com/api/postback/acquisition?sub_id={source}&click_id={p1}&order_id={txn_id}&sale_amount={sale_amount}&currency={currency}&campaign_name={p8}&refernce_link={p6}&zip_order_id={p5}&cashback={p7}&security_token=J2023IT400UMX

  const refrenceHandlerCopy = (link) => {
    navigator.clipboard.writeText(`${link}`);
    alert("Copy Refrence Link :)");
  };

  const title = client_data?.name;

  useEffect(() => {
    GetUserSecure();
  }, []);

  return (
    <>
      {/* Friends who shop together, save together! Refer & Earn, cashback
          weather! */}
      <Helmet>
        <title>Refer & Earn with our Friends</title>
        <meta
          name="description"
          content="Spread the savings love with CASHDHUNDO's Refer & Earn! Share your link, friends shop, and you both earn cashback magic. From fashion finds to tech gadgets, every referral unlocks rewards. Watch your cashback grow as your friends join the CASHDHUNDO community. It's a win-win! So, start sharing and start earning together. Happy shopping, happy saving!"
        />
        <meta
          name="keywords"
          content="refer and earn, friends, earn, coin, refer and earn with your friends, saving money, share link and earn money, shop, cashback, "
        />
      </Helmet>
      <div className="container mt-5 mb-5 refer-container d-flex align-items-center justify-content-center">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mobile_hidden">
            {!client_data ? (
              <div className="without_client_image">
                <img
                  src="https://api.cashdhundo.com/public/uploads/1705214442318money.png"
                  loading="lazy"
                  alt="cashdhundo"
                />
              </div>
            ) : (
              <>
                <div className="select_container">
                  <div className="client_logo mx-auto">
                    <span>
                      <img src={logo} loading="lazy" alt={client_offer} />
                    </span>
                    <span>
                      <h3>{name}</h3>
                      <h4>
                        <Link to={url_generate} target="_blank">
                          {client_offer} Cashback
                          <span>
                            <FiArrowUpRight />
                          </span>
                        </Link>
                      </h4>
                    </span>
                  </div>
                  <div className="client_content mt-4">
                    {cashback_rates.length === 0 ? null : (
                      <ul className="mb-3">
                        <h5>Feature</h5>
                        {cashback_rates?.map((val, ind) => {
                          return <li key={ind}>{val}</li>;
                        })}
                      </ul>
                    )}
                    {!description ? null : (
                      <>
                        <h5>Description</h5>
                        <p>{description}</p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="refer_earn_section sticky-top">
              <h5 className="text-center mb-3">
                Generate Link & Share your Friends! Get Cashback
              </h5>
              <div className="store-selector">
                <select
                  className="mb-3"
                  onChange={(e) => setClientId(e.target.value)}
                >
                  <option value="Select Store" disabled selected>
                    Select Store
                  </option>
                  {filterClient?.map((val, ind) => {
                    return (
                      <option key={ind} value={val._id}>
                        {val.name}
                      </option>
                    );
                  })}
                </select>
                {toggle === false ? (
                  " "
                ) : (
                  <>
                    <div className="generate_link mb-1">
                      <span
                        type="button"
                        title="copy link"
                        onClick={() => refrenceHandlerCopy(refrenceLink)}
                      >
                        <FiCopy />
                      </span>
                      <h6>{refrenceLink}</h6>
                    </div>
                  </>
                )}

                <div className="generate_link mb-3">
                  <button type="button" onClick={generateLinker}>
                    Generate Link
                  </button>
                </div>
                <div className="link-share">
                  <FacebookShareButton url={refrenceLink} title={title}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton url={refrenceLink} title={title}>
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <LinkedinShareButton url={refrenceLink} title={title}>
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={refrenceLink} title={title}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Refer_Earn;
