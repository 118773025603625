import React, { useEffect } from "react";
import { FiCheck, FiChevronRight, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import UpdateProfile from "../UpdateProfile";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { GetUserSecure } from "../../Secure/Helper";

function Profile() {
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data || {};

  const {
    username,
    email,
    phone,
    sub_id,
    email_verified,
    phone_verified,
    amount,
  } = name;

  useEffect(() => {
    GetUserSecure();
  }, []);

  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name.username}</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Profile</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9 col-md-9 col-12">
            <div className="container p-0">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="wallet-section">
                    <div className="wallet-img">
                      <img
                        src="../../assets/img/animation/wallet.gif"
                        loading="lazy"
                        alt="cashdhundo"
                      />
                    </div>
                    <div className="wallet-amount">
                      <h5>
                        {email_verified === "false" ? (
                          <h5>
                            ₹ {amount} <span>Pending..</span>
                          </h5>
                        ) : (
                          <h5>₹ {amount}</h5>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-lg-9 col-md-9 col-12">
                  <div className="inner-profile ">
                    <span className=" mb-4 d-flex align-items-center justify-content-between">
                      <h5>Profile</h5>{" "}
                      <a
                        className="btn edit-profile"
                        data-bs-toggle="modal"
                        href="#exampleModalToggleEditProfile"
                        role="button"
                      >
                        Edit Profile
                      </a>
                    </span>
                    <div className="profile-se d-flex align-items-start">
                      <span className="profile-img">
                        <img
                          src="../../assets/img/profile.jpeg"
                          loading="lazy"
                          alt="cashdhundo"
                        />
                      </span>
                      <span className="profile-form">
                        <ul className="profile-list">
                          <li>
                            <span className="first-list">Sub Id</span>{" "}
                            <span className="api-data">{sub_id}</span>
                          </li>
                          <li>
                            <span className="first-list">Username</span>{" "}
                            <span className="api-data">{username}</span>
                          </li>
                          <li>
                            <span className="first-list">E-mail</span>{" "}
                            <span className="api-data">
                              {email}{" "}
                              <>
                                {email_verified === "true" ? (
                                  <span className="success-check">
                                    <FiCheck />
                                  </span>
                                ) : (
                                  <span className="failed-check">
                                    <FiX />
                                  </span>
                                )}
                              </>
                            </span>
                          </li>
                          <li>
                            <span className="first-list">Phone</span>{" "}
                            <span className="api-data">
                              {phone}{" "}
                              <>
                                {phone_verified === "true" ? (
                                  <span className="success-check">
                                    <FiCheck />
                                  </span>
                                ) : (
                                  <span className="failed-check">
                                    <FiX />
                                  </span>
                                )}
                              </>
                            </span>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateProfile name={name} />
    </>
  );
}

export default Profile;
