import React from "react";
import SliderBlog from "../View/Blog/SliderBlog";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import Popular from "../View/Blog/Popular";
import Main_Blog_Card from "../View/Blog/Main_Blog_Card";
import { useGetBlogQuery } from "../App/Slice/blogSlice";
import { Helmet } from "react-helmet";

function Blog() {
  const params = useParams();
  const cateFilter = params?.category;
  const { data: blogCate } = useGetBlogQuery();
  let blogCategory = blogCate?.blog;

  const findalData = blogCategory?.filter((item) =>
    item.status.includes("success")
  );

  const allCategory = [
    ...new Set(
      findalData?.map((val) => {
        return val.category?.name;
      })
    ),
    "All",
  ];
  const filterCategory = allCategory?.reverse();

  return (
    <>
      <Helmet>
        <title>All Blogs</title>
        <meta
          name="description"
          content="Unlock the secrets of smart shopping with Cashdhundo's blog! Discover insider tips, category guides, and expert reviews to snag the best deals and maximize your cashback across electronics, fashion, travel, and more!"
        />
        <meta
          name="keywords"
          content="blogs, cashdhundo blog, cashdhundo blogs, CPS, CPI, CPV, blogs"
        />
      </Helmet>
      <SliderBlog />
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to={`/blog/category/filter/All`}>Blogs</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{cateFilter}</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-lg-9 col-md-9 col-12">
            <div className="row">
              <Main_Blog_Card cateFilter={cateFilter} />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="category-filter">
                <h6 className="mb-3">Category Filter</h6>
                <ul>
                  <li>
                    {filterCategory?.map((val, ind) => {
                      return (
                        <Link key={ind} to={`/blog/category/filter/${val}`}>
                          {val}
                        </Link>
                      );
                    })}
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <Popular />
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
