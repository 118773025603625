import React, { useState } from "react";
import { FiLock } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useChangeUserPasswordMutation } from "../App/Slice/userSlice";
import { toast } from "react-toastify";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa6";

function ForgotPassword() {
  const params = useParams();
  const { id, token } = params;

  const [mess, setMess] = useState(null);
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();
  const [changePassword, isLoading] = useChangeUserPasswordMutation();

  const [show, setShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const apiLoading = isLoading?.isLoading;

  const changePasswordFormhandler = async (e) => {
    e.preventDefault();
    const data = {
      id: id,
      token: token,
      password: password,
      password_confirmation: password_confirmation,
    };
    const response = await changePassword(data);
    const result = response?.data;
    if (result.status === "success") {
      setMess(result);
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 1500);
      return;
    } else {
      toast.error(`${result.message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
  };

  const showPasswordHandler = () => {
    setShow(!show);
  };

  const showConfirmPasswordHandler = () => {
    setConfirmShow(!confirmShow);
  };

  return (
    <>
      <div className="modal-body forgot-modal login_form">
        <div className="outer-form">
          <form
            className="form-data pt-3 pb-4"
            onSubmit={changePasswordFormhandler}
          >
            <div className="register-heading mb-4">
              <h5>FORGOT PASSWORD</h5>
            </div>
            {mess && (
              <div class="alert alert-success" role="alert">
                {mess?.message}
              </div>
            )}
            <div className="input-fields">
              <span>
                <FiLock />
              </span>
              <input
                type={show === true ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="show_password" onClick={showPasswordHandler}>
                {!show === true ? <FaEyeSlash /> : <IoEyeSharp />}
              </span>
            </div>
            <div className="input-fields">
              <span>
                <FiLock />
              </span>
              <input
                type={confirmShow === true ? "text" : "password"}
                placeholder="Repeat Password"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <span
                className="show_password"
                onClick={showConfirmPasswordHandler}
              >
                {!confirmShow === true ? <FaEyeSlash /> : <IoEyeSharp />}
              </span>
            </div>
            <div className="input-fields mt-2 p-0">
              <button type="submit" className="btn" disabled={apiLoading}>
                {apiLoading === true ? "Please wait..." : "Forgot Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
