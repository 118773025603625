import React from "react";

function TermsAndCondition() {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">Terms and Conditions – CASHDHUNDO</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="terms_conditions_para p-4">
          <div className="condition_heading">
            <h2>1. Introduction</h2>
            <p>
              We extend our gratitude for your exploration of www.cashdhundo.com
              or the cashdhundo mobile application, collectively recognized as
              'Cashdhundo' – India's preeminent Cashback Site
              www.Cashdhundo.com. The operation of the cashback service is
              orchestrated by Cashdhundo ('We,' 'Us,' or 'Our,' inclusive of its
              respective legal heirs, representatives, administrators, permitted
              successors, and assigns, unless contextually incongruous). Upon
              engaging with the diverse services presented on the Cashdhundo
              Platform ('Services'), you are cognizant of and acknowledge these
              terms, guidelines, and supplementary terms pertaining to the
              Services availed (collectively referred to as 'Terms'). These
              serve as a testament to Cashdhundo's dedication to enhancing the
              experience of every user on the platform. <br />
              Please meticulously peruse these terms and conditions ('Terms and
              Conditions' or 'Agreement') as they delineate the legal parameters
              binding you when availing the services provided by us through the
              Website and the App ('Platform,' 'Website'). Any substantive
              modifications to these terms and conditions will be communicated
              to our registered members either through an email notice to the
              provided address during sign-up or via a prominently displayed
              pop-up on our website/mobile application(s). It is advised to
              periodically revisit this page to stay abreast of the most recent
              iteration of the Agreement.
            </p>
          </div>
          <div className="condition_heading">
            <h2>2. Membership Guidelines</h2>
            <p>
              Transactions executed on the Cashdhundo platform are restricted
              exclusively to bona fide personal usage. The utilization of the
              Cashdhundo Platform for procurement with the intention of resale,
              engagement in commercial activities, or seeking financial gains is
              expressly prohibited. Cashdhundo maintains the authority to impede
              or terminate a user's Cashdhundo Platform account without prior
              notice if, based on its risk management criteria, there exists a
              reasonable suspicion that the platform is being employed for
              unauthorized purposes. This measure ensures the integrity of the
              platform and aligns with the stringent risk assessment standards
              implemented by Cashdhundo.
            </p>
          </div>
          <div className="condition_heading">
            <h2>3. Our Array of Services</h2>
            <h4>a. Cashback Service:</h4>
            <p>
              Our sophisticated Cashback Service empowers registered users on
              the Platform, referred to as "Members," to accrue cashback through
              strategic purchases from a curated list of esteemed retailers. The
              fruition of this endeavour is contingent upon the Retailer's
              confirmation of a Member's purchase as meticulously tracked,
              authentically executed, and successfully concluded—this, in
              essence, constitutes a "Qualifying Transaction," culminating in
              the conversion of accrued benefits into tangible "Cashback."
              Members can seamlessly transfer their accumulated Cashback to
              their bank accounts once it attains the predetermined minimum
              threshold set by our discerning standards. The validity of a
              transaction hinges on the Member, redirected by Cashdhundo, making
              a legitimate purchase on the Retailer's platform, ensuring a
              seamless delivery process, and allowing the expiration of the
              refund/return period. It is imperative to note that transactions
              untraceable by Retailers may not be remitted to Cashdhundo,
              thereby withholding the anticipated cashback entitlement to the
              Member.
            </p>
            <h4>b. Reward Program:</h4>
            <p>
              Our intricately designed Reward Program offers users the
              opportunity to amass Rewards by channeling their purchases through
              our platform, leading to redirection to select retailers that
              offer rewards as opposed to conventional cashbacks. Notably, these
              Rewards, while not transferable to a user's bank account, can be
              judiciously redeemed through an array of options such as gift
              cards or digital wallets, once the stipulated minimum threshold
              set by our exacting standards is met.
            </p>
            <h4>c. Unique Referral Fee:</h4>
            <p>
              The innovative Cashdhundo Service extends a distinctive referral
              fee to our esteemed Members. Existing Members are entitled to a
              commendable 10% cashback for each new Member they successfully
              introduce, qualifying as a "Qualifying Referral." It is pertinent
              to highlight that the continuity of referrers' eligibility is
              contingent upon their ongoing status as Members, although
              transactions conducted at specific stores may preclude them from
              the 10% cashback entitlement. In alignment with stringent legal
              standards, Members are obliged to utilize our website in
              accordance with the law. We remain steadfast in our commitment to
              continuous improvement and enhancement of our referral program,
              reserving the right to amend its terms at our discretion, with due
              consideration for transparency and fairness to all stakeholders,
              at any juncture, without prior notice.
            </p>
          </div>
          <div className="condition_heading">
            <h2>4. Membership Enrollment</h2>
            <p>
              For individuals contemplating membership, adherence to specific
              criteria is requisite. Should you be an individual, it is
              imperative that you (i) have attained the age of 18 (eighteen)
              years or older to engage with the Platform, and (ii) by accessing
              the Platform or endorsing these Terms and Conditions, affirm to
              Cashdhundo your attainment of the age of 18 (eighteen) years or
              older. Additionally, you warrant possessing the requisite right,
              authority, and capacity to effectively navigate and leverage the
              Platform, thereby pledging compliance with the stipulations
              outlined in these Terms and Conditions. In the event that you act
              as a parent or legal guardian of an individual below 18 (eighteen)
              years of age, you are granted permission to access and utilize the
              Platform with the sole purpose of procuring Services on behalf of
              the said individuals.
              <br /> <br />
              In the process of registering for the Cashback Service, it is
              imperious that you furnish precise and current information
              concerning your personal details, encompassing your accurate name,
              address, and any other particulars solicited during the
              registration process. In the event that you disclose information
              pertaining to a bank account, UPI, or any alternative payment
              method for the reception of payouts (hereinafter referred to as
              your “Cashback Receipt Method”), you are obligated to (a) ensure
              full entitlement to the usage of the designated Cashback Receipt
              Method, and (b) express your unequivocal intention to receive
              cashback through the designated Cashback Receipt Method. The onus
              of maintaining updated information lies with you through your
              Account. It remains within our purview to discontinue or
              temporarily suspend any enumerated payment methods, including but
              not limited to UPI, Bank Transfer, or Wallet/Gift Card, as listed
              on our site. It is underscored that the generation and collection
              of 'Sensitive Personal Data or Information' align with the
              provisions set forth in the Information Technology Act, 2000, as
              amended, and allied rules, necessitating explicit consent from the
              User. By affirmatively assenting to the information generation and
              collection, as mandated by applicable laws, during the
              registration process by clicking the “I agree with Terms and
              Policy” button, you validate your compliance with these legal
              requirements
              <br /> <br />
              It is crucial to note that the chosen Cashback Receipt Method
              (such as Bank Transfer, Gift Cards/Wallets, UPI, or other
              forthcoming payment modalities) imposes a minimum Cashback
              threshold for withdrawal, and adherence to this threshold is
              obligatory to receive Cashback through the designated Cashback
              Receipt Method. Furthermore, it is your responsibility to ensure
              the currency and accessibility of the email address and mobile
              number associated with your Account. Pertinent updates will be
              communicated through various channels, including text messages,
              emails, app notifications, and, where applicable, WhatsApp. In the
              event of alterations to your email address or mobile number,
              prompt updating of the details within your Account is mandated.
            </p>
          </div>
          <div className="condition_heading">
            <h2>5. Cashback, Rewards, and Referral Fees</h2>
            <p>
              Upon the successful completion of a Qualifying Transaction by a
              Member and upon our receipt of the ensuing Cashback/Reward for
              said transaction, we proceed to transfer the Cashback/Reward to
              the Member through their designated Cashback Receipt Method. It is
              imperative to acknowledge that there exist instances in which a
              transaction with a Retailer may not meet the criteria of a
              Qualifying Transaction, leading to the non-accrual of
              Cashback/Rewards. This principle holds true for Qualifying
              Referrals as well. Members explicitly recognize that all
              transactions occur with individual Retailers and not directly with
              us. Further insights into such scenarios can be found in our help
              pages. Additionally, Retailers retain the right to adjust
              commission rates, potentially affecting the displayed
              cashback/Reward offer on our Platform. By default, your
              transactions will be credited based on the commission reported by
              the Retailer, which may deviate from the advertised rate. We bear
              no responsibility for any disparities between the anticipated and
              actual cashback/reward received by the Member.
              <br /> <br />
              Upon the identification of a paying, Qualifying Transaction in
              your Account, we credit it as a 'pending payment.' Subsequently,
              once the Retailer confirms the transaction, it attains the status
              of 'validated,' a process that may extend for 90 days or more from
              the date of purchase. In the event of product returns or sale
              reversals, the processing of cashback/reward payments will not
              transpire. Members are obligated to inform Cashdhundo of any
              returns or exchanges that may impact their earned cashback/reward.{" "}
              <br /> <br />
              The determination of whether a transaction qualifies as a
              Qualifying Transaction lies with the discretion of the Retailer or
              us, and Members explicitly consent to this discretion. We bear no
              responsibility if a Retailer fails to report a sale to us or if a
              transaction is not categorized as a Qualifying Transaction.
              Technical errors preventing the traceability of a sale back to the
              Member are also beyond our accountability. While efforts will be
              made to recover non-payments from the Retailer, the final decision
              rests with ourselves, the Retailer, or the Retailer’s tracking
              agent. We retain the right to refrain from pursuing missing
              commission claims, particularly when no purchase has occurred.{" "}
              <br /> <br />
              Moreover, if a Retailer deems a purchase as not genuine for any
              reason, and we do not receive Cashback/Rewards for the
              transaction, the Member shall not be entitled to any
              Cashback/Rewards. In the event of a Member raising a ticket
              regarding missing cashback/reward, our system examines for an exit
              click from our Platform to the store on the specified date. If no
              exit clicks are tracked, the Member will be notified that they did
              not navigate via our website before the sale. All exit clicks to
              stores are stored in the database for cross-reference when adding
              cashback/reward values. In the event a Retailer successfully pays
              a manual commission claim, the Member may receive a reduced amount
              due to our receipts potentially being lower than the original
              claim. We afford Members the opportunity to raise queries with us.
              If a Retailer fails to pay a manual commission claim after six
              months, we reserve the right to close the inquiry claim. Payment
              for Missing Cashback/Reward is contingent on the Retailer settling
              dues with Cashdhundo. It is crucial to note that users intending
              to raise a ticket for missing cashback/reward must do so within 10
              (ten) days from the date of the transaction, with no claims
              entertained beyond this timeline.
              <br /> <br />
              We reserve the right to recoup or adjust balances if it is
              determined that any credit was applied in error. This encompasses,
              but is not limited to, transactions where credit is not genuinely
              due, payment for any credited transaction has not been received
              from a Retailer or its agencies, and cases of misuse or fraud.
              Such instances may involve transactions marked as payable or
              validated in your Account, or transactions already disbursed to
              you through Bank Transfer, Gift Cards/Wallets, UPIs, or any other
              payment modes introduced from time to time. In these scenarios,
              Cashdhundo retains the right to recover all unduly paid
              cashback/rewards through legal proceedings. <br /> <br />
              Users are responsible for ensuring accurate details when raising a
              pay-out request, selecting the withdrawal method (Bank Transfer,
              Gift Card/Wallet, UPIs, etc.). In the event of incorrect details
              being provided and subsequent processing of the pay-out(s), we
              shall not be held liable for any such negligence, considering it
              an error on the User's part. <br /> <br />
              Users must ensure correct details when raising a pay-out request,
              selecting the withdrawal method (Bank Transfer, Gift Card/Wallet,
              UPIs, etc.). If incorrect details are provided and we process the
              pay-out(s), we will not be liable for any such negligence, which
              will be considered the User's error.
              <br /> <br />
              There exist various circumstances in which Cashback/Rewards will
              not be payable to the Member and will be forfeited by us. These
              circumstances include, without limitation: <br /> <br />
            </p>
            <ul>
              <li>
                {" "}
                If the cashback/reward payment received is not attributed to a
                Qualifying Transaction or associated with an Account e.g., when
                the Member is not logged in to our Platform during the relevant
                purchase{" "}
              </li>
              <li>
                If the transaction to which the Cashback/Reward relates is
                canceled after being entered into, whether under the right of
                cancellation that applies to some sales made at a distance or
                otherwise.
              </li>
              <li>
                If the Cashback/Reward is attributed to a suspended Account or
                one associated with fraudulent activity or any breach of this
                Agreement. Additional information about these circumstances can
                be found in our help pages.
              </li>
            </ul>
          </div>
          <div className="condition_heading">
            <h2>6. Account Withdrawal and Redemption</h2>
            <p>
              Upon reaching the stipulated minimum threshold for cashback or
              rewards in your account, you become eligible to withdraw or redeem
              your validated earnings. Should you opt to close your account, any
              validated cashback or rewards below the specified minimum
              threshold at the time of closure will be forfeited entirely.
              However, if the validated cashback or rewards in your account
              equals or surpasses the minimum threshold, you may initiate a
              payment request through any of the designated Cashback or Reward
              Receipt Methods. <br /> <br />
              It is important to note that we reserve the discretion to forfeit
              any validated balance displayed on your account, a matter further
              elaborated upon in other sections of this Agreement. This
              provision underscores our commitment to ensuring compliance with
              the terms and conditions governing your account and the redemption
              of associated benefits.
            </p>
          </div>
          <div className="condition_heading">
            <h2>7. Validity of Cashback/ Rewards/ Referral/ Special Bonus</h2>
            <ul>
              <li>
                <span className="fw-bold">Acknowledgment: </span> You recognize
                that your Cashdhundo Earnings will expire if you do not log in
                to your Cashdhundo account (via the website or application) for
                a consecutive period of 2 years.
              </li>
              <li>
                <span className="fw-bold">Preventing expiry of earnings: </span>{" "}
                To avoid the expiration of your Earnings, it is necessary to log
                in to your Cashdhundo account via the website at least once
                every 2 years.
              </li>
              <li>
                <span className="fw-bold">Expired earnings: </span> Once
                expired, this amount will be removed from the Order Details page
                and will be categorized under the "Expired Cashbacks" menu as a
                single entry. Such expired Earnings will not be withdrawable by
                you.
              </li>
            </ul>
          </div>
          <div className="condition_heading">
            <h2>8. Intellectual Property</h2>
            <p>
              Acknowledgment is extended to the User/visitor for the
              understanding that all copyrights, trademarks, and additional
              intellectual property rights associated with the Platform,
              encompassing content contributed by Members or Retailers, either
              vest in Us or are subject to licensing arrangements with Us. While
              the act of copying material from our portal may initially seem
              uncomplicated, it is essential to underscore its illegality.
              Vigorous actions will be undertaken against the User/visitor in
              accordance with relevant laws. Hence, no individual is sanctioned
              to engage in activities such as copying, distributing, publicly
              displaying, or crafting derivative works from the Platform or any
              material discovered on the Platform unless an appropriate license
              has been duly granted by Us.
              <br /> <br />
              Upon uploading or incorporating any material onto the Platform, a
              Member explicitly confers the following: <br /> <br />
            </p>
            <ol>
              <li>
                <span className="fw-bold">To Us: </span> A non-exclusive
                license, inclusive of the right to sublicense, enabling the use,
                reproduction, and distribution of said material through our
                Cashback/Reward Service and any other interactive services
                through which We or our sublicensees render the Cashback/Reward
                Service (or a service derived from our offering) accessible.
              </li>
              <li>
                <span className="fw-bold">To Other Members: </span> Through us,
                under the license delineated in the preceding point, the
                non-exclusive, personal, and non-transferable right to peruse
                the pertinent material.
              </li>
            </ol>
          </div>
          <div className="condition_heading">
            <h2>9. Privacy Policy</h2>
            <p>
              The Privacy Policy, an intrinsic component of this Agreement,
              assumes a pivotal role in delineating the framework governing the
              management of your personal data. By endorsing this Agreement, you
              concurrently express your consent to the protocols articulated
              within the aforementioned policy. Acknowledging the expansive
              reach of the World Wide Web, it is imperative to comprehend that
              any content posted on the Platform possesses the potential for
              accessibility by internet users on a global scale.
            </p>
          </div>
          <div className="condition_heading">
            <h2>10. Privacy Concerning Minors</h2>
            <p>
              Our website is not designed for the use of minors. Deliberately,
              we refrain from collecting personal data from children. We kindly
              request parents or guardians to reach out to us if they become
              aware that their child has furnished us with personal information.
              Your communication regarding such matters is of utmost importance
              to us, and we appreciate your proactive involvement in ensuring
              the privacy and security of minors.
            </p>
          </div>
          <div className="condition_heading">
            <h2>11. Our Role</h2>
            <p>
              We abstain from engaging in any transactions with Retailers,
              assuming neither the role of the seller nor the supplier of the
              goods or services they proffer. Consequently, we bear no legal
              obligations akin to those incumbent upon sellers in relation to
              the aforementioned goods or services. Consequently, we disavow
              control over and disclaim responsibility for the following
              aspects:
              <br />
            </p>
            <ol>
              <li>
                The quality, safety, or legality of goods or services tendered
                by Retailers.
              </li>
              <li>
                The Retailer's capability to furnish and convey unencumbered
                title to any goods or services.
              </li>
            </ol>
            <p>
              In transactions with Retailers, Members are advised to exercise a
              degree of circumspection commensurate with that exercised in
              analogous offline transactions. Within the bounds stipulated by
              Applicable Law, you hereby absolve Us, Our Agents, and employees
              of any liability arising from or related to transactions with
              Retailers. This encompasses all claims and demands, whether
              pertaining to incomplete or consummated transactions, or goods and
              services presented, sold, or provided through or in conjunction
              with any transactions involving Retailers.
            </p>
          </div>
          <div className="condition_heading">
            <h2>12. Misuse</h2>
            <p>
              We maintain the prerogative to suspend or terminate a Member's
              access to our service or its components if, within the bounds of
              our reasonable judgment, the Member or Account is found to
              contravene any stipulation of this Agreement. <br />
              Members are proscribed from partaking in, or attempting to engage
              in, any transaction with a Retailer to accrue Cashback/Rewards
              through: (a) furnishing personal information belonging to someone
              else or a payment method to which they are not entitled, (b)
              artfully or unfairly exploiting a Retailer's offerings, including
              but not limited to fabricating fake or unauthorized referral
              links, or (c) transgressing any terms and conditions imposed by Us
              or the Retailer on that transaction. In the event of such misuse
              of our service, we retain the right to forfeit any pending or
              validated payments from the Member's Account. <br />
              Each Member assumes responsibility for ensuring that any material
              posted by them or affiliated with their Account: <br />
            </p>
            <ul>
              <li>
                Is not defamatory, offensive, or abusive, and is devoid of an
                obscene, indecent, or menacing nature.{" "}
              </li>
              <li>
                Is neither intended nor likely to cause needless annoyance,
                inconvenience, or distress to any person.
              </li>
              <li>
                Does not contain any computer virus, macro virus, Trojan horse,
                worm, or any other disruptive element designed to interfere
                with, interrupt, or disrupt the normal operating procedures of a
                computer or surreptitiously intercept, access without authority,
                or expropriate any system, data, or personal information.
              </li>
              <li>Abides by the Applicable Law and regulations.</li>
              <li>
                Does not infringe upon the rights of any person or entity,
                including expectations of privacy.
              </li>
              <li>
                Must be accurate and fair in relation to feedback on Retailers.
              </li>
              <li>Does not serve as advertising for any goods or services.</li>
            </ul>
            <p>
              Notwithstanding the provisions in this Agreement and the Privacy
              Policy, we reserve the right to probe complaints or reported
              violations and take suitable action, which may include reporting
              suspected unlawful activity to law enforcement officials,
              regulators, or other third parties. Any Member found, at our sole
              discretion or that of the Retailer, to have committed fraud or
              misused Our Platform or Cashback/Reward Service will face
              immediate closure of their Account. The registered email and IP
              address will also be blacklisted, preventing the Member from
              utilizing or registering with the same credentials or IP address
              in the future.
              <br /> <br />
              Should you encounter anything on Our Platform that appears to
              infringe upon the aforementioned requirements, we encourage you to
              apprise us using Our contact form. Each Member acknowledges that
              we possess the entitlement, though not the obligation, to withdraw
              any material that seems to contravene this Agreement based on
              information received from third parties or other Members.
            </p>
          </div>
          <div className="condition_heading">
            <h2>13. Contact from External Entities</h2>
            <p>
              In the event of any communication directed towards us pertaining
              to materials or transactions associated with you or your Account,
              it is hereby agreed:
            </p>
            <ul>
              <li>
                To provide all pertinent and reasonable information as well as
                assistance essential for our comprehensive response to such
                communication.
              </li>
              <li>
                To expeditiously and precisely respond, should we convey the
                message to you, requiring your timely feedback.
              </li>
            </ul>
          </div>
          <div className="condition_heading">
            <h2>14. Additional Services</h2>
            <p>
              Periodically, either we or our affiliated partners may introduce
              novel or supplementary services via this Platform. The utilization
              of such services may be subject to supplementary terms and
              conditions, to which your adherence is obligatory. Provided that
              these terms are effectively communicated to you on the Platform in
              a manner deemed appropriate by us, exercising our reasonable
              discretion, upon your consent to avail yourself of said services,
              any failure on your part to comply with a substantial provision of
              the terms governing those services will be construed as a breach
              of this Agreement.
            </p>
          </div>
          <div className="condition_heading">
            <h2>15. Operation of Our Cashback/ Reward Service</h2>
            <p>
              We reserve the prerogative to withdraw, amend, or suspend specific
              facets of the Cashback/Reward Service or the entirety of the
              service, citing legal, security, technical, or commercial
              rationales. Our commitment includes an endeavor to furnish you
              with a notice period of 30 days before implementing such measures,
              except in instances where expedited action becomes imperative for
              security reasons or due to technical impediments that might
              detrimentally impact our service. Occurrences of unavailability of
              the Cashback/Reward Service may transpire owing to technical
              glitches encountered by us or on the Internet. Nonetheless, we
              pledge to apply reasonable expertise and diligence to rectify
              these challenges within our sphere of influence. Although
              continuous access to the Cashback/Reward Services cannot be
              unconditionally guaranteed, we will apprise users of service
              unavailability, system maintenance, or server downtime through our
              portal, minimizing such instances to the utmost extent feasible.{" "}
              <br /> <br />
              Furthermore, prompted by security or other considerations, we may
              request you to update your password or other access-related
              information. It is imperative to note that we will never solicit
              your password via email, telephone, or any medium other than the
              Platform. The onus of maintaining the confidentiality of your
              password and any additional identifying information rests solely
              on you.
            </p>
          </div>
          <div className="condition_heading">
            <h2>16. Disclaimer and Limitation of Liability</h2>
            <p>
              <span>Disclaimer:</span> The content and material derived from or
              accessible through the Platform are presented "as-is," "as
              available," with "all faults," and all warranties, whether
              expressed or implied, are explicitly disclaimed. This encompasses,
              but is not restricted to, the disclaimer of any implied warranties
              of merchantability, non-infringement, freedom from error, and
              fitness for a particular purpose. It is acknowledged that the
              information and services provided may contain bugs, errors,
              problems, or other limitations. Neither we nor our affiliated
              parties assume any liability for your utilization of any
              information or service, except as explicitly outlined in
              subsection 13(d). More specifically, though not exclusively, we
              and our affiliated parties are not responsible for any indirect,
              special, incidental, or consequential damages (including damages
              for loss of business, profits, savings, litigation, or the like),
              whether based on breach of contract, breach of warranty, tort
              (including negligence), product liability, or any other grounds,
              even if informed of the potential for such damages. <br /> <br />
              The denial and limitation of damages as elucidated above
              constitute essential components of the agreement between
              Cashdhundo and yourself. This Platform, along with its products,
              services, documents, content, materials, and information
              presented, would not be provided without such constraints. Any
              advice or information, whether oral or written, acquired by you
              from us through the Platform or otherwise, does not create any
              warranty, representation, or guarantee not expressly stated in
              this Agreement. We disclaim all responsibility or liability for
              any damages resulting from viruses contained within the electronic
              files containing forms or documents.
            </p>
            <p>
              <span>Liability:</span> We affirm that the Cashback/Reward Service
              will be dispensed with reasonable care and skill, with the
              intention of meeting our specifications for the Cashback/Reward
              Service. Nevertheless, we cannot guarantee that the
              Cashback/Reward Service will fulfill all your requirements. Our
              liability is explicitly defined in this Agreement, encompassing no
              other obligation, duty, or liability in contract, tort (including
              negligence, breach of statutory duty, and any other tort), or
              otherwise.
            </p>
            <p>
              <span>Limitation of Liability:</span> Subject to sub-Clause d.
              below, our liability is confined to direct loss or damage only,
              whether in contract, tort (including negligence, breach of
              statutory duty, or other tort), or otherwise, arising from our
              act, omission, or that of our employees, agents, or
              subcontractors. The aggregate liability of Cashdhundo and
              affiliated parties concerning any claim arising from the Platform,
              products, information, documents, and services provided hereby
              shall not exceed Rs 500, serving as a substitute for all other
              remedies against us and any affiliated party. We disclaim
              liability for:
            </p>
            <ul>
              <li>
                Loss of revenue, business, anticipated savings, or profits;
              </li>
              <li>
                Errors in or omissions from the Platform, or any services or
                products obtainable therefrom;
              </li>
              <li>
                The unavailability or interruption of the Platform or any
                features thereof;
              </li>
              <li>Your use of the Platform;</li>
              <li>The content and materials on the Platform;</li>
              <li>
                Any delay or failure in performance beyond our control or any of
                our affiliated parties.
              </li>
            </ul>
            <p>
              Furthermore, we are not liable for any indirect, special, or
              consequential loss, damage, costs, or other claims arising from
              non-supply or late supply of the Cashback Service, non-performance
              of this Agreement, or any other cause. Unless expressly stated
              otherwise in this Agreement, all representations, warranties,
              conditions, and other terms, whether express or implied, are
              excluded, except in cases of fraud or where such exclusion is
              disallowed by Applicable Law. For clarity, we disclaim liability
              for material contributed by Members, transactions (or
              non-transactions) with Retailers, or any activity or communication
              related to such material or transactions. The provisions of this
              Clause 14 shall endure the termination or expiry of this
              Agreement.
            </p>
          </div>
          <div className="condition_heading">
            <h2>17. Disclaimer Regarding Third-Party Content</h2>
            <p>
              The Platform may feature content and materials sourced from third
              parties or provide access to such content through hyperlinks. We
              expressly disclaim any responsibility and absolve ourselves of
              liability for errors, misstatements of law, defamation, omissions,
              falsehood, obscenity, pornography, or profanity that may be
              present in statements, opinions, representations, or any other
              form of content and materials visible on the Platform or
              accessible through hyperlinks from the Platform.
            </p>
          </div>
          <div className="condition_heading">
            <h2>18. Communication Consent Agreement</h2>
            <p>
              Upon acceptance of this agreement, you expressly grant consent to
              the receipt of intermittent communications from Cashdhundo, its
              affiliated entities, partners, and third-party associates. These
              communications are poised to encompass updates regarding the
              services rendered, vital information, solicitations for feedback,
              surveys, promotional materials, or any other pertinent messages.
              The dissemination of such communication may manifest through
              diverse channels, including but not limited to SMS, emails, calls
              (whether automated or agent-initiated), WhatsApp notifications,
              mobile applications, browser notifications, or any other fitting
              medium. By consenting, you affirm your readiness to receive
              communications falling within the aforementioned categories and
              deliberately relinquish any prior registrations or preferences
              established under the DND/NCPR list, aligning with the
              stipulations outlined in the pertinent TRAI regulations.
            </p>
          </div>
          <div className="condition_heading">
            <h2>19. Indemnification Covenant</h2>
            <p>
              By entering into this agreement, you expressly undertake to
              indemnify and hold us harmless from any and all liabilities,
              claims, and expenses that may arise as a consequence of:
            </p>
            <ol>
              <li>
                Your breach of the terms and conditions stipulated in this
                Agreement, whether directly attributable to your actions or
                occurring through your Account; or
              </li>
              <li>
                Any and all transactions conducted with a Retailer, wherein you
                are a party.
              </li>
            </ol>
            <p>
              This indemnification commitment encompasses a comprehensive
              safeguard, ensuring that we are shielded from the financial
              consequences and legal implications resulting from the specified
              breaches or transactions. Your acknowledgment and acceptance of
              this indemnity provision underscore your commitment to assuming
              responsibility for the stated liabilities, thereby reinforcing the
              integrity of our mutual agreement.
            </p>
          </div>
          <div className="condition_heading">
            <h2>20. Clause Pertaining to Assignment</h2>
            <p>
              Within the framework of this Agreement, we maintain the
              prerogative to either assign or subcontract, in part or in whole,
              our rights and obligations. It is imperative to note that such
              delegation shall not in any manner diminish the guarantees
              bestowed upon you under this Agreement. Conversely, it is
              incumbent upon you to refrain from assigning or transferring this
              Agreement, or subcontracting any of your associated rights and
              obligations, without having first obtained our explicit and
              documented consent.
            </p>
          </div>
          <div className="condition_heading">
            <h2>21. Entirety of Agreement Provision</h2>
            <p>
              This contractual stipulation is meticulously crafted to
              encapsulate the comprehensive understanding between yourself and
              our entity concerning the Cashback/Reward Service. Deemed as
              equitable and judicious, it stands as the definitive repository of
              terms, supplanting any antecedent agreements and cognizance,
              except in cases of fraudulent activities or misrepresentations
              perpetrated by either party.
            </p>
          </div>
          <div className="condition_heading">
            <h2>22. Notification of Amendment</h2>
            <p>
              We reserve the prerogative to periodically revise this Agreement
              and disseminate the updated iteration through the Cashback/Reward
              Service. Subsequent to implementation, the revised terms and
              conditions shall preside over the Cashback/Reward Service and
              delineate your affiliation with us: <br /> <br />
            </p>
            <ol>
              <li>
                <span className="fw-bold">
                  Commencement of Governing Period:
                </span>{" "}
                The newly instituted terms shall come into effect no less than 3
                (three) days subsequent to the posting date, unless otherwise
                specified by us in the pertinent notification. This applies
                particularly when alterations impact operational provisions with
                the potential to adversely affect you. Should you choose to
                abstain from being bound by the updated version, you may apprise
                us on or before the effective date. Furthermore, it is
                imperative that you desist from utilizing our service commencing
                from that specified date.{" "}
              </li>
              <li>
                <span className="fw-bold">Immediate Implementation:</span>{" "}
                Amendments that neither impact operational provisions nor
                possess the capacity to adversely affect you shall take
                immediate effect from the posting date, or as subsequently
                stipulated by us in the pertinent notification. Illustrative
                instances include alterations to contact details or refinements
                of existing provisions in this Agreement.{" "}
              </li>
            </ol>
          </div>
          <div className="condition_heading">
            <h2>23. Validity and Independence Clause</h2>
            <p>
              In the event that any provision within this Agreement is deemed to
              be invalid or unenforceable, the enduring terms shall persist in
              their validity and enforceability. It is expressly clarified that
              both you and Cashdhundo function as independent entities, and the
              terms of this Agreement do not give rise to any agency,
              partnership, joint venture, or employer-employee relationship
              between the parties. <br />
              It is underscored that any inaction on our part concerning a
              breach of the Agreement does not constitute a waiver of our right
              to take appropriate action in response to subsequent or similar
              breaches. This provision serves to emphasize the autonomy of the
              contracting parties and ensures that the overall integrity and
              enforceability of the Agreement remain intact despite potential
              challenges to individual provisions.
            </p>
          </div>
          <div className="condition_heading">
            <h2>24. Grievance Redressal Information</h2>
            <p>
              In accordance with the Information Technology Act of 2000, the
              Consumer Protection (E-Commerce) Rules of 2020, and pertinent
              regulatory frameworks, the designated Grievance Officer for
              matters pertaining to Cashdhundo within the ambit of this
              Agreement is as delineated below: <br />
              <ul>
                <li>Name: cashdhundo</li>
                <li>
                  Email ID:{" "}
                  <a href="mailto:support@cashdhundo.com">
                    support@cashdhundo.com
                  </a>
                </li>
              </ul>
              Should any user find themselves aggrieved and wish to articulate a
              complaint or seek elucidation concerning a potential breach of
              these Terms of Use, a comprehensive written complaint is welcomed
              and may be directed to the Grievance Officer. The Grievance
              Officer, entrusted with the responsibility of redressing such
              concerns, is committed to addressing any lodged complaint within
              the duration of 1 (One) month from the date of its receipt.
              <br />
              It is imperative to acknowledge that the information provided
              herein is subject to potential modifications at our discretion.
              Moreover, it is expressly communicated that the act of furnishing
              false, misleading, or inaccurate information to the Grievance
              Officer may invoke civil and/or criminal liability. Users are
              therefore advised to exercise due diligence in presenting accurate
              and truthful grievances for expeditious resolution.
            </p>
          </div>
          <div className="condition_heading">
            <h2>25. Jurisdiction and Applicable Law</h2>
            <p>
              This Agreement, together with our engagement with you and each
              Member, is meticulously governed by the laws of India. It is
              imperative to note that both parties, namely yourself and us,
              willingly and unequivocally submit to the non-exclusive
              jurisdiction of the Indian courts in matters pertaining to any
              disputes that may arise in connection with this Agreement. This
              alignment with Indian legal jurisdiction ensures a comprehensive
              and authoritative framework for addressing and resolving issues
              that may emerge during the course of our association. By expressly
              consenting to the jurisdiction of the Indian courts, we underscore
              our commitment to a judicious and legally sound resolution process
              in accordance with the established legal principles of the Indian
              legal system.
            </p>
          </div>
          <div className="condition_heading">
            <h2>26. Access to Agreement</h2>
            <p>
              The individual agreements meticulously entered into by Members as
              part of the registration process for the Cashback Service are not
              autonomously archived. For your convenience, the Agreement can be
              accessed at www.Cashdhundo.com. It is imperative to secure a
              lasting copy of this Agreement through the act of printing and/or
              preserving a downloaded copy on your personal computer. It is
              pertinent to note that this Agreement is exclusively available in
              the English language.
            </p>
          </div>
          <div className="condition_heading">
            <h2>27. Contact Information</h2>
            <p>
              YFor inquiries, you may contact us through the following email
              address:
              <a href="mailto:support@cashdhundo.com">
                support@cashdhundo.com
              </a>{" "}
              <br />
              We welcome your correspondence and will attend to your queries
              with the utmost attention and diligence.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndCondition;
