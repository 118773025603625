import React from "react";

function AboutPayment() {
  return (
    <>
      <h3 className="faq_heading">Cracking the Code: All About Payments!</h3>
      <div className="accordion-faq">
        <div className="accordion accordion-flush" id="accordionFlushTraking">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOneTraking">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOneTraking"
                aria-expanded="false"
                aria-controls="flush-collapseOneTraking"
              >
                What is the procedure for requesting my Cashback Payment?
              </button>
            </h2>
            <div
              id="flush-collapseOneTraking"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOneTraking"
              data-bs-parent="#accordionFlushTraking"
            >
              <div className="accordion-body">
                Once you've accumulated a minimum of Rs. 149 as Confirmed
                Cashback, you have the option to withdraw your earnings to your
                (Indian) Bank Account via NEFT. To initiate the payment request,
                follow these steps:
                <ol>
                  <li>Log in to your cashdhundo account.</li>
                  <li>
                    Navigate to the 'Payments' section under My Account/Profile.
                  </li>
                  <li>Select 'Request Cashback Payment.'</li>
                  <li>
                    Provide the necessary details of the Bank Account where you
                    wish to receive the Cashback.
                  </li>
                  <li>
                    After entering all the required details, click on 'Get
                    Paid.'
                  </li>
                  <li>
                    Verify the OTP to complete the payment request process.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPayment;
