import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import ClientFilter from "./ClientFilter";
import {
  useGetCouponsQuery,
  useGetSearchQueryCouponsQuery,
} from "../../App/Slice/couponsSlice";
import { Helmet } from "react-helmet";
import Skeleton from "react-skeleton-loader";

function Main_Coupons() {
  const params = useParams();
  const key = params.key;

  const { data } = useGetCouponsQuery();
  const coupon = data?.coupons?.filter((ele) => ele.status.includes("success"));

  const { data: couponData, isLoading } = useGetSearchQueryCouponsQuery(key);
  const coupon_data = couponData?.coupons?.filter((ele) =>
    ele.status.includes("success")
  );

  return (
    <>
      {/* Shop Smart, Not Hard: Coupons & Cashback, Your Bargain Bard! */}
      <Helmet>
        <title>All Coupons</title>
        <meta
          name="description"
          content="Discounts galore, Cashbacks on top! Supercharge your shopping with Cashdhundo Coupons."
        />
        <meta
          name="keywords"
          content="myntra coupon, amazon coupons, hostinger coupons, ajio coupons, deals, myntra deals, amazon deals , flipkart coupons, flipkrt deals"
        />
      </Helmet>

      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="/coupons/category/All">Coupons</Link>
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{key}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="container p-0 main-store mt-4 mb-4">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12">
            <ClientFilter />
            {/* <CategoryFilter /> */}
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <div className="grid-filter-section d-flex justify-content-between mb-4">
              <div className="grid-section">
                <h6>
                  Search Result: <Link to="">{key}</Link>{" "}
                </h6>
              </div>
            </div>
            {isLoading ? (
              <>
                <div className="inner-deal_coupon">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-4">
                        <Link to="#">
                          <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                            <span className="coupons-offers sklton_bg_light">
                              <Skeleton color="#d6e7ff" />
                            </span>
                            <div className="deals-image sklton_deals_image">
                              <Skeleton color="#d6e7ff" />
                              <span>
                                <Skeleton color="#d6e7ff" />
                              </span>
                            </div>
                            <div className="deals-content coupons-content d-flex flex-column">
                              <Skeleton color="#d6e7ff" width="80%" />
                              <Skeleton color="#d6e7ff" width="60%" />
                              <Skeleton color="#d6e7ff" />
                            </div>
                            <div className="coupon_input_section deals-content sklton_deals_content">
                              <Skeleton color="#d6e7ff" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-deal_coupon">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-12 mb-4">
                        <Link to="#">
                          <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                            <span className="coupons-offers sklton_bg_light">
                              <Skeleton color="#d6e7ff" />
                            </span>
                            <div className="deals-image sklton_deals_image">
                              <Skeleton color="#d6e7ff" />
                              <span>
                                <Skeleton color="#d6e7ff" />
                              </span>
                            </div>
                            <div className="deals-content coupons-content d-flex flex-column">
                              <Skeleton color="#d6e7ff" width="80%" />
                              <Skeleton color="#d6e7ff" width="60%" />
                              <Skeleton color="#d6e7ff" />
                            </div>
                            <div className="coupon_input_section deals-content sklton_deals_content">
                              <Skeleton color="#d6e7ff" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="inner-deal_coupon">
                <div className="container-fluid p-0">
                  {key === "All" ? (
                    <div className="row">
                      {coupon?.map((val, ind) => {
                        const {
                          _id,
                          client,
                          title,
                          offer,
                          category,
                          terms_condition,
                        } = val;
                        const name = title?.split(" ")?.join("-");
                        const client_name = client?.name?.split(" ")?.join("-");
                        return (
                          <div
                            key={ind}
                            className="col-lg-12 col-md-12 col-12 mb-4"
                          >
                            <Link to={`/coupon/${client_name}/${name}/${_id}`}>
                              <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                <span className="coupons-offers">
                                  {category?.name}
                                </span>
                                {/* <span className="coupons-feature">
                                <Link to="">View Feature</Link>
                                <Link to="">How it work?</Link>
                              </span> */}
                                <div className="deals-image">
                                  <img
                                    src={client?.logo}
                                    loading="lazy"
                                    alt={title}
                                  />
                                </div>
                                <div className="deals-content coupons-content">
                                  <h1>{title}</h1>
                                  <h2 className="text-danger">{offer}</h2>
                                  {terms_condition?.length === 0 ? null : (
                                    <ul className="feature_list">
                                      {terms_condition?.map((val, ind) => {
                                        return <li key={ind}>{val}</li>;
                                      })}
                                    </ul>
                                  )}
                                  {/* <div className="coupons-btn mt-2">
                                  <Link
                                    to={`/coupon/${client?.name}/${name}/${_id}`}
                                    type="button"
                                    className="btn"
                                  >
                                    View Detail
                                  </Link>
                                </div> */}
                                </div>
                                <div className="coupon_input_section deals-content">
                                  <Link
                                    to={`/coupon/${client_name}/${name}/${_id}`}
                                    type="button"
                                    className="coupon_link_btn text-right p-2"
                                  >
                                    <span>SHOW COUPON CODE</span> ACTIVATE
                                  </Link>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="row">
                      {coupon_data?.map((val, ind) => {
                        const { _id, client, title, offer, terms_condition } =
                          val;
                        const name = title?.split(" ")?.join("-");
                        const client_name = client[0]?.name
                          ?.split(" ")
                          ?.join("-");

                        const client_logo = client[0]?.logo;
                        return (
                          <div
                            key={ind}
                            className="col-lg-12 col-md-12 col-12 mb-4"
                          >
                            <Link to={`/coupon/${client_name}/${name}/${_id}`}>
                              <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                {/* <span className="coupons-offers">
                                {category}
                              </span> */}
                                {/* <span className="coupons-feature">
                                <Link to="">View Feature</Link>
                                <Link to="">How it work?</Link>
                              </span> */}
                                <div className="deals-image">
                                  <img
                                    src={client_logo}
                                    loading="lazy"
                                    alt={title}
                                  />
                                </div>
                                <div className="deals-content coupons-content">
                                  <h1>{title}</h1>
                                  <h2 className="text-danger">{offer}</h2>
                                  {terms_condition?.length === 0 ? null : (
                                    <ul className="feature_list">
                                      {terms_condition?.map((val, ind) => {
                                        return <li key={ind}>{val}</li>;
                                      })}
                                    </ul>
                                  )}
                                  {/* <div className="coupons-btn mt-2">
                                  <Link
                                    to={`/coupon/${client?.name}/${name}/${_id}`}
                                    type="button"
                                    className="btn"
                                  >
                                    View Detail
                                  </Link>
                                </div> */}
                                </div>
                                <div className="coupon_input_section deals-content">
                                  <Link
                                    to={`/coupon/${client_name}/${name}/${_id}`}
                                    type="button"
                                    className="coupon_link_btn text-right p-2"
                                  >
                                    <span>SHOW COUPON CODE</span> ACTIVATE
                                  </Link>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Main_Coupons;
