import React, { useState } from "react";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useForgotPasswordMailSendMutation } from "../App/Slice/userSlice";
import { toast } from "react-toastify";

function ForgotMailSend() {
  const [email, setEmail] = useState();
  const [mess, setMess] = useState(null);
  const [forgotPass, isLoading] = useForgotPasswordMailSendMutation();
  const apiLoading = isLoading?.isLoading;

  const forgotFormHandler = async (e) => {
    e.preventDefault();
    const data = { email: email };
    const response = await forgotPass(data);
    const result = response?.data;
    if (result.status === "success") {
      setMess(result);
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 1500);
      return;
    } else {
      toast.error(`${result.message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleForgot"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleForgot"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body login_form">
              <div className="outer-form ">
                <form
                  action=""
                  className="form-data pt-3 pb-4"
                  onSubmit={forgotFormHandler}
                >
                  <div className="register-heading mb-4">
                    <h5>FORGOT PASSWORD</h5>
                    <h6>Enter your E-mail</h6>
                  </div>
                  {mess && (
                    <div class="alert alert-success" role="alert">
                      {mess?.message}
                    </div>
                  )}
                  <div className="input-fields">
                    <span>
                      <FiMail />
                    </span>
                    <input
                      type="text"
                      placeholder="abc@gmail.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-fields mt-2 p-0">
                    <button type="submit" className="btn" disabled={apiLoading}>
                      {apiLoading === true
                        ? "Please wait..."
                        : "Forgot Password"}
                    </button>
                  </div>
                  <div className="input-content">
                    <h5>
                      Already had an Account?{" "}
                      <Link
                        to=""
                        data-bs-target="#exampleModalToggle"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Click here to Login
                      </Link>
                    </h5>
                    <h5>
                      Don't have an account?{" "}
                      <Link
                        to=""
                        data-bs-target="#exampleModalToggle2"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Create an account
                      </Link>
                    </h5>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotMailSend;
