import React from "react";
import { Helmet } from "react-helmet";

function AntiPolicy() {
  return (
    <>
      <Helmet>
        <title>Anti-Span Policy</title>
        <meta
          name="description"
          content=" We guarantee that we won't send you any unwanted emails or
              correspondence aside from what is specified in our privacy policy
              and terms and conditions. "
        />
        <meta
          name="keywords"
          content="anti-policy, policy, terms & conditions, condition, anti policy"
        />
      </Helmet>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">Anti-Spam Policy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="terms_conditions_para p-4">
          <div className="condition_heading">
            <p>
              <span className="fw-bold">
                CASHDHUNDO: Committed to Your Privacy and a Spam-Free Shopping
                Paradise
              </span>{" "}
              <br />
              At CASHDHUNDO, we're not just passionate about saving you money;
              we're passionate about protecting your privacy and keeping your
              inbox free from unwanted clutter. We believe in building trust and
              transparency, so we've crafted this Anti-Spam Policy to shed light
              on how we handle your information and ensure your digital shopping
              experience is a delight, not a drone attack.
              <br /> <br />
              <span className="fw-bold">What is spam?</span> <br />
              In our world, spam doesn't refer to the canned variety (though we
              wouldn't blame you for craving deals that good!). Here, spam
              refers to unsolicited, unwanted, or irrelevant emails that clog
              your inbox and disrupt your peace of mind. We're sworn enemies of
              spam and take every measure to ensure it never touches your
              digital doorstep. <br />
              <br />
              <span className="fw-bold">
                How do we protect your information?
              </span>{" "}
              <br /> Your trust is our treasure, and we guard it fiercely. We
              utilize industry-standard security measures to safeguard your
              data, including encryption, secure storage, and access controls.
              We never share your information with third-party marketers without
              your explicit consent, and we give you complete control over your
              communication preferences. <br /> <br />
              <span className="fw-bold">
                What kind of emails do we send?
              </span>{" "}
              <br /> We believe in keeping you informed and excited about the
              savings fiesta we orchestrate. You'll receive emails about:
              <br /> <br />
              <ul>
                <li>
                  Hot deals and exclusive discounts: We wouldn't dream of hiding
                  amazing offers! You'll be the first to know about jaw-dropping
                  deals, limited-time promotions, and coupons that make your
                  wallet sing.
                </li>
                <li>
                  Cashback updates: We love celebrating your savings wins!
                  You'll receive notifications about your accumulated cashback,
                  reminders to claim it, and tips to maximize your earning
                  potential.
                </li>
                <li>
                  Account updates and notifications: We'll keep you in the loop
                  about important information regarding your account, including
                  changes to terms and conditions, security updates, and
                  exciting new features we've added to your shopping playground.
                </li>
              </ul>
              How can you control your communication preferences? <br />
              We believe in communication that respects your boundaries. You
              have complete control over what you receive from us. You can
              choose to:
              <br />
              <ul>
                <li>
                  Opt-in or opt-out of different email categories: Want deals on
                  fashion but not tech? No problem! Choose which topics you're
                  interested in and personalize your communication experience.
                </li>
                <li>
                  Adjust your email frequency: Feeling overwhelmed? You can
                  choose how often you hear from us, from daily updates to
                  weekly digests.
                </li>
                <li>
                  Unsubscribe completely: If you ever decide CASHDHUNDO isn't
                  your shopping soulmate, simply click "unsubscribe" at the
                  bottom of any email and we'll gracefully bow out of your
                  inbox.
                </li>
              </ul>
              <span className="fw-bold">
                What happens if you receive a suspicious email?
              </span>{" "}
              <br />
              We take your security seriously, and we encourage you to do the
              same. If you receive an email claiming to be from CASHDHUNDO that
              looks suspicious or contains unusual requests, please do not click
              on any links or provide any personal information. Report it
              immediately to our support team, and we'll investigate it
              thoroughly.
              <br /> <br />
              <span className="fw-bold">
                Together, we can keep CASHDHUNDO a spam-free shopping haven!
              </span>{" "}
              <br />
              We believe in building a community where trust thrives and savings
              flow freely. By respecting your privacy and offering clear
              communication options, we hope to earn your trust and make
              CASHDHUNDO your go-to destination for happy shopping, not unwanted
              emails. If you have any questions or concerns about our Anti-Spam
              Policy, please don't hesitate to contact us. We're always happy to
              chat about keeping your inbox safe and your shopping experience
              sparkling!
              <br />
              <br />
              Remember, at CASHDHUNDO, your trust is our priority. We're
              committed to protecting your privacy and ensuring your shopping
              journey is filled with joy, not spam. So, come on in, explore the
              deals, and let's make shopping great again, one cashback click at
              a time!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AntiPolicy;
