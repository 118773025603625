import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetToken, URL_LINK } from "../../Secure/Helper";

export const user = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getLoginUser: builder.mutation({
      query: (body) => {
        return {
          url: "/user/login",
          method: "POST",
          body: body,
        };
      },
    }),

    getRegisterUser: builder.mutation({
      query: (body) => {
        return {
          url: "/user/register",
          method: "POST",
          body: body,
        };
      },
    }),

    UpdateUserProfile: builder.mutation({
      query: (body) => {
        return {
          url: "/user/update-profile",
          method: "PUT",
          headers: {
            Authorization: `Bearer ${GetToken()}`,
          },
          body: body,
        };
      },
    }),

    EmailSendForVerify: builder.mutation({
      query: (email) => {
        return {
          url: "/user/email-send-verify",
          method: "POST",
          headers: {
            Authorization: `Bearer ${GetToken()}`,
          },
          body: email,
        };
      },
    }),

    NormalUserVerify: builder.query({
      query: () => {
        return {
          url: "/user/normal-user",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GetToken()}`,
          },
        };
      },
    }),

    VerifyEmail: builder.mutation({
      query: (params) => {
        return {
          url: `/user/verify-email?id=${params}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    ForgotPasswordMailSend: builder.mutation({
      query: (body) => {
        return {
          url: "/user/forgot-mail-send",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        };
      },
    }),

    changeUserPassword: builder.mutation({
      query: (data) => {
        const { id, token, password, password_confirmation } = data;
        const bodies = {
          password: password,
          password_confirmation: password_confirmation,
        };
        return {
          url: `/user/password-forgot/${id}/${token}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: bodies,
        };
      },
    }),
  }),
});

export const {
  useGetLoginUserMutation,
  useGetRegisterUserMutation,
  useUpdateUserProfileMutation,
  useEmailSendForVerifyMutation,
  useVerifyEmailMutation,
  useForgotPasswordMailSendMutation,
  useChangeUserPasswordMutation,
  useNormalUserVerifyQuery,
} = user;
