import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const deals = createApi({
  reducerPath: "deals",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getDeals: builder.query({
      query: () => ({
        url: "/deals",
        method: "GET",
      }),
    }),

    getByIdDeals: builder.query({
      query: (id) => {
        return {
          url: `deals/${id}`,
          method: "GET",
        };
      },
    }),

    getSearchQueryDeals: builder.mutation({
      query: (query) => {
        return {
          url: `deals/search/q/${query}`,
          method: "GET",
        };
      },
    }),

    getSearchQueryParamsDeals: builder.query({
      query: (query) => {
        return {
          url: `deals/search/${query}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetDealsQuery,
  useGetSearchQueryDealsMutation,
  useGetSearchQueryParamsDealsQuery,
  useGetByIdDealsQuery
} = deals;
