import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_LINK } from "../../Secure/Helper";

export const orderID = createApi({
  reducerPath: "orderID",
  baseQuery: fetchBaseQuery({ baseUrl: `${URL_LINK}` }),

  endpoints: (builder) => ({
    getOrderID: builder.query({
      query: () => ({
        url: "/ord_002-ER_cashD",
        method: "GET",
      }),
    }),

    getByIdOrderID: builder.query({
      query: (id) => {
        return {
          url: `/ord_002-ER_cashD/${id}`,
          method: "GET",
        };
      },
    }),

    createOrderID: builder.mutation({
      query: (body) => {
        return {
          url: "/ord_002-ER_cashD",
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetByIdOrderIDQuery,
  useGetOrderIDQuery,
  useCreateOrderIDMutation,
} = orderID;
