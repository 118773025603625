import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import CreateSupport from "../../Components/Profile/CreateSupport";
import { useGetSupportQuery } from "../../App/Slice/supportSlice";
import Loading from "../../Secure/Loading";

function Support() {
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data || {};

  const { data: getSupport, isLoading } = useGetSupportQuery();
  const support = getSupport?.Support;

  // const loc = "http://localhost:8004/public/uploads";
  const loc = "https://api.cashdhundo.com/public/uploads";
  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name.username}</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Support</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9">
            <div className="inner-profile ">
              <span className=" mb-4 d-flex align-items-center justify-content-between">
                <h5>Support</h5>
                <button
                  className="btn edit-profile"
                  data-bs-toggle="modal"
                  href="#exampleModalToggleSupport"
                  role="button"
                >
                  Create Support
                </button>
              </span>
              <div className="inner-profile ">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Apply Date</th>
                      <th scope="col">Message</th>
                      <th scope="col">Attachment</th>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {support?.withdraw_money_length === 0 ? (
                        <span className="not-found-error">No Result Found</span>
                      ) : (
                        <tbody>
                          {support?.map((val, ind) => {
                            const { resume, description, createdAt } = val;
                            return (
                              <tr key={ind}>
                                <td>{createdAt?.slice(0, 10)}</td>
                                <td>{description}</td>
                                <td>
                                  <Link
                                    to={`${loc}/${resume}`}
                                    download={resume}
                                    type="button"
                                    className="user_support_btn"
                                    title={resume}
                                    target="_blank"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateSupport />
    </>
  );
}

export default Support;
