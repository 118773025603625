import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Helmet } from "react-helmet";
import CashbackQuery from "../View/Faq/CashbackQuery";
import MagicCashdhundo from "../View/Faq/MagicCashdhundo";
import LostCashbacks from "../View/Faq/LostCashbacks";
import AboutPayment from "../View/Faq/AboutPayment";
import ReferEarn from "../View/Faq/ReferEarn";
import AccessKingdom from "../View/Faq/AccessKingdom";
function Faq() {
  return (
    <>
      <Helmet>
        <title>FAQ</title>
        <meta name="description" content="Got Cashback Questions? Cashdhundo's FAQ Has You Covered!" />
        <meta
          name="keywords"
          content="faq, company question, faq query, cashdhundo faq"
        />
      </Helmet>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">FAQ</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section container">
        <div className="row mt-4">
          <div className="col-3">
            <div className="faq-header-button-head sticky-top p-4 mb-4 ">
              <div className="faq-header-button ">
                <a href="#UnlockingSavings">
                  <button className="mb-2">
                    {" "}
                    <span className="toogle">
                      <FaAngleRight />
                    </span>
                    Unlocking Savings
                  </button>
                </a>
                <a href="#CashbackQueries">
                  <button className="mb-2">
                    <FaAngleRight />
                    Cashback Queries
                  </button>
                </a>
                <a href="#LostCashbacks">
                  <button className="mb-2">
                    {" "}
                    <FaAngleRight />
                    Lost Cashbacks
                  </button>
                </a>
                <a href="#AboutPayments">
                  <button className="mb-2">
                    {" "}
                    <FaAngleRight />
                    About Payments
                  </button>
                </a>
                <a href="#ReferEarn">
                  <button className="mb-2">
                    {" "}
                    <FaAngleRight />
                    Refer, Earn, Repeat
                  </button>
                </a>
                <a href="#AccessKingdom">
                  <button>
                    {" "}
                    <FaAngleRight />
                    Login Woes Solved
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div id="UnlockingSavings" className="side-faq p-4">
              <MagicCashdhundo />
            </div>
            <div id="CashbackQueries" className="side-faq p-4">
              <CashbackQuery />
            </div>
            <div id="LostCashbacks" className="side-faq p-4">
              <LostCashbacks />
            </div>
            <div id="AboutPayments" className="side-faq p-4">
              <AboutPayment />
            </div>
            <div id="ReferEarn" className="side-faq p-4">
              <ReferEarn />
            </div>
            <div id="AccessKingdom" className="side-faq p-4">
              <AccessKingdom />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
