import React from "react";

function MagicCashdhundo() {
  return (
    <>
      <h3 className="faq_heading">Unlocking Savings Magic with cashdhundo!</h3>
      <div className="accordion-faq">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                How does cashdhundo shopping work?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>Log in to your cashdhundo account to get started.</li>
                  <li>Search for the desired shopping site, and once chosen, locate the site’s Store Page on cashdhundo.</li>
                  <li>Before finalizing your transaction, always double-check Cashback Rates and other offer terms.</li>
                  <li>Click on the orange button to seamlessly navigate to the retailer’s site.</li>
                  <li>The prices, offers, and discounts you encounter after being redirected from cashdhundo will be identical to those found directly on the website. Shop as you normally would, ensuring that you add products to your cart only after accessing the site through cashdhundo.</li>
                  <li>Your Cashback will automatically show as 'Pending' in your cashdhundo account, and you'll receive a notification within 24-48 hours.</li>
                  <li>All websites typically have a return/cancellation period of 30 days. Once this period concludes, successful transactions are shared with us by the sites, a process that may take between 10 to 60 days. Subsequently, we update the status of your Cashback/Rewards from Pending to Confirmed or Cancelled</li>
                  <li>Upon accumulating Rs 149 or more in Confirmed Cashback/Rewards, you have the option to transfer this amount to your bank account. </li>
                </ul>
                Happy Shopping!
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Uncover the Deals: Where to Find Cashback Rates and Offer Terms
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>Begin by searching for the site where you intend to shop. Once you've made your selection, navigate to the corresponding Store Page on cashdhundo.</li>
                  <li>On the store page, conveniently access the 'Cashback/Reward Rates' and 'Offer Terms' sections by simply clicking on them. Here, you'll discover all the essential details you need before proceeding with your shopping.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Unlock the Code: Can I Utilize a Coupon Code to Snag Cashback?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              Certainly! We provide Coupon Codes to enhance your savings with additional discounts. It's important to note that cashdhundo Cashback is applicable on top of the Coupon discount. To maximize your benefits, please make sure to use the Coupon Codes exclusively listed on cashdhundo. This way, you can enjoy both the coupon-based discount and the Cashback for an even more rewarding shopping experience.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingfour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsefour"
                aria-expanded="false"
                aria-controls="flush-collapsefour"
              >
                Do I Earn Cashback/Rewards with Cash on Delivery?
              </button>
            </h2>
            <div
              id="flush-collapsefour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingfour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
              Indeed! cashdhundo Cashback is applicable across all modes of payment, including Cash On Delivery. Regardless of how you choose to pay for your purchase, you can still enjoy the benefits of cashdhundo Cashback. This flexibility ensures that you can optimize your savings and earn Cashback irrespective of your preferred payment method.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MagicCashdhundo;
