import React from "react";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="We're not just another cashback website; we're CASHDHUNDO, your pocket's personal cheerleader! We scour the web for jaw-dropping deals, negotiate irresistible discounts, and sprinkle cashback magic on every purchase you make. From fashion finds to tech treasures, travel adventures to everyday essentials, we unlock savings across everything you love."
        />
        <meta
          name="keywords"
          content="about us, cashdhundo about, company about us, about us, cashdhundo about us"
        />
      </Helmet>

      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="term_main_banner">
              <h1 className="text-white">About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="terms_conditions_para p-4">
              <div className="condition_heading pt-4 pb-4">
                <p>
                  We're not just another cashback website; we're CASHDHUNDO,
                  your pocket's personal cheerleader! We scour the web for
                  jaw-dropping deals, negotiate irresistible discounts, and
                  sprinkle cashback magic on every purchase you make. From
                  fashion finds to tech treasures, travel adventures to everyday
                  essentials, we unlock savings across everything you love. Join
                  our vibrant community, discover hidden gems, watch your wallet
                  smile, and hear the sweet symphony of "cha-ching!" So, ditch
                  full prices and hello cashback cheers! Let CASHDHUNDO be your
                  shopping sidekick, sprinkling savings magic on every click.
                  We're not just saving you money, we're making shopping happy!{" "}
                  <br /> <br />
                  <span className="fw-bold">
                    Cashback Delight: Your Gateway to Shopping Bliss with Deals,
                    Discounts, and Cashback Magic
                  </span>{" "}
                  <br />
                  Forget buyer's remorse; welcome to CASHDHUNDO, your secret
                  portal to a shopping utopia! We're not just another cashback
                  website; we're your personal genie in a bottle, granting
                  wishes in the form of exciting deals, irresistible discounts,
                  and cashback that sprinkles magic on every purchase. <br />{" "}
                  <br />
                  <span className="fw-bold">
                    A Treasure Trove of Savings:
                  </span>{" "}
                  <br /> <br />
                  Imagine a treasure chest overflowing with coupons, vouchers,
                  and promo codes for your favorite brands – that's CASHDHUNDO!
                  We scrounge the internet, negotiate jaw-dropping deals, and
                  present them to you on a silver platter. You'll find
                  unbelievable discounts on everything from everyday essentials
                  to travel adventures, tech gadgets to fashion finds. No stone
                  is left unturned in our quest for your shopping satisfaction.{" "}
                  <br /> <br />
                  <span className="fw-bold">
                    Cashback, the Cherry on Top:
                  </span>{" "}
                  <br />
                  But the magic doesn't stop with discounts! CASHDHUNDO
                  sprinkles cashback over every purchase, like fairy dust that
                  adds a delightful shimmer to your savings. A percentage of
                  your spending magically reappears in your account, padding
                  your wallet and fueling your next shopping spree. It's like
                  getting paid to shop – who wouldn't want that? <br /> <br />
                  <span className="fw-bold">
                    Effortless Savings, Joyful Experience:
                  </span>
                  <br />
                  Navigating the labyrinthine world of deals and coupons can be
                  a chore. Not at CASHDHUNDO! Our user-friendly website and
                  intuitive app make finding the perfect offer a breeze. With
                  personalized recommendations, curated deals based on your
                  shopping habits, and easy-to-use search filters, finding the
                  best savings is as exciting as discovering a hidden gem in a
                  vintage store. <br /> <br />
                  <span className="fw-bold">
                    Join the CASHDHUNDO Community:
                  </span>{" "}
                  <br />
                  CASHDHUNDO is more than just a website; it's a vibrant
                  community of savvy shoppers who share their tips, tricks, and
                  favorite finds. Our social media channels are your playground
                  for discovering hidden deals, learning shopping hacks, and
                  celebrating savings victories with fellow enthusiasts. <br />{" "}
                  <br />
                  <span className="fw-bold">
                    Ready to Shop Smarter and Savvier?
                  </span>{" "}
                  <br />
                  Stop settling for full price; unlock the door to a world of
                  savings and cashback magic with Cashback Delight. Sign up
                  today, browse our treasure trove of deals, and start earning
                  cashback with every purchase. Remember, with CASHDHUNDO, every
                  click is a step closer to shopping bliss!
                  <br />
                  So, what are you waiting for? Let's make shopping delightful,
                  together!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
