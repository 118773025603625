import React, { useEffect, useState } from "react";
import { useVerifyEmailMutation } from "../App/Slice/userSlice";

function Verify_Email() {
  const [verfiyMail] = useVerifyEmailMutation();
  const [mess, setMess] = useState("");
  const currentUrl = window.location;
  const params = currentUrl?.search?.slice(4);

  console.log("location :", currentUrl);

  const verifyEmail = async () => {
      const response = await verfiyMail(params);
      const result = response?.data;
      console.log("response :", result);
    setMess(result);
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  const ClearCookies = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center row mx-auto">
          <div className="col-lg-12 col-md-12 col-12 mt-5">
            {mess?.status !== "failed" ? (
              <div className="succe-img">
                <img
                  src="https://api.cashdost.com/public/uploads/1689068693234success.png"
                  loading="lazy" alt="cashdhundo-success-mail-verify"
                />
              </div>
            ) : (
              <div className="succe-img">
                <img
                  src="https://api.cashdost.com/public/uploads/1689068340753cross.png"
                  loading="lazy" alt="cashdhundo-wrong-verify"
                />
              </div>
            )}

            <p className="lead mt-2 mb-2">{mess?.message}</p>
            {mess?.status !== "failed" ? (
              <div className="signup-btn">
                <button type="button" className="btn edit-profile" onClick={ClearCookies}>
                  Login to cashdhundo
                </button>
              </div>
            ) : (
              <div className="signup-btn">
                <button
                  type="button"
                  className="btn edit-profile"
                  disabled
                  style={{ backgroundColor: "#e0e0e0" }}
                >
                  Verify your E-mail
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Verify_Email;
