import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Nav from "../../Components/Profile/Nav";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import { useGetOrderIDQuery } from "../../App/Slice/orderIDSlice";
import { useState } from "react";

function Click() {
  const [clickReport, setClickReport] = useState([]);
  const { data } = useNormalUserVerifyQuery();
  const name = data?.user_data || {};

  const { data: clickData } = useGetOrderIDQuery();
  const click = clickData?.order_id || [];

  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{name.username}</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">Click Report</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container profile_interface mt-3 mb-5 p-3">
        <div className="row">
          <Nav />
          <div className="col-lg-9">
            <div className="inner-profile ">
              <span className=" mb-4 d-flex align-items-center justify-content-between">
                <h5>Clicks Report</h5>{" "}
              </span>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Store Name</th>
                    <th scope="col">Click</th>
                  </tr>
                </thead>
                <tbody>
                  {click?.map((val, ind) => {
                    const click_user = val?.user || {};
                    const click_client = val?.client;
                    if (click_user._id === name._id) {
                      return (
                        <tr key={ind}>
                          <td>{click_user?._id}</td>
                          <td>{click_client?.name}</td>
                          <td>{val?.order_id?.length}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Click;
