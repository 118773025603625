import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useGetSearchStoreMutation } from "../App/Slice/storeSlice";
import { IoCloseCircle } from "react-icons/io5";

function SearchBar() {
  const [search, setSearch] = useState(0);
  const [search_data, setSearchData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  // console.log("searching", search);

  const filterSearch = search_data?.filter((ele) =>
    ele.status.includes("success")
  );

  const [searchQuery, isLoading] = useGetSearchStoreMutation();
  const loading = isLoading?.isLoading;

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await searchQuery(search);
        const result = response?.data?.client;
        setSearchData(result);
        setShowResults(true);
      } catch (error) {
        console.error("Error occurred during search:", error);
      }
    };
    if (search) {
      fetchSearchResults();
    } else {
      setSearchData([]);
      setShowResults(false);
    }
  }, [search, searchQuery]);

  const hideShowSearch = () => {
    setShowResults(false);
  };

  const openShowHandler = () => {
    if (!search || search?.length === 0) {
      setShowResults(false);
    }
    setShowResults(true);
  };

  return (
    <>
      <div className="d-flex navbar-form">
        <span>
          <FiSearch />
        </span>
        <input
          className="form-control me-2"
          type="text"
          placeholder="Search By - Store & Category"
          aria-label="Search"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={openShowHandler}
        />
        {showResults && (
          <>
            <div className="searh-result">
              <div className="d-flex justify-content-between">
                <p>Search result : {search ? search : search}</p>
                <span
                  className="p-0"
                  onClick={() => setShowResults(false)}
                  style={{ cursor: "pointer" }}
                >
                  <IoCloseCircle />
                </span>
              </div>
              {loading ? (
                <p>Please wait...</p>
              ) : (
                <ul className="store-ul mb-3">
                  {filterSearch?.length === 0 ? (
                    <p>Result Not Found</p>
                  ) : (
                    <>
                      {filterSearch?.map((val, ind) => {
                        const { _id, name, calc, client_offer, logo } = val;
                        const cli = name?.split(" ")?.join("-");
                        return (
                          <li key={ind}>
                            <Link
                              to={`/campaign-store/${cli}/${_id}`}
                              onClick={hideShowSearch}
                            >
                              <div className="client-store">
                                <div className="store-inner">
                                  <div className="outer-store" title={name}>
                                    <img src={logo} loading="lazy" alt={name} />
                                    {/* <h4>{name}</h4> */}
                                    {calc[0] === null || calc[0] === "Paused" ? null : (
                                      <h5 className="highlight_offer">
                                        {client_offer} Cashback
                                      </h5>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SearchBar;
