import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import ClientFilter from "./ClientFilter";
import { useNormalUserVerifyQuery } from "../../App/Slice/userSlice";
import {
  useCreateOrderIDMutation,
  useGetOrderIDQuery,
} from "../../App/Slice/orderIDSlice";
import {
  useGetAllStoreDataQuery,
} from "../../App/Slice/storeSlice";
import CashbackFilterAll from "./CashbackFilterAll";

function CategoryByFilter() {

  const params = useParams();
  const key = params?.category;
  const titles = params?.title;

  const { data: getAllData } = useGetAllStoreDataQuery(titles);
  const getAllCategory = getAllData?.searchByclient;
  const getDeal = getAllCategory?.deals?.filter((ele) =>ele.status.includes("success"));
  const getCoupon = getAllCategory?.coupon?.filter((ele) =>ele.status.includes("success"));

  return (
    <>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12 p-0">
            <ul className="brade_crampe">
              <li>
                <Link to="/">Home</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="/">Category</Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to={`/campaign-store/category/${titles}/Deals`}>
                  {titles}
                </Link>{" "}
                <span>
                  <FiChevronRight />
                </span>
              </li>
              <li>
                <Link to="#">{key}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container p-0 main-store mt-4 mb-4">
        <div className="row">
          <div className="col-3">
            <CashbackFilterAll titles={titles} />
            <ClientFilter />
          </div>
          <div className="col-9">
            <div className="grid-filter-section d-flex justify-content-between mb-4">
              <div className="grid-section">
                <h6>{key === null ? " " : key}</h6>
              </div>
            </div>
            <div className="inner-deal_coupon">
              <div className="container-fluid p-0">
                {key === "Deals" ? (
                  <div className="row">
                    {getDeal?.length === 0 ? (
                      <p>Empty Result!</p>
                    ) : (
                      <>
                        {getDeal?.map((val, ind) => {
                          const { _id, client, title, category, description } =
                            val;
                          const name = title?.split(" ")?.join("-");
                          return (
                            <div
                              key={ind}
                              className="col-lg-12 col-md-12 col-12 mb-4"
                            >
                              <Link
                                to={`/store/${client?.name}/${name}/${_id}`}
                              >
                                <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                  <span className="coupons-offers">
                                    {category?.name}
                                  </span>
                                  <div className="deals-image">
                                    <img src={client?.logo} loading="lazy" alt={title} />
                                    <span>
                                      <h5>Trending</h5>
                                    </span>
                                  </div>
                                  <div className="deals-content coupons-content">
                                    <h5>
                                      {client?.name} <span>DEAL</span>
                                    </h5>
                                    <h2>{client?.client_offer}</h2>
                                    <h1>{title}</h1>
                                    <p>{description?.slice(0, 116)}</p>
                                  </div>
                                  <div className="coupon_input_section deals-content">
                                    <Link
                                      to={`/store/${client?.name}/${name}/${_id}`}
                                      type="button"
                                      className="coupon_link_btn text-right p-2"
                                    >
                                      <span>ACTIVATE DEAL</span> ACTIVATE
                                    </Link>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : key === "Coupons" ? (
                  <div className="row">
                    {getCoupon?.length === 0 ? (
                      <p>Empty Result!</p>
                    ) : (
                      <>
                        {getCoupon?.map((val, ind) => {
                          const {
                            _id,
                            client,
                            category,
                            coupon_offer,
                            title,
                            description,
                            offer,
                          } = val;
                          const name = title?.split(" ")?.join("-");
                          return (
                            <div
                              key={ind}
                              className="col-lg-12 col-md-12 col-12 mb-4"
                            >
                              <Link
                                to={`/coupon/${client?.name}/${name}/${_id}`}
                              >
                                <div className="today-deals today-coupons d-flex justify-content-between align-items-center">
                                  <span className="coupons-offers">
                                    {category?.name}
                                  </span>
                                  <span className="coupons-feature">
                                    <Link to="">View Feature</Link>
                                    <Link to="">How it work?</Link>
                                  </span>
                                  <div className="deals-image coupon-img">
                                    <h1>{coupon_offer}</h1>
                                    <span>
                                      <img src={client?.logo} loading="lazy" alt={title} />
                                    </span>
                                  </div>
                                  <div className="deals-content coupons-content">
                                    <h1>{title}</h1>
                                    <h2 className="text-danger">{offer}</h2>
                                    <p>{description?.slice(0, 116)}</p>
                                    <div className="coupons-btn mt-2">
                                      <Link
                                        to={`/coupon/${client?.name}/${name}/${_id}`}
                                        type="button"
                                        className="btn"
                                      >
                                        View Detail
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="coupon_input_section deals-content">
                                    <Link
                                      to={`/coupon/${client?.name}/${name}/${_id}`}
                                      type="button"
                                      className="coupon_link_btn text-right p-2"
                                    >
                                      <span>SHOW COUPON CODE</span> ACTIVATE
                                    </Link>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryByFilter;
