import React, { useState } from "react";
import { FiLock, FiMail, FiPhone, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetRegisterUserMutation } from "../App/Slice/userSlice";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa6";

function Register() {
  const [createSignUp, isLoading] = useGetRegisterUserMutation();
  const apiLoading = isLoading?.isLoading;

  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();

  const [show, setShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const [msg, setMsg] = useState(false);

  const registerFormHandler = async (e) => {
    e.preventDefault();
    try {
      const data = {
        username: username,
        email: email,
        phone: phone,
        password: password,
        password_confirmation: password_confirmation,
      };
      if (
        !username ||
        (username === undefined && !email) ||
        (email === undefined && !phone) ||
        (phone === undefined && !password) ||
        (password === undefined && !password_confirmation) ||
        password_confirmation === undefined
      ) {
        toast.error("all fields are required!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      if (password !== password_confirmation) {
        toast.error("Passwrod & Confirm Password is not match!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else {
        const response = await createSignUp(data);
        const exitUser = response?.error?.data;
        const result = response?.data;
        if (!result || exitUser) {
          toast.error(`${exitUser.message}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          return;
        }
        if (result.status === "success") {
          setMsg(result.message);
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
          return;
        } else {
          toast.error(`${result.message}`, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          return;
        }
      }
    } catch (error) {
      return alert("register form is not working!");
    }
  };

  const showPasswordHandler = () => {
    setShow(!show);
  };

  const showConfirmPasswordHandler = () =>{
    setConfirmShow(!confirmShow)
  }

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body login_form">
              <div className="outer-form">
                <form
                  action=""
                  className="form-data pt-3 pb-4"
                  onSubmit={registerFormHandler}
                >
                  <div className="register-heading mb-4">
                    <h5>CREATE A NEW ACCOUNT</h5>
                  </div>
                  {msg && (
                    <div class="alert alert-success" role="alert">
                      {msg}
                    </div>
                  )}
                  <div className="input-fields">
                    <span>
                      <FiUser />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input-fields">
                    <span>
                      <FiMail />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter E-mail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-fields">
                    <span>
                      <FiPhone />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter Phone No."
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="input-fields">
                    <span>
                      <FiLock />
                    </span>
                    <input
                      type={show === true ? "text" : "password"}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="show_password"
                      onClick={showPasswordHandler}
                    >
                      {!show === true ? <FaEyeSlash /> : <IoEyeSharp />}
                    </span>
                  </div>
                  <div className="input-fields">
                    <span>
                      <FiLock />
                    </span>
                    <input
                      type={confirmShow === true ? "text" : "password"}
                      placeholder="Repeat Password"
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                    <span
                      className="show_password"
                      onClick={showConfirmPasswordHandler}
                    >
                      {!confirmShow === true ? <FaEyeSlash /> : <IoEyeSharp />}
                    </span>
                  </div>
                  <div className="input-fields mt-2">
                    <button type="submit" className="btn" disabled={apiLoading}>
                      {apiLoading ? "Please wait..." : "Sign Up"}
                    </button>
                  </div>
                  <div className="or-section">
                    <h5>OR</h5>
                  </div>
                  {/* <div className="social-media-login pt-3 pb-1">
                    <div className="input-fields">
                      <span>
                        <img
                          src="../../assets/img/social/google.png"
                          loading="lazy" alt="cashdhundo"
                        />
                      </span>
                      <h5>Google</h5>
                    </div>
                    <div className="input-fields">
                      <span>
                        <img
                          src="../../assets/img/social/facebook.png"
                          loading="lazy" alt="cashdhundo"
                        />
                      </span>
                      <h5>Facebook</h5>
                    </div>
                  </div> */}
                  <div className="input-content mt-3">
                    <h5>
                      Already had an Account?{" "}
                      <Link
                        to=""
                        data-bs-target="#exampleModalToggle"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Click here to Login
                      </Link>
                    </h5>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
