import React from "react";

function Loading() {
  return (
    <>
      <p className="loading_section">Please wait...</p>
    </>
  );
}

export default Loading;
