import React, { useEffect, useState } from "react";
import { FiArrowUpRight, FiCheck, FiMail, FiPhone, FiUser } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  useEmailSendForVerifyMutation,
  useUpdateUserProfileMutation,
} from "../App/Slice/userSlice";

function UpdateProfile({ name }) {
  const user = name;
  // console.log(user);

  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();

  const [updateProfile, isLoading] = useUpdateUserProfileMutation();
  const apiLoading = isLoading?.isLoading;

  const [sendVerifyMail, { isLoading: isEmailLoading }] =
    useEmailSendForVerifyMutation();

  const checkAccount = async () => {
    if (!user) {
      return;
    }
    setUsername(user.username);
    setEmail(user.email);
    setPhone(user.phone);
  };

  useEffect(() => {
    checkAccount();
  }, []);

  // ---------------------------------------update-user-profile-------------------------------------
  const updatedProfileHandler = async (e) => {
    e.preventDefault();
    const data = { username: username };
    if (!username || username === undefined) {
      toast.error("all fields are required!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    } else {
      const updateuser = await updateProfile(data);
      //   console.log("response :", updateuser?.data);
      const result = updateuser?.data;
      if (result.status === "success") {
        toast.success(`${result.message}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/";
        }, 1000);
        return;
      } else {
        toast.error(`${result.message}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    }
  };

  // ---------------------------------send-email-verify-on-user-mail---------------------------------
  const emailVerifyHandler = async () => {
    const d = user.email;
    const response = await sendVerifyMail(d);
    const result = response?.data;
    if (result.status === "success") {
      toast.success(`${result.message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/";
      }, 1000);
      return;
    } else {
      toast.error(`${result.message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggleEditProfile"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleEditProfileLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body login_form">
              <div className="outer-form">
                <form
                  action=""
                  className="form-data pt-4 pb-4"
                  onSubmit={updatedProfileHandler}
                >
                  <div className="register-heading mb-5">
                    <h5>Update Profile</h5>
                  </div>
                  <div className="input-fields">
                    <span>
                      <FiUser />
                    </span>
                    <input
                      type="text"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div
                    className="input-fields update-profile"
                    disabled
                    style={{ backgroundColor: "#e0e0e0" }}
                  >
                    <span>
                      <FiMail />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter E-mail"
                      value={email}
                      disabled
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {user.email_verified === "false" ? (
                      <span className="verify-button">
                        {isEmailLoading === true ? (
                          <h6 style={{ backgroundColor: "#bf506a" }} disabled>
                            Wait...
                          </h6>
                        ) : (
                          <h6
                            type="button"
                            title="Verify your email"
                            onClick={emailVerifyHandler}
                          >
                            Verify
                          </h6>
                        )}
                      </span>
                    ) : (
                      <span className="verify-success-btn">
                        <FiCheck />
                      </span>
                    )}
                  </div>
                  <div
                    className="input-fields update-profile"
                    disabled
                    style={{ backgroundColor: "#e0e0e0" }}
                  >
                    <span>
                      <FiPhone />
                    </span>
                    <input
                      type="text"
                      placeholder="Phone"
                      value={phone}
                      disabled
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {user.phone_verified === "false" ? (
                      <span
                        type="button"
                        title="Verify your mobile no."
                        className="verify-button"
                      >
                        <h6>Verify</h6>
                      </span>
                    ) : (
                      <span className="verify-success-btn">
                        <FiCheck />
                      </span>
                    )}
                  </div>

                  <div className="input-fields input-login mt-2">
                    <button type="submit" className="btn" disabled={apiLoading}>
                      {apiLoading ? "Please wait..." : "Update"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
